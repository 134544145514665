// UploadReportsSection.js

import React from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  List,
  ListItem,
  IconButton,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { IoClose } from "react-icons/io5";
import PrimaryButton from "../../../components/PrimaryButton";

const UploadReportsSection = ({
  errors,
  selectedFile,
  handleMultiFileChange,
  handleViewFile,
  removeFile,
  fileInputRef,
  label,
  id,
  ml,
}) => {
  const accept =
    id === "reports"
      ? ".pdf,.jpg,.jpeg,.png,.doc,.docx,.gif,.dcm,.bmp,.pdf"
      : ".pdf,.jpg,.jpeg,.png";

  return (
    <Box mb="10px" ml={ml}>
      <Box p="15px 20px" bg="white" borderRadius="10px" mt="10px">
        <FormControl isInvalid={errors[id]} isRequired>
          <PrimaryButton onClick={() => fileInputRef.current.click()} mb="4">
            {label}
          </PrimaryButton>
          <Input
            variant="filled"
            h="auto"
            p="10px"
            id="reports"
            name="reports"
            type="file"
            style={{ display: "none" }}
            multiple
            accept={accept}
            onChange={handleMultiFileChange}
            ref={fileInputRef}
          />
          <FormHelperText>
            Note: Please upload files in PNG, JPG, JPEG, or PDF format. You may
            upload more than one file
          </FormHelperText>
          <FormErrorMessage>{errors[id]}</FormErrorMessage>
        </FormControl>
        <List mt="10px">
          {selectedFile.map((file, index) => (
            <ListItem key={index} display="flex" alignItems="center">
              <Box flex="1">
                {file.name} ({(file.size / (1024 * 1024)).toFixed(2)} MB)
              </Box>
              <IconButton
                variant="ghost"
                colorScheme="blue"
                size="sm"
                aria-label="View"
                icon={<ViewIcon />}
                onClick={() => handleViewFile(file)}
                mr="2"
              />
              <IconButton
                variant="ghost"
                colorScheme="red"
                size="sm"
                aria-label="Remove"
                icon={<IoClose />}
                onClick={() => removeFile(index)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default UploadReportsSection;
