import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Input,
} from "@chakra-ui/react";
import PrimaryText from "../../../components/PrimaryText";

const PickUpDetailsModal = ({ isOpen, onClose, pickup_details }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent maxH="90vh" overflowY="scroll">
        <ModalHeader color="primary.1" textAlign={"center"} fontSize={"2xl"}>
          Pickup Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4} mt="10px">
            <PrimaryText mb={1} fontWeight="bold">
              Driver Name
            </PrimaryText>
            <Input
              name="DriverName"
              value={pickup_details[0].driver_name}
              isReadOnly={true}
              variant="filled"
            />
          </Box>
          <Box mb={4}>
            <PrimaryText mb={1} fontWeight="bold">
              Vehicle Number
            </PrimaryText>
            <Input
              name="VehicleNumber"
              value={pickup_details[0].vehicle_no}
              isReadOnly={true}
              variant="filled"
            />
          </Box>
          <Box mb={4} mt="10px">
            <PrimaryText mb={1} fontWeight="bold">
              Mobile Number
            </PrimaryText>
            <Input
              name="MobileNumber"
              value={pickup_details[0].mobile_no}
              isReadOnly={true}
              variant="filled"
            />
          </Box>
          <Box mb={4}>
            <PrimaryText mb={1} fontWeight="bold">
              Pickup Date
            </PrimaryText>
            <Input
              name="PickupDate"
              value={pickup_details[0].pickup_date}
              isReadOnly={true}
              variant="filled"
            />
          </Box>
          <Box mb={4} mt="10px">
            <PrimaryText mb={1} fontWeight="bold">
              Pickup Time
            </PrimaryText>
            <Input
              name="PickupTime"
              value={pickup_details[0].pickup_time}
              isReadOnly={true}
              variant="filled"
            />
          </Box>
          <Box mb={4}>
            <PrimaryText mb={1} fontWeight="bold">
              Vehicle Type Color
            </PrimaryText>
            <Input
              name="VehicleTypeColor"
              value={pickup_details[0].vehicle_type_color}
              isReadOnly={true}
              variant="filled"
            />
          </Box>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PickUpDetailsModal;
