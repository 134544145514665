import React, { useEffect } from "react";
import { Flex, Box } from "@chakra-ui/react";
import Layout from "../../../layout";
import { useDispatch } from "react-redux";
import { useState } from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import HealthConcernSection from "./HealthConcernSection";
import PatientDetailsSection from "./PatientDetailsSection";
import PatientPassportSection from "./PatientPassportSection";
import {
  handleSelectChange,
  handleChange,
  handlePassportFileChange,
  formatMobileNumber,
} from "./formUtils";

import { preIntimationFormData } from "./FormData";
import { preIntimationValidateForm } from "./ValidationUtils";
import "../base.css";
import { useSelector } from "react-redux";
import {
  fetchCountryRequest,
  fetchHealthConcernRequest,
  fetchHospitalsRequest,
  fetchPreferredCountryRequest,
  fetchTrDetailsRequest,
  medicalOpinionRequest,
  verifyExistingPatientRequest,
} from "../hcfStore/actions";
import QRCodeModal from "../components/QRCodeModel";
import PatientDetailsModal from "../components/PatientDetailsModal";
import Alert from "../../../components/Alert";
import { useBlocker, useNavigate } from "react-router-dom";
import FooterCheckBox from "../components/FooterCheckbox";

const PreIntimation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(preIntimationFormData);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isUnsaveDialogOpen, setIsUnsaveDialogOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showConfirmError, setShowConfirmError] = useState("");
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [existingPatientAlert, setExistingPatientAlert] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    medicalOpinionResponse,
    existingPatientResponse,
    trDetails,
  } = useSelector((state) => state.hcf);

  useEffect(() => {
    let payload = { is_master: true };
    dispatch(fetchHospitalsRequest(payload));
    dispatch(fetchPreferredCountryRequest(payload));
    dispatch(fetchCountryRequest());
    dispatch(fetchHealthConcernRequest(payload));
  }, []);

  useEffect(() => {
    if (formData.passportNumber && formData.hospital) {
      if (formData.passportNumber.length > 6) {
        handlePatientCheck(formData.hospital.value, formData.passportNumber);
      }
    }
  }, [formData.passportNumber, formData.hospital]);

  useEffect(() => {
    if (existingPatientResponse) {
      setExistingPatientAlert(true);
    }
  }, [existingPatientResponse]);

  useEffect(() => {
    if (trDetails && trDetails.length > 0) {
      setIsModalOpen(true);
    }
  }, [trDetails]);

  const handlePatientCard = (id) => {
    let payload = {
      tr_id: existingPatientResponse.data,
    };
    dispatch(fetchTrDetailsRequest(payload));
  };

  const handlePatientCheck = (hospital, passportNumber) => {
    const payload = {
      hospital: hospital,
      passport_no: passportNumber,
    };

    dispatch(verifyExistingPatientRequest(payload));
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const hasValues = Object.values(formData).some(
      (value) =>
        Boolean(value) &&
        (typeof value !== "object" || Object.keys(value).length > 0)
    );
    setHasUnsavedChanges(hasValues);
  }, [formData]);

  const shouldBlockNavigation = () => {
    if (hasUnsavedChanges) {
      setIsUnsaveDialogOpen(true);
      return "Are you sure you want to leave? You may lose unsaved changes.";
    }
    return null;
  };

  const blocker = useBlocker(shouldBlockNavigation);

  useEffect(() => {
    if (medicalOpinionResponse) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [medicalOpinionResponse]);

  const onSubmit = (e) => {
    e.preventDefault();
    let errors = validateForm(formData, formErrors);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (!isConfirmed) {
        setShowConfirmError("Please check the checkbox to continue..!");
        return;
      }
      if (formData.countryCode) {
        const formattedMobileNumber = formatMobileNumber(
          formData.mobileNumber,
          formData.countryCode
        );
        formData.mobileNumber = formattedMobileNumber;
      }

      let payload = new FormData();
      payload.append("is_preintimation", "1");
      payload.append("health_concern", formData.healthConcern.value);
      payload.append("preferred_country", formData.preferredCountry.value);
      payload.append("hospital", formData.hospital.value);
      payload.append("patient_name", formData.name);
      payload.append("gender", formData.gender.value);
      payload.append("nationality", formData.nationality.value);
      payload.append("country_of_res", formData.countryOfResidence.value);
      payload.append("dob", formData.dob);
      payload.append("passport_no", formData.passportNumber);
      payload.append("arrival_within", formData.arrivalWithin.value);
      payload.append("patient_moving_from", formData.patientMovingFrom.value);

      if (formData.mobileNumber && formData.mobileNumber !== "") {
        payload.append("mobile_no", formData.mobileNumber);
      }
      if (formData.passport) {
        payload.append("passport", formData.passport);
      }
      setHasUnsavedChanges(false);
      dispatch(medicalOpinionRequest(payload));
    } else {
      setErrorMessage(true);
    }
  };

  const validateForm = (values, formErrors) => {
    return preIntimationValidateForm(values, formErrors);
  };

  return (
    <Layout header="New Pre-Intimation" iconUrl="/h/home">
      <Box p="0 10px" mb="10px">
        <form>
          <HealthConcernSection
            formErrors={formErrors}
            formData={formData}
            setFormData={setFormData}
            setFormErrors={setFormErrors}
            selectedOrder={selectedOrder}
            // handleSelectChange={(selectedOption, actionMeta) =>
            //   handleSelectChange(
            //     formData,
            //     setFormData,
            //     formErrors,
            //     setFormErrors,
            //     selectedOption,
            //     actionMeta,
            //     dispatch
            //   )
            // }
          />
          <PatientPassportSection
            formErrors={formErrors}
            formData={formData}
            setFormData={setFormData}
            setFormErrors={setFormErrors}
            handlePassportFileChange={(event) =>
              handlePassportFileChange(
                formData,
                setFormData,
                formErrors,
                setFormErrors,
                dispatch,
                event
              )
            }
            isRequired={true}
          />
          <PatientDetailsSection
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            formData={formData}
            setFormData={setFormData}
            handleSelectChange={(selectedOption, actionMeta) =>
              handleSelectChange(
                formData,
                setFormData,
                formErrors,
                setFormErrors,
                selectedOption,
                actionMeta
              )
            }
            handleChange={(e) =>
              handleChange(formData, setFormData, formErrors, setFormErrors, e)
            }
            isPassportRequired={true}
            name="Preintimation"
          />

          <Flex justifyContent={"right"} mt="4">
            <FooterCheckBox
              msg="Kindly confirm the details are entered correctly"
              isChecked={isConfirmed}
              setIsChecked={setIsConfirmed}
              checkboxError={showConfirmError}
            />
            <PrimaryButton type="submit" onClick={onSubmit}>
              Submit
            </PrimaryButton>
          </Flex>
        </form>
        <Alert
          isOpen={errorMessage}
          onClose={() => {
            setErrorMessage(false);
          }}
          body="Please fill all mandatory fields"
        />
        <Alert
          isOpen={isUnsaveDialogOpen}
          onClose={() => {
            setIsUnsaveDialogOpen(false);
          }}
          showCancelButton={true}
          title="Unsaved Changes!"
          body="Are you sure you want to leave? You may lose unsaved changes."
          type="info"
          callback={blocker.proceed}
        />
        <Alert
          isOpen={existingPatientAlert}
          onClose={() => {
            setExistingPatientAlert(false);
            setFormData(preIntimationFormData);
          }}
          showCancelButton={true}
          title="Info"
          body={existingPatientResponse.message}
          type="info"
          callback={handlePatientCard}
        />

        <QRCodeModal
          isOpen={showAlert}
          onClose={() => {
            setShowAlert(false);
            navigate("/existing-patients");
          }}
          name={medicalOpinionResponse?.patient_name}
          trid={medicalOpinionResponse?.tr_id}
          requestType={"Pre-Intimation"}
          qrCodeImageUrl={medicalOpinionResponse?.qr_code_url}
          qrCodeImageContentUrl={medicalOpinionResponse?.qr_code_image_content}
        />
        {trDetails && (
          <PatientDetailsModal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              setExistingPatientAlert(false);
              setFormData(preIntimationFormData);
            }}
            patient={trDetails?.[0] || []}
          />
        )}
      </Box>
    </Layout>
  );
};

export default PreIntimation;
