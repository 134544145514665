import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import {
  Box,
  Text,
  VStack,
  Stack,
  Card,
  CardBody,
  Box as ChakraBox,
  Flex,
} from "@chakra-ui/react";
import noDataImage from "../../assets/images/MessageBg.svg";
import ChatBot from "./chatBot";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessagesHistoryRequest } from "./store/actions";
import PrimaryButton from "../../components/PrimaryButton";
import { FiMessageCircle } from "react-icons/fi";

const Messages = () => {
  const dispatch = useDispatch();
  const { messagesSummaryHistory } = useSelector((state) => state.messages);

  // const [isChatBotVisible, setIsChatBotVisible] = useState(true);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const [newChat, setNewChat] = useState(false);

  useEffect(() => {
    const payload = { is_summary: true };
    dispatch(fetchMessagesHistoryRequest(payload));
  }, [dispatch]);

  const handleNewChatClick = () => {
    setNewChat(true);
    setOpenChat(false);
    setSelectedTaskId(null);
  };

  const handleChatClick = (taskId) => {
    setOpenChat(true);
    setSelectedTaskId(taskId);
    const payload = { is_summary: false, task_id: taskId };
    dispatch(fetchMessagesHistoryRequest(payload));
  };

  return (
    <Layout
      header={"Messages"}
      iconUrl="/h/home"
      newChat="true"
      // openChatbot={handleNewChatClick}
    >
      <PrimaryButton
        rightIcon={<FiMessageCircle fontSize="20px" pb="10px" />}
        ml="10px"
        onClick={handleNewChatClick}
      >
        Start Chat
      </PrimaryButton>

      <Flex direction="row" height="80vh" overflow="hidden">
        <Box
          minHeight="600px"
          width="50%"
          bgColor={"#ffff"}
          mt="20px"
          ml="10px"
          shadow="md"
          borderWidth="1px"
          borderRadius="md"
          p="4"
          overflowY="auto"
        >
          {messagesSummaryHistory.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
                fontSize: "20px",
              }}
            >
              <img
                src={noDataImage}
                alt="No records"
                style={{ width: "300px" }}
              />
              <Text as="b">No messages yet</Text>
            </div>
          ) : (
            <VStack spacing="4">
              {messagesSummaryHistory.map((message) => (
                <Card
                  key={message.task_id}
                  variant="outline"
                  width="100%"
                  position="relative"
                  bg={
                    selectedTaskId === message.task_id ? "primary.1" : "white"
                  }
                  onClick={() => handleChatClick(message.task_id)}
                >
                  <CardBody cursor={"pointer"}>
                    <Stack spacing="2">
                      <Text
                        fontSize="xl"
                        fontWeight="bold"
                        color={
                          selectedTaskId === message.task_id ? "white" : "black"
                        }
                      >
                        {message.task_subject}
                        {message.trid ? ` - ${message.trid}` : ""}
                      </Text>

                      <Text
                        fontSize="md"
                        color={
                          selectedTaskId === message.task_id
                            ? "white"
                            : "gray.500"
                        }
                      >
                        Status: {message.task_status}
                      </Text>
                      <ChakraBox
                        position="absolute"
                        bottom="4"
                        right="4"
                        fontSize="sm"
                        color={
                          selectedTaskId === message.task_id
                            ? "white"
                            : "gray.500"
                        }
                      >
                        {message.created_at}
                      </ChakraBox>
                    </Stack>
                  </CardBody>
                </Card>
              ))}
            </VStack>
          )}
        </Box>
        <Box
          width="50%"
          mt="20px"
          ml="10px"
          shadow="md"
          borderWidth="1px"
          borderRadius="lg"
          minHeight="600px"
          background={"white"}
        >
          <ChatBot
            // isChatBotVisible={isChatBotVisible}
            // setIsChatBotVisible={setIsChatBotVisible}
            openChat={openChat}
            selectedTaskId={selectedTaskId}
            newChat={newChat}
            setNewChat={setNewChat}
          />
        </Box>
      </Flex>
    </Layout>
  );
};

export default Messages;
