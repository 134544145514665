import React from "react";
import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Box,
  Flex,
} from "@chakra-ui/react";
import Layout from "../../layout";
// import { useDispatch } from "react-redux";
import DashboardIcon from "../../assets/images/Dashboard.svg";
import ExistingPatientsIcon from "../../assets/images/ExistingPatients.svg";
import FlightTicketsIcon from "../../assets/images/FlightTickets.svg";
import MedicalOpinionIcon from "../../assets/images/MedicalOpinion.svg";
import PreIntimationIcon from "../../assets/images/PreIntimation.svg";
import TravelRequestIcon from "../../assets/images/TravelRequest.svg";

const HcfHome = () => {
  // const dispatch = useDispatch();
  const data = [
    {
      name: "Medical Opinion",
      icon: MedicalOpinionIcon,
      url: "/medical-opinion",
    },
    {
      name: "Travel Request",
      icon: TravelRequestIcon,
      url: "/travel-request",
    },
    {
      name: "Flight Tickets",
      icon: FlightTicketsIcon,
      url: "/flight-tickets",
    },
    {
      name: "Pre-Intimation",
      icon: PreIntimationIcon,
      url: "/pre-intimation",
    },
    {
      name: "Existing Patients",
      icon: ExistingPatientsIcon,
      url: "/existing-patients",
    },
    {
      name: "Dashboard",
      icon: DashboardIcon,
      url: "/h/dashboard",
    },
  ];

  const handleCardClick = (url) => {
    window.location.href = url; // Redirect to the specified URL
  };

  return (
    <Layout>
      {/* <Heading size="md" paddingBottom="20px">
        Home
      </Heading> */}
      {/* <SimpleGrid
        spacing={4}
        templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
      > */}
      <SimpleGrid
        spacing={4}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} // 1 column on small screens, 3 columns on medium and larger screens
      >
        {data.map((item, index) => (
          <Card
            key={index}
            onClick={() => handleCardClick(item.url)}
            cursor="pointer"
            // _hover={{ background: "#55BE7D" }}
            _hover={{
              background: "#2D67B0",
              ".hover-text": { color: "white" },
            }}
            // boxShadow="none"
            // background="transparent"
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              height="100%"
            >
              <CardHeader>
                <Box>
                  <img
                    src={item.icon}
                    alt={`${item.name} icon`}
                    style={{ width: "200px", height: "200px" }}
                  />
                </Box>
              </CardHeader>
              <CardBody>
                <Heading size="md" textAlign="center" className="hover-text">
                  {item.name}
                </Heading>
              </CardBody>
            </Flex>
          </Card>
        ))}
      </SimpleGrid>
    </Layout>
  );
};

export default HcfHome;
