import { put, all, takeLatest } from "redux-saga/effects";

import {
  HIDE_LOADER,
  SHOW_LOADER,
  apiError,
  exceptionAPIError,
  exceptionError,
  exceptionSessionExpire,
} from "../../authentication/authStore/actions";
import {
  FETCH_NOTIFICATIONS_REQUEST,
  fetchNotificationSuccess,
} from "./actions";
import api from "../../../config/api";

function* notificationsDataWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  let response;
  try {
    console.log("Payload");
    if (payload) {
      response = yield api.get("qms.api.fetch_notifications", payload, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    } else {
      var data = {};
      response = yield api.get("qms.api.fetch_notifications", data, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    }
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (payload.is_master) {
          yield put(fetchNotificationSuccess(response.data));
        } else {
          yield put(fetchNotificationSuccess(response.data));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

export default function* notificationSaga() {
  yield all([takeLatest(FETCH_NOTIFICATIONS_REQUEST, notificationsDataWorker)]);
}
