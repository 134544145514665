import React, { useEffect, useState } from "react";
import {
  Collapse,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import CollapseIcon from "../../../assets/images/collapse.svg";
import PrimaryHeading from "../../../components/PrimaryHeading";
import PrimaryFormLabel from "../../../components/PrimaryFormLabel";
import { Select } from "chakra-react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHealthConcernRequest,
  fetchHospitalsRequest,
  fetchPreferredCountryRequest,
} from "../hcfStore/actions";
import { handleClear } from "../form/utils";

const HealthConcernSection = ({
  formErrors,
  formData,
  setFormData,
  setFormErrors,
  selectedOrder = [],
}) => {
  const dispatch = useDispatch();

  const [healthConcernDetails, setHealthConcernDetails] = useState([]);
  const [preferredCountryDetails, setPreferredCountryDetails] = useState([]);
  const [hospitalDetails, setHospitalDetails] = useState([]);

  // State to keep track of the order of selections
  const [selectionOrder, setSelectionOrder] = useState(selectedOrder);
  const { isOpen: isSectionOpen, onToggle: toggleSection } = useDisclosure({
    defaultIsOpen: true,
  });

  const {
    hospitalData,
    healthConcernData,
    preferredCountryData,
    preferredCountryMaster,
    hospitalMaster,
    healthConcernMaster,
  } = useSelector((state) => state.hcf);

  useEffect(() => {
    setSelectionOrder(selectedOrder);
  }, [selectedOrder]);

  useEffect(() => {
    setHealthConcernDetails(healthConcernMaster);
  }, [healthConcernMaster]);

  useEffect(() => {
    setHospitalDetails(hospitalMaster);
  }, [hospitalMaster]);

  useEffect(() => {
    setPreferredCountryDetails(preferredCountryMaster);
  }, [preferredCountryMaster]);

  useEffect(() => {
    setHealthConcernDetails(healthConcernData);
  }, [healthConcernData]);

  useEffect(() => {
    setHospitalDetails(hospitalData);
  }, [hospitalData]);

  useEffect(() => {
    setPreferredCountryDetails(preferredCountryData);
  }, [preferredCountryData]);

  const handleSelectChange = (selectedOption, { name }) => {
    // Update formData
    const newFormData = { ...formData, [name]: selectedOption };
    setFormData(newFormData);
    setFormErrors({ ...formErrors, [name]: false });
    const newSelectionOrder = [...selectionOrder];

    // Update selection order
    // const newSelectionOrder = [...selectionOrder];
    if (!newSelectionOrder.includes(name)) {
      newSelectionOrder.push(name);
    }
    setSelectionOrder(newSelectionOrder);

    if (newSelectionOrder.length === 1 || newSelectionOrder[0] === name) {
      // Handle API calls based on the order of selections
      // First selection
      const firstSelection = newSelectionOrder[0];
      fetchDataForSecondAndThird(firstSelection, newFormData[firstSelection]);
    } else if (
      newSelectionOrder.length === 2 ||
      newSelectionOrder[1] === name
    ) {
      // Second selection
      const [firstSelection, secondSelection] = newSelectionOrder;
      fetchDataForThird(
        firstSelection,
        secondSelection,
        newFormData[firstSelection],
        newFormData[secondSelection]
      );
    } else if (newSelectionOrder.length === 3) {
      // No additional API calls required
    }
  };

  const fetchDataForSecondAndThird = (firstSelection, firstValue) => {
    try {
      if (firstSelection === "healthConcern") {
        let payload = { health_concern: firstValue.value, is_master: false };
        setFormData((prevData) => ({
          ...prevData,
          hospital: "",
          preferredCountry: "",
        }));
        dispatch(fetchHospitalsRequest(payload));
        dispatch(fetchPreferredCountryRequest(payload));
      } else if (firstSelection === "preferredCountry") {
        let payload = { country: firstValue.value, is_master: false };
        setFormData((prevData) => ({
          ...prevData,
          healthConcern: "",
          hospital: "",
        }));
        dispatch(fetchHealthConcernRequest(payload));
        dispatch(fetchHospitalsRequest(payload));
      } else if (firstSelection === "hospital") {
        let payload = { hospital: firstValue.value, is_master: false };
        setFormData((prevData) => ({
          ...prevData,
          healthConcern: "",
          preferredCountry: "",
        }));
        dispatch(fetchHealthConcernRequest(payload));
        dispatch(fetchPreferredCountryRequest(payload));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataForThird = (
    firstSelection,
    secondSelection,
    firstValue,
    secondValue
  ) => {
    try {
      if (
        (firstSelection === "healthConcern" &&
          secondSelection === "preferredCountry") ||
        (firstSelection === "preferredCountry" &&
          secondSelection === "healthConcern")
      ) {
        setFormData((prevData) => ({
          ...prevData,
          hospital: "",
        }));
        let payload = {};
        if (
          firstSelection === "healthConcern" &&
          secondSelection === "preferredCountry"
        ) {
          payload = {
            health_concern: firstValue.value,
            country: secondValue.value,
            is_master: false,
          };
        } else {
          payload = {
            country: firstValue.value,
            health_concern: secondValue.value,
            is_master: false,
          };
        }

        dispatch(fetchHospitalsRequest(payload));
      } else if (
        (firstSelection === "healthConcern" &&
          secondSelection === "hospital") ||
        (firstSelection === "hospital" && secondSelection === "healthConcern")
      ) {
        setFormData((prevData) => ({
          ...prevData,
          preferredCountry: "",
        }));
        let payload = {};
        if (
          firstSelection === "healthConcern" &&
          secondSelection === "hospital"
        ) {
          payload = {
            health_concern: firstValue.value,
            hospital: secondValue.value,
            is_master: false,
          };
        } else {
          payload = {
            hospital: firstValue.value,
            health_concern: secondValue.value,
            is_master: false,
          };
        }

        dispatch(fetchPreferredCountryRequest(payload));
      } else if (
        (firstSelection === "preferredCountry" &&
          secondSelection === "hospital") ||
        (firstSelection === "hospital" &&
          secondSelection === "preferredCountry")
      ) {
        setFormData((prevData) => ({
          ...prevData,
          healthConcern: "",
        }));
        let payload = {};
        if (
          firstSelection === "preferredCountry" &&
          secondSelection === "hospital"
        ) {
          payload = {
            country: firstValue.value,
            hospital: secondValue.value,
            is_master: false,
          };
        } else {
          payload = {
            hospital: firstValue.value,
            country: secondValue.value,
            is_master: false,
          };
        }
        dispatch(fetchHealthConcernRequest(payload));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpdateMasterData = () => {
    setSelectionOrder([]);
    setHealthConcernDetails(healthConcernMaster);
    setHospitalDetails(hospitalMaster);
    setPreferredCountryDetails(preferredCountryMaster);
  };

  return (
    <>
      <Flex alignItems="center" mt="20px">
        <PrimaryHeading>Health Concern</PrimaryHeading>
        <img
          src={CollapseIcon}
          width={"24px"}
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            transition: "transform 0.3s ease",
            transform: isSectionOpen ? "rotate(0)" : "rotate(180deg)",
          }}
          onClick={toggleSection}
        />
      </Flex>
      <Collapse in={isSectionOpen} animateOpacity>
        <Box
          bg="white"
          borderRadius="10px"
          mt="10px"
          display="flex"
          flexDirection={{ base: "column", md: "column", lg: "row" }}
          flexWrap="wrap"
          justifyContent="space-between"
          p="15px 20px"
        >
          <FormControl
            flex="30%"
            mr="10px"
            isRequired
            isInvalid={formErrors.healthConcern}
          >
            <PrimaryFormLabel htmlFor="healthConcern">
              Health Concern
            </PrimaryFormLabel>
            <Select
              id="healthConcern"
              name="healthConcern"
              placeholder="Select Health Concern"
              options={healthConcernDetails}
              menuPortalTarget={document.body}
              classNamePrefix="chakra-react-select"
              value={formData.healthConcern}
              onChange={handleSelectChange}
            />
            <FormErrorMessage>{formErrors.healthConcern}</FormErrorMessage>
          </FormControl>
          <FormControl
            flex="30%"
            mr="10px"
            isRequired
            isInvalid={formErrors.preferredCountry}
          >
            <PrimaryFormLabel htmlFor="preferredCountry">
              Preferred Country
            </PrimaryFormLabel>
            <Select
              id="preferredCountry"
              name="preferredCountry"
              placeholder="Select Country"
              options={preferredCountryDetails}
              menuPortalTarget={document.body}
              classNamePrefix="chakra-react-select"
              value={formData.preferredCountry}
              onChange={handleSelectChange}
            />

            <FormErrorMessage>{formErrors.preferredCountry}</FormErrorMessage>
          </FormControl>

          <FormControl flex="30%" isRequired isInvalid={formErrors.hospital}>
            <PrimaryFormLabel htmlFor="hospital">Hospital</PrimaryFormLabel>
            <Select
              id="hospital"
              name="hospital"
              placeholder="Select Hospital"
              options={hospitalDetails}
              menuPortalTarget={document.body}
              classNamePrefix="chakra-react-select"
              value={formData.hospital}
              onChange={handleSelectChange}
            />
            <FormErrorMessage>{formErrors.hospital}</FormErrorMessage>
          </FormControl>

          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button
              mt="20px"
              colorScheme="red"
              variant="ghost"
              onClick={(e) => {
                handleClear(setFormData);
                handleUpdateMasterData();
              }}
            >
              Clear Selection
            </Button>
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

export default HealthConcernSection;
