import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index.js";

import { Login } from "./features/authentication/Login.js";
import { Register } from "./features/authentication/Register.js";
import { ForgotPassword } from "./features/authentication/ForgotPassword.js";
import { ValidateForgotPasswordOtp } from "./features/authentication/ValidateForgotPasswordOtp.js";
import { ChangePassword } from "./features/authentication/ChangePassword.js";
import { RequireAuth } from "./config/RequireAuth.js";
import PageNotFound from "./components/PageNotFound.js";
import Unauthorized from "./components/Unauthorized.js";
import MedicalOpinion from "./features/hcf/medicalOpinion/MedicalOpinion.js";
import HcfHome from "./features/hcf/HcfHome.js";
import TravelRequest from "./features/hcf/travelRequest/index.js";
import FlightTickets from "./features/hcf/flightTicket/index.js";
import PreIntimation from "./features/hcf/medicalOpinion/PreIntimation.js";
import ExistingPatients from "./features/hcf/existingPatients/index.js";
import Dashboard from "./features/hcf/dashboard/index.js";
import UserProfile from "../src/features/authentication/Profile.js";
import DashboardView from "./features/hcf/dashboard/DashboardView.js";
import Notifications from "./features/notifications/index.js";
import Messages from "./features/messages/index.js";
import SearchResults from "./features/search/SearchResults.js";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* Public routes */}
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/forgot-password/otp"
        element={<ValidateForgotPasswordOtp />}
      />
      <Route path="/change-password" element={<ChangePassword />} />
      {/* Routes with required authentication */}
      {/* <Route element={<RequireAuth roles={["HCF", "ADMIN"]} />}> */}
      <Route path="h">
        {/* <Route index element={<div>HCF Page</div>} /> */}
        <Route path="home" element={<HcfHome />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboard/summary" element={<DashboardView />} />
      </Route>
      {/* </Route> */}

      <Route path="/medical-opinion" element={<MedicalOpinion />} />
      <Route path="/travel-request" element={<TravelRequest />} />
      <Route path="/flight-tickets" element={<FlightTickets />} />
      <Route path="/pre-intimation" element={<PreIntimation />} />

      <Route path="/existing-patients" element={<ExistingPatients />} />

      <Route path="/profile" element={<UserProfile />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/search" element={<SearchResults />} />
      {/* Routes with required authentication */}
      <Route element={<RequireAuth roles={["HCF", "ADMIN"]} />}>
        <Route path="h">
          <Route index element={<div>HCF Page</div>} />
          <Route path="medical-opinion" element={<div>Medical Opinion</div>} />
        </Route>
      </Route>
      {/* Unauthorized route */}
      <Route path="/unauthorized" element={<Unauthorized />} />

      {/* Fallback route */}
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
