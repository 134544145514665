import React, { useEffect } from "react";
import {
  Flex,
  Box,
  Collapse,
  useDisclosure,
  Textarea,
  Stack,
  Radio,
  RadioGroup,
  FormControl,
} from "@chakra-ui/react";
import Layout from "../../../layout";
import { useDispatch } from "react-redux";
import { useState, useRef } from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import HealthConcernSection from "./HealthConcernSection";
import PatientDetailsSection from "./PatientDetailsSection";
import PatientPassportSection from "./PatientPassportSection";
import UploadReportsSection from "./UploadReportsSection";
import CollapseIcon from "../../../assets/images/collapse.svg";
import {
  handleSelectChange,
  handleChange,
  handlePassportFileChange,
  formatMobileNumber,
} from "./formUtils";
import {
  handleReportsFileChange,
  removeFile,
  handleViewFile,
} from "./fileUtils";
import { medicalOpinionFormData } from "./FormData";
import { medicalOpinionValidateForm } from "./ValidationUtils";
import "../base.css";
import {
  fetchCountryRequest,
  fetchHealthConcernRequest,
  fetchHospitalsRequest,
  fetchPreferredCountryRequest,
  fetchTrDetailsRequest,
  fetchTridsRequest,
  medicalOpinionRequest,
  verifyExistingPatientRequest,
} from "../hcfStore/actions";
import { useSelector } from "react-redux";
import QRCodeModal from "../components/QRCodeModel";
import Alert from "../../../components/Alert";
import { useBlocker, useNavigate } from "react-router-dom";
import PrimaryHeading from "../../../components/PrimaryHeading";
import FooterCheckBox from "../components/FooterCheckbox";
import { fetchProfileDetailsRequest } from "../../authentication/authStore/actions";
import PrimaryFormLabel from "../../../components/PrimaryFormLabel";
import { Select } from "chakra-react-select";
import { MedicalOpinionFreezeFields } from "../constants";
import PatientDetailsModal from "../components/PatientDetailsModal";

const MedicalOpinion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState(medicalOpinionFormData);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  // const [totalSizeExceeded, setTotalSizeExceeded] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isUnsaveDialogOpen, setIsUnsaveDialogOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showConfirmError, setShowConfirmError] = useState("");
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [isAutopopulateData, setIsAutopopulateData] = useState(false);
  const [initialPatientPassport, setInitialPatientPassport] = useState("");
  const [selectedMedicalOpinion, setSelectedMedicalOpinion] = useState("1");
  const [isTrdetailsUpdated, setIsTrdetailsUpdated] = useState(false);
  const [selectTrid, setSelectTrid] = useState(false);
  const [existingPatientAlert, setExistingPatientAlert] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [autoPopulatedFields, setAutoPopulatedFields] = useState({
    MedicalOpinionFreezeFields,
  });

  const {
    medicalOpinionResponse,
    trDetails,
    tridData,
    trid,
    verifyExistingPatientResponse,
  } = useSelector((state) => state.hcf);
  const { profileDetails } = useSelector((state) => state.auth);
  const name = "reports";

  const { isOpen: isSectionOpen, onToggle: toggleSection } = useDisclosure({
    defaultIsOpen: true,
  });

  useEffect(() => {
    let payload = { is_master: true };
    dispatch(fetchHospitalsRequest(payload));
    dispatch(fetchPreferredCountryRequest(payload));
    dispatch(fetchHealthConcernRequest(payload));
    dispatch(fetchCountryRequest());
    payload = {
      action: "get",
    };
    dispatch(fetchProfileDetailsRequest(payload));
    if (selectedMedicalOpinion === "2") {
      setIsTrdetailsUpdated(true);
    }
    payload = { request_type: "MO" };
    dispatch(fetchTridsRequest(payload));
  }, []);

  useEffect(() => {
    if (selectTrid) {
      let payload = { tr_id: selectTrid.value };
      if (isTrdetailsUpdated) {
        dispatch(fetchTrDetailsRequest(payload));
      } else {
        dispatch(fetchTrDetailsRequest(payload));
      }
    }
  }, [selectTrid]);

  useEffect(() => {
    // if (trDetails && trDetails.length > 0 && !selectTrid) {
    //   setIsModalOpen(true);
    // }
    if (trDetails && trDetails.length > 0 && !isModalOpen) {
      if (!selectTrid) {
        let data = {
          label: trDetails[0].tr_id_label,
          value: trDetails[0].id,
        };
        setSelectTrid(data);
        setSelectedMedicalOpinion("2");
      }

      setInitialPatientPassport(trDetails[0].patient_passport);
      setSelectedOrder(["healthConcern", "hospital"]);
      setIsAutopopulateData(true);

      let payload = {
        health_concern: trDetails[0].health_concern.value,
        is_master: false,
      };
      dispatch(fetchHospitalsRequest(payload));

      payload = {
        health_concern: trDetails[0].health_concern.value,
        hospital: trDetails[0].hospital,
        is_master: false,
      };
      dispatch(fetchPreferredCountryRequest(payload));
      setFormData((prevFormData) => ({
        ...prevFormData,
        healthConcern: trDetails[0]?.health_concern || "",
        preferredCountry: trDetails[0]?.preferred_country
          ? {
              value: trDetails[0].preferred_country,
              label: trDetails[0].preferred_country,
            }
          : "",
        hospital: trDetails[0]?.hospital
          ? { value: trDetails[0].hospital, label: trDetails[0].hospital }
          : "",
        gender: trDetails[0]?.gender
          ? { value: trDetails[0].gender, label: trDetails[0].gender }
          : "",
        nationality: trDetails[0]?.nationality
          ? {
              value: trDetails[0].nationality,
              label: trDetails[0].nationality,
            }
          : "",
        countryOfResidence: trDetails[0]?.country_of_residency
          ? {
              value: trDetails[0].country_of_residency,
              label: trDetails[0].country_of_residency,
            }
          : "",
        passport: trDetails[0]?.patient_passport || "",
        name: trDetails[0]?.patient_name || "",
        passportNumber: trDetails[0]?.passport_no || "",
        dob: trDetails[0]?.dob || "",
        age: trDetails[0]?.age || "",
        mobileNumber: trDetails[0]?.mobile_no || "",
      }));

      // Initialize autopopulated fields
      if (trDetails[0].vil_status !== "Not Requested") {
        const initialAutoPopulatedFields = {
          name: !!trDetails[0].patient_name,
          passport: !!trDetails[0].patient_passport,
          passportNumber: !!trDetails[0].passport_no,
          dob: !!trDetails[0].dob,
          countryOfResidence: !!trDetails[0].country_of_residency,
          nationality: !!trDetails[0].nationality,
          gender: !!trDetails[0].gender,
        };

        setAutoPopulatedFields(initialAutoPopulatedFields);
      } else {
        setAutoPopulatedFields(MedicalOpinionFreezeFields);
      }
    } else {
      setSelectedOrder([]);
    }
  }, [trDetails]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const hasValues = Object.values(formData).some(
      (value) =>
        Boolean(value) &&
        (typeof value !== "object" || Object.keys(value).length > 0)
    );
    setHasUnsavedChanges(hasValues);
  }, [formData]);

  const shouldBlockNavigation = () => {
    if (hasUnsavedChanges) {
      setIsUnsaveDialogOpen(true);
      return "Are you sure you want to leave? You may lose unsaved changes.";
    }
    return null;
  };

  const blocker = useBlocker(shouldBlockNavigation);

  useEffect(() => {
    if (medicalOpinionResponse) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [medicalOpinionResponse]);

  useEffect(() => {
    if (verifyExistingPatientResponse) {
      setExistingPatientAlert(true);
    } else {
      setExistingPatientAlert(false);
    }
  }, [verifyExistingPatientResponse]);

  useEffect(() => {
    if (!isAutopopulateData) {
      if (formData.passportNumber && formData.hospital) {
        if (formData.passportNumber.length > 6) {
          handlePatientCheck(formData.hospital.value, formData.passportNumber);
        }
      }
    }
  }, [formData.passportNumber, formData.hospital]);

  const handlePatientCheck = (hospital, passportNumber) => {
    const payload = {
      hospital: hospital,
      passport_no: passportNumber,
      request_type: "MO",
    };

    dispatch(verifyExistingPatientRequest(payload));
  };

  const handlePatientCard = (id) => {
    let payload = {
      tr_id: verifyExistingPatientResponse.data.value,
    };
    if (verifyExistingPatientResponse.data.is_existing_rec === "1") {
      setIsModalOpen(true);
    } else {
      let data = {
        label: verifyExistingPatientResponse.data.label,
        value: verifyExistingPatientResponse.data.value,
      };
      setSelectTrid(data);
      setSelectedMedicalOpinion("2");
      setIsModalOpen(false);
    }
    dispatch(fetchTrDetailsRequest(payload));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let errors = validateForm(formData, formErrors);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (!isConfirmed) {
        setShowConfirmError("Please check the checkbox to continue..!");
        return;
      }
      if (formErrors.mobileNumber) {
        return;
      }
      if (formData.countryCode) {
        const formattedMobileNumber = formatMobileNumber(
          formData.mobileNumber,
          formData.countryCode
        );
        formData.mobileNumber = formattedMobileNumber;
      }
      let payload = new FormData();
      payload.append("is_preintimation", "0");
      payload.append("health_concern", formData.healthConcern.value);
      payload.append("preferred_country", formData.preferredCountry.value);
      payload.append("hospital", formData.hospital.value);
      payload.append("patient_name", formData.name);
      payload.append("gender", formData.gender.value);
      payload.append("nationality", formData.nationality.value);
      payload.append("country_of_res", formData.countryOfResidence.value);
      payload.append("dob", formData.dob);
      payload.append("remarks", formData.remarks);
      if (formData.mobileNumber && formData.mobileNumber !== "") {
        payload.append("mobile_no", formData.mobileNumber);
      }
      if (formData.passport && formData.passport !== "") {
        payload.append("passport_no", formData.passportNumber);
      }

      // Append passport image if available
      // if (formData.passport) {
      //   payload.append("passport", formData.passport);
      // }

      if (formData.passport) {
        if (isAutopopulateData) {
          payload.append("tr_id", trDetails[0].id);
          if (formData.passport !== initialPatientPassport) {
            payload.append("passport", formData.passport);
          }
        } else {
          payload.append("passport", formData.passport);
        }
      }

      if (formData.reports && formData.reports.length > 0) {
        formData.reports.forEach((report) => {
          payload.append("reports", report);
        });
      }
      setHasUnsavedChanges(false);
      dispatch(medicalOpinionRequest(payload));
    } else {
      setErrorMessage(true);
    }
  };

  const validateForm = (values, formErrors) => {
    return medicalOpinionValidateForm(values, formErrors);
  };

  const handleRadioButtonChange = (value) => {
    setSelectedMedicalOpinion(value);
    if (value === "1") {
      setSelectedOrder([]);
    } else {
      setSelectedOrder(["healthConcern", "hospital"]);
    }
    setFormData(medicalOpinionFormData);
    setSelectTrid(false);
    setIsAutopopulateData(false);
    setFormErrors({});
  };

  return (
    <Layout header="Medical Opinion" iconUrl="/h/home">
      <Flex alignItems="center" mt="20px">
        <RadioGroup
          defaultValue="1"
          mb="10px"
          ml="10px"
          onChange={handleRadioButtonChange}
          value={selectedMedicalOpinion}
        >
          <Stack spacing={5} direction="row">
            <Radio colorScheme="blue" value="1">
              <PrimaryFormLabel mt="10px">New Medical Opinion</PrimaryFormLabel>
            </Radio>
            <Radio colorScheme="blue" value="2">
              <PrimaryFormLabel mt="10px">Existing TR ID</PrimaryFormLabel>
            </Radio>
          </Stack>
        </RadioGroup>
      </Flex>
      {selectedMedicalOpinion === "2" && (
        <Flex mb="20px" ml="10px">
          <Box
            display="flex"
            flexDirection="column"
            bgColor="white"
            p="20px"
            borderRadius="10px"
            flex={{ base: "50px", md: "calc(50% - 10px)" }}
            mr="10px"
          >
            <Box
              display="flex"
              flexDirection={{ base: "column", md: "column" }}
              justifyContent="space-between"
              width={{ base: "100%", md: "50%" }}
            >
              <PrimaryFormLabel>Select TR ID</PrimaryFormLabel>
              <Select
                placeholder="Select TRID"
                id={"trid"}
                name={"trid"}
                menuPortalTarget={document.body}
                classNamePrefix="chakra-react-select"
                options={tridData}
                onChange={(value) => setSelectTrid(value)}
                value={selectTrid}
              />
            </Box>
          </Box>
        </Flex>
      )}
      {(selectedMedicalOpinion === "1" ||
        (selectedMedicalOpinion === "2" && selectTrid)) && (
        <Box p="0 10px" mb="10px">
          <form>
            <HealthConcernSection
              formErrors={formErrors}
              formData={formData}
              setFormData={setFormData}
              setFormErrors={setFormErrors}
              selectedOrder={selectedOrder}
              isAutopopulateData={isAutopopulateData}
              trDetails={trDetails}
              fromScreen="MO"
              // handleSelectChange={(selectedOption, actionMeta) =>
              //   handleSelectChange(
              //     formData,
              //     setFormData,
              //     formErrors,
              //     setFormErrors,
              //     selectedOption,
              //     actionMeta,
              //     dispatch
              //   )
              // }
            />
            <PatientPassportSection
              formErrors={formErrors}
              formData={formData}
              setFormData={setFormData}
              setFormErrors={setFormErrors}
              handlePassportFileChange={(event) =>
                handlePassportFileChange(
                  formData,
                  setFormData,
                  formErrors,
                  setFormErrors,
                  dispatch,
                  event
                )
              }
              isRequired={false}
              isAutopopulateData={isAutopopulateData}
              autoPopulatedFields={autoPopulatedFields}
            />
            <PatientDetailsSection
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              formData={formData}
              setFormData={setFormData}
              handleSelectChange={(selectedOption, actionMeta) =>
                handleSelectChange(
                  formData,
                  setFormData,
                  formErrors,
                  setFormErrors,
                  selectedOption,
                  actionMeta
                )
              }
              handleChange={(e) =>
                handleChange(
                  formData,
                  setFormData,
                  formErrors,
                  setFormErrors,
                  e
                )
              }
              isPassportRequired={false}
              isAutopopulateData={isAutopopulateData}
              autoPopulatedFields={autoPopulatedFields}
            />
            <Flex alignItems="center" className="collapse-section-header">
              <PrimaryHeading>Patient Reports</PrimaryHeading>
              <img
                src={CollapseIcon}
                width="24px"
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  transition: "transform 0.3s ease",
                  transform: isSectionOpen ? "rotate(0)" : "rotate(180deg)",
                }}
                onClick={toggleSection}
              />
            </Flex>
            <Collapse in={isSectionOpen} animateOpacity>
              <UploadReportsSection
                errors={formErrors}
                selectedFile={selectedFile}
                // totalSizeExceeded={totalSizeExceeded}
                handleMultiFileChange={(event) =>
                  handleReportsFileChange(
                    event,
                    selectedFile,
                    setSelectedFile,
                    // setTotalSizeExceeded,
                    formData,
                    setFormData,
                    formErrors,
                    setFormErrors,
                    name
                  )
                }
                handleViewFile={handleViewFile}
                removeFile={(index) =>
                  removeFile(
                    index,
                    selectedFile,
                    setSelectedFile,
                    formData,
                    setFormData,
                    formErrors,
                    setFormErrors
                  )
                }
                fileInputRef={fileInputRef}
                label="Upload Reports"
                id="reports"
              />
            </Collapse>

            <Box
              bg="white"
              borderRadius="10px"
              mt="10px"
              display="flex"
              flexDirection={{ base: "column", md: "column", lg: "row" }}
              flexWrap="wrap"
              justifyContent="space-between"
              className="single-box"
            >
              <FormControl>
                <PrimaryFormLabel htmlFor="Remarks">Remarks</PrimaryFormLabel>
                <Textarea
                  id="remarks"
                  name="remarks"
                  value={formData.remarks}
                  placeholder="Enter your remarks here..."
                  onChange={(e) =>
                    handleChange(
                      formData,
                      setFormData,
                      formErrors,
                      setFormErrors,
                      e
                    )
                  }
                />
              </FormControl>
            </Box>

            <Flex justifyContent={"right"} mt="4">
              <FooterCheckBox
                msg={`I ${profileDetails.fullname} have been given the consent to share, store all medical and non-medical reports.`}
                isChecked={isConfirmed}
                setIsChecked={setIsConfirmed}
                checkboxError={showConfirmError}
              />
              <PrimaryButton type="submit" onClick={onSubmit}>
                Submit
              </PrimaryButton>
            </Flex>
          </form>
          <Alert
            isOpen={errorMessage}
            onClose={() => {
              setErrorMessage(false);
            }}
            body="Please fill all mandatory fields"
          />
          <Alert
            isOpen={isUnsaveDialogOpen}
            onClose={() => {
              setIsUnsaveDialogOpen(false);
            }}
            showCancelButton={true}
            title="Unsaved Changes!"
            body="Are you sure you want to leave? You may lose unsaved changes."
            type="info"
            callback={blocker.proceed}
          />
          <Alert
            isOpen={existingPatientAlert}
            onClose={() => {
              setExistingPatientAlert(false);
              setFormData(medicalOpinionFormData);
              // setIsModalOpen(false);
            }}
            showCancelButton={true}
            title="Info"
            body={verifyExistingPatientResponse.message}
            type="info"
            callback={handlePatientCard}
          />
          <QRCodeModal
            isOpen={showAlert}
            onClose={() => {
              setShowAlert(false);
              navigate("/existing-patients");
            }}
            name={medicalOpinionResponse?.patient_name}
            trid={medicalOpinionResponse?.tr_id}
            requestType="Medical Opinion"
            qrCodeImageUrl={medicalOpinionResponse?.qr_code_url}
            qrCodeImageContentUrl={
              medicalOpinionResponse?.qr_code_image_content
            }
          />
          {trDetails && (
            <PatientDetailsModal
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                setExistingPatientAlert(false);
                setFormData(medicalOpinionFormData);
              }}
              patient={trDetails?.[0] || []}
            />
          )}
        </Box>
      )}
    </Layout>
  );
};

export default MedicalOpinion;
