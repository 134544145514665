import React, { useRef } from "react";
import {
  FormControl,
  Input,
  List,
  ListItem,
  Box,
  IconButton,
  FormErrorMessage,
  FormHelperText,
  Text,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { IoClose } from "react-icons/io5";
import PrimaryFormLabel from "./PrimaryFormLabel";

const FormInputFile = ({
  name,
  label,
  formattedLabel,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  dispatch,
  handlePassportFileChange,
  handleViewFile,
  removeFile,
}) => {
  const fileInputRefs = useRef({});

  return (
    <FormControl
      id={`${label.toLowerCase()}-passportUpload`} // Unique ID for the passport upload field
      flex={{ base: "100%", md: "calc(50% - 10px)" }}
      mr={{ base: "0", md: "20px" }}
      mb={{ base: "20px", md: "0" }}
      isInvalid={!!formErrors[`${formattedLabel}Passport`]}
      isRequired
    >
      {/* <PrimaryButton
        onClick={() =>
          fileInputRefs.current[`${formattedLabel}Passport`].click()
        }
        mb="4"
      >
        {name}
      </PrimaryButton> */}
      <PrimaryFormLabel htmlFor={`${formattedLabel}Passport`}>
        {name}
      </PrimaryFormLabel>
      <Input
        // style={{ display: "none" }}
        mt="10px"
        ref={(el) => (fileInputRefs.current[`${formattedLabel}Passport`] = el)}
        name={`${formattedLabel}Passport`}
        textAlign="center"
        placeholder="Upload Passport"
        type="file"
        variant="filled"
        h="auto"
        width="101%"
        p="10px"
        color="primary.1"
        onChange={(e) =>
          handlePassportFileChange(
            formData,
            setFormData,
            formErrors,
            setFormErrors,
            formattedLabel,
            dispatch,
            e
          )
        }
        accept=".pdf,.jpg,.jpeg,.png"
      />
      <FormHelperText>
        Note: Please upload file in PNG, JPG, JPEG, or PDF format. Maximum file
        size: 10MB.
      </FormHelperText>
      <FormErrorMessage>
        {formErrors[`${formattedLabel}Passport`]}
      </FormErrorMessage>
      {formData[`${formattedLabel}Passport`] && (
        <List mt={3}>
          <ListItem display="flex" alignItems="center">
            {/* <Box flex="1">
              {formData[`${formattedLabel}Passport`].name} (
              {(
                formData[`${formattedLabel}Passport`].size /
                (1024 * 1024)
              ).toFixed(2)}{" "}
              MB)
            </Box> */}
            <Box flex="1">
              <Text fontSize="sm" isTruncated>
                {typeof formData[`${formattedLabel}Passport`] === "string"
                  ? new URL(formData[`${formattedLabel}Passport`]).pathname
                      .split("/")
                      .pop()
                  : formData[`${formattedLabel}Passport`]?.name ||
                    "No file selected"}
              </Text>

              {typeof formData[`${formattedLabel}Passport`] !== "string" &&
                formData[`${formattedLabel}Passport`]?.size && (
                  <Text fontSize="xs" color="gray.500">
                    {(
                      formData[`${formattedLabel}Passport`].size /
                      (1024 * 1024)
                    ).toFixed(2)}{" "}
                    MB
                  </Text>
                )}
            </Box>
            <IconButton
              variant="ghost"
              colorScheme="blue"
              size="sm"
              aria-label="View"
              icon={<ViewIcon />}
              onClick={() =>
                handleViewFile(formData[`${formattedLabel}Passport`])
              }
              mr="2"
            />
            <IconButton
              variant="ghost"
              colorScheme="red"
              size="sm"
              aria-label="Remove"
              icon={<IoClose />}
              onClick={() =>
                removeFile(
                  fileInputRefs,
                  `${formattedLabel}Passport`,
                  setFormData,
                  setFormErrors
                )
              }
            />
          </ListItem>
        </List>
      )}
    </FormControl>
  );
};

export default FormInputFile;
