export const genderData = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];

export const patientMovingFromData = [
  { value: "Within Country", label: "Within Country" },
  { value: "Outside Country", label: "Outside Country" },
];

export const arrivalWithinData = [
  { value: "Less than 3 Days", label: "Less than 3 Days" },
  { value: "Less than 7 Days", label: "Less than 7 Days" },
  { value: "Less than 15 Days", label: "Less than 15 Days" },
  { value: "Less than 30 Days", label: "Less than 30 Days" },
  { value: "More than 30 Days", label: "More than 30 Days" },
];

export const Days = [
  { value: "today", label: "Today" },
  { value: "last_week", label: "Last 7 Days" },
  { value: "last_month", label: "Last 30 Days" },
  { value: "custom", label: "Custom" },
];
