import React from "react";
import { FormControl, Input } from "@chakra-ui/react";

const AlphaInput = ({
  placeholder,
  value,
  onChange,
  name,
  isInvalid,
  regex,
  maxLength,
}) => {
  const handleChange = (event) => {
    const { value: inputValue } = event.target;

    if (regex && !regex.test(inputValue)) {
      return;
    }
    if (maxLength && inputValue.length > maxLength) {
      return;
    }

    onChange({ target: { name, value: inputValue } });
  };

  return (
    <FormControl isInvalid={isInvalid}>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        name={name}
        // variant={"filled"}
      />
    </FormControl>
  );
};

export default AlphaInput;
