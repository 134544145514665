import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Button,
  useDisclosure,
  Collapse,
  RadioGroup,
  Stack,
  Radio,
  Spacer,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../layout";
import SecondaryButton from "../../../components/SecondaryButton";
import PrimaryButton from "../../../components/PrimaryButton";
import PatientAttendantSection from "./PatientAttendantSection";
import FormInput from "../../../components/FormInput";
import FormSelector from "../../../components/FormSelector";
import { handleChange, handleClear } from "../form/utils";
import CollapseIcon from "../../../assets/images/collapse.svg";
import {
  fetchCountryRequest,
  fetchDoctorRequest,
  fetchEmbassyRequest,
  fetchHospitalsRequest,
  vilRequest,
  fetchTrDetailsRequest,
  fetchHealthConcernRequest,
  fetchTridsRequest,
  resetTrdetails,
} from "../hcfStore/actions";
import QRCodeModal from "../components/QRCodeModel";
import Alert from "../../../components/Alert";
import { useBlocker, useNavigate } from "react-router-dom";
import PrimaryHeading from "../../../components/PrimaryHeading";
import FooterCheckBox from "../components/FooterCheckbox";
import PrimaryFormLabel from "../../../components/PrimaryFormLabel";
import { Select } from "chakra-react-select";

const TravelRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUnsaveDialogOpen, setIsUnsaveDialogOpen] = useState(false);

  const { isOpen: isSectionOpen, onToggle: toggleSection } = useDisclosure({
    defaultIsOpen: true,
  });

  const defaultFormData = {
    doctor: "",
    specialty: "",
    hospital: "",
    dateOfAppointment: "",
    patientName: "",
    patientPassport: "",
    patientNationality: "",
    patientPassportNumber: "",
    patientEmbassy: "",
    attendant1Name: "",
    attendant1Passport: "",
    attendant1Nationality: "",
    attendant1PassportNumber: "",
    attendant1Embassy: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [attendantsCount, setAttendantsCount] = useState(1);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showConfirmError, setShowConfirmError] = useState("");

  const [healthConcernDetails, setHealthConcernDetails] = useState([]);
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [hospitalDetails, setHospitalDetails] = useState([]);
  const [selectionOrder, setSelectionOrder] = useState([]);

  const [isAutopopulateData, setIsAutopopulateData] = useState(false);
  const [selectTrid, setSelectTrid] = useState(false);
  const [selectedVIL, setSelectedVIL] = useState("1");
  const [isTrdetailsUpdated, setIsTrdetailsUpdated] = useState(false);
  const [initialPatientPassport, setInitialPatientPassport] = useState("");
  const [initialAttendantPassports, setInitialAttendantPassports] = useState(
    {}
  );

  const {
    hospitalData,
    doctorData,
    vilResponse,
    healthConcernData,
    hospitalMaster,
    healthConcernMaster,
    doctorMaster,
    trDetails,
    tridData,
    trid,
  } = useSelector((state) => state.hcf);

  // useEffect(() => {
  //   resetTrdetails();
  //   if (trid) {
  //     let data = { label: trid, value: trid };
  //     setSelectTrid(data);
  //     setSelectedVIL("2");
  //   } else {
  //     setSelectTrid(false);
  //     setSelectedVIL("1");
  //   }
  // }, []);

  useEffect(() => {
    let payload = { is_master: true };
    dispatch(fetchHospitalsRequest(payload));
    if (selectedVIL === "1") {
      dispatch(fetchHealthConcernRequest(payload));
      dispatch(fetchDoctorRequest(payload));
    } else {
      setIsTrdetailsUpdated(true);
    }
    dispatch(fetchEmbassyRequest());
    dispatch(fetchCountryRequest());
    payload = { request_type: "VIL" };
    dispatch(fetchTridsRequest(payload));
  }, []);

  useEffect(() => {
    // resetTrdetails();
    if (selectTrid) {
      let payload = { tr_id: selectTrid.value };
      if (isTrdetailsUpdated) {
        dispatch(fetchTrDetailsRequest(payload));
      } else {
        dispatch(fetchTrDetailsRequest(payload));
      }
    }
  }, [selectTrid]);

  useEffect(() => {
    if (trDetails && trDetails.length > 0) {
      if (!selectTrid) {
        let data = {
          label: trDetails[0].tr_id_label,
          value: trDetails[0].id,
        };
        setSelectTrid(data);
        setSelectedVIL("2");
      }

      setInitialPatientPassport(trDetails[0]?.patient_passport || "");
      setIsAutopopulateData(true);
      setSelectionOrder(["hospital", "specialty"]);

      let payload = { hospital: trDetails[0].hospital, is_master: false };
      dispatch(fetchHealthConcernRequest(payload));
      payload = {
        hospital: trDetails[0].hospital,
        health_concern: trDetails[0].health_concern.value,
        is_master: false,
      };
      dispatch(fetchDoctorRequest(payload));
      setFormData((prevFormData) => ({
        ...prevFormData,
        doctor: trDetails[0].requested_doctor
          ? {
              value: trDetails[0].requested_doctor,
              label: trDetails[0].requested_doctor,
            }
          : "",
        // specialty: trDetails[0]?.health_concern || "",
        specialty: trDetails[0]?.health_concern
          ? {
              value: trDetails[0].health_concern.value,
              label: trDetails[0].health_concern.label,
            }
          : "",
        hospital: trDetails[0]?.hospital
          ? {
              value: trDetails[0]?.hospital,
              label: trDetails[0]?.hospital,
            }
          : "",
        patientName: trDetails[0]?.patient_name || "",
        patientPassportNumber: trDetails[0]?.passport_no || "",
        patientNationality: trDetails[0].nationality
          ? {
              value: trDetails[0].nationality,
              label: trDetails[0].nationality,
            }
          : "",
        patientPassport: trDetails[0]?.patient_passport || "",
      }));

      if (
        trDetails[0].attendant_details &&
        trDetails[0].attendant_details.length > 0
      ) {
        setAttendantsCount(trDetails[0].attendant_details.length);
        trDetails[0].attendant_details.forEach((attendant, index) => {
          const key = `attendant${index + 1}Passport`;
          setInitialAttendantPassports((prevPassports) => ({
            ...prevPassports,
            [key]: attendant.attendant_passport || "",
          }));
          setFormData((prevFormData) => ({
            ...prevFormData,
            [`attendant${index + 1}Name`]: attendant.attendant_name
              ? attendant.attendant_name
              : "",
            [`attendant${index + 1}Passport`]: attendant.attendant_passport
              ? attendant.attendant_passport
              : "",
            [`attendant${index + 1}PassportNumber`]: attendant.passport_no
              ? attendant.passport_no
              : "",
          }));
        });
      }
    } else {
      setIsAutopopulateData(false);
    }
  }, [trDetails]);

  const handleRadioButtonChange = (value) => {
    setIsAutopopulateData(false);
    setSelectedVIL(value);
    setFormData(defaultFormData);
    setSelectTrid(false);
    setAttendantsCount(1);
    setFormErrors({});
  };

  useEffect(() => {
    setHealthConcernDetails(healthConcernMaster);
  }, [healthConcernMaster]);

  useEffect(() => {
    setHospitalDetails(hospitalMaster);
  }, [hospitalMaster]);

  useEffect(() => {
    setDoctorDetails(doctorMaster);
  }, [doctorMaster]);

  useEffect(() => {
    setHealthConcernDetails(healthConcernData);
  }, [healthConcernData]);

  useEffect(() => {
    setHospitalDetails(hospitalData);
  }, [hospitalData]);

  useEffect(() => {
    setDoctorDetails(doctorData);
  }, [doctorData]);

  useEffect(() => {
    if (vilResponse) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [vilResponse]);

  // useEffect(() => {
  //   if (formData.hospital && formData.hospital.value) {
  //     handleHospitalChange(formData.hospital);
  //   }
  // }, [formData.hospital]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const hasValues = Object.values(formData).some(
      (value) =>
        Boolean(value) &&
        (typeof value !== "object" || Object.keys(value).length > 0)
    );
    setHasUnsavedChanges(hasValues);
  }, [formData]);

  const shouldBlockNavigation = () => {
    if (hasUnsavedChanges) {
      setIsUnsaveDialogOpen(true);
      return "Are you sure you want to leave? You may lose unsaved changes.";
    }
    return null;
  };

  const blocker = useBlocker(shouldBlockNavigation);

  const addAttendant = () => {
    setAttendantsCount((prevCount) => prevCount + 1);
    const newIndex = attendantsCount + 1;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`attendant${newIndex}Name`]: "",
      [`attendant${newIndex}Passport`]: "",
      [`attendant${newIndex}PassportNumber`]: "",
      [`attendant${newIndex}Embassy`]: "",
      [`attendant${newIndex}Nationality`]: "",
    }));
  };

  const deleteAttendant = (index) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      // Remove fields for the attendant being deleted
      delete updatedFormData[`attendant${index + 1}Name`];
      delete updatedFormData[`attendant${index + 1}Passport`];
      delete updatedFormData[`attendant${index + 1}PassportNumber`];
      delete updatedFormData[`attendant${index + 1}Embassy`];
      delete updatedFormData[`attendant${index + 1}Nationality`];

      // Re-index the remaining attendants
      for (let i = index + 1; i < attendantsCount; i++) {
        updatedFormData[`attendant${i}Name`] =
          updatedFormData[`attendant${i + 1}Name`];
        updatedFormData[`attendant${i}Passport`] =
          updatedFormData[`attendant${i + 1}Passport`];
        updatedFormData[`attendant${i}PassportNumber`] =
          updatedFormData[`attendant${i + 1}PassportNumber`];
        updatedFormData[`attendant${i}Embassy`] =
          updatedFormData[`attendant${i + 1}Embassy`];
        updatedFormData[`attendant${i}Nationality`] =
          updatedFormData[`attendant${i + 1}Nationality`];
      }

      // Remove the last attendant (now duplicated)
      delete updatedFormData[`attendant${attendantsCount}Name`];
      delete updatedFormData[`attendant${attendantsCount}Passport`];
      delete updatedFormData[`attendant${attendantsCount}PassportNumber`];
      delete updatedFormData[`attendant${attendantsCount}Embassy`];
      delete updatedFormData[`attendant${attendantsCount}Nationality`];

      return updatedFormData;
    });
    setAttendantsCount((prevCount) => Math.max(prevCount - 1, 0));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let errors = validateForm(formData);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (!isConfirmed) {
        setShowConfirmError("Please check the checkbox to continue..!");
        return;
      }
      let duplicatePassportError = false;
      let attendantPassportNumbers = new Set();
      for (let i = 1; i <= attendantsCount; i++) {
        if (
          formData[`attendant${i}PassportNumber`] ===
          formData.patientPassportNumber
        ) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [`attendant${i}PassportNumber`]: "Attendant's passport number cannot be the same as patient's passport number",
          }));
          duplicatePassportError = true;
        }

        if (
          attendantPassportNumbers.has(formData[`attendant${i}PassportNumber`])
        ) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [`attendant${i}PassportNumber`]: "Duplicate passport number",
            [`attendant${Array.from(attendantPassportNumbers).findIndex(
              (num) => num === formData[`attendant${i}PassportNumber`]
            ) + 1}PassportNumber`]: "Duplicate passport number",
          }));
          duplicatePassportError = true;
        } else {
          attendantPassportNumbers.add(formData[`attendant${i}PassportNumber`]);
        }
      }

      if (duplicatePassportError) {
        setErrorMessage(true);
        return;
      }

      let payload = new FormData();
      payload.append("hospital", formData.hospital.value);
      payload.append("doctor", formData.doctor.value);
      payload.append("specialty", formData.specialty.value);
      payload.append("date_of_appointment", formData.dateOfAppointment);
      payload.append("patient_name", formData.patientName);
      // payload.append("passport", formData.patientPassport);
      payload.append("nationality", formData.patientNationality?.value);
      payload.append("passport_no", formData.patientPassportNumber);
      payload.append("embassy", formData.patientEmbassy?.value);

      if (isAutopopulateData) {
        payload.append("tr_id", selectTrid.value);
        if (formData.patientPassport !== initialPatientPassport) {
          payload.append("passport", formData.patientPassport);
        }
      } else {
        payload.append("passport", formData.patientPassport);
      }

      const attendants = [];
      for (let i = 1; i <= attendantsCount; i++) {
        const attendant = {
          name: formData[`attendant${i}Name`] || "",
          passport_no: formData[`attendant${i}PassportNumber`] || "",
          nationality: formData[`attendant${i}Nationality`]?.value || "",
          embassy: formData[`attendant${i}Embassy`]?.value || "",
        };
        attendants.push(attendant);
        // payload.append(
        //   `attendant${i}_passport`,
        //   formData[`attendant${i}Passport`]
        // );

        if (isAutopopulateData) {
          if (
            formData[`attendant${i}Passport`] !==
            initialAttendantPassports[`attendant${i}Passport`]
          ) {
            payload.append(
              `attendant${i}_passport`,
              formData[`attendant${i}Passport`]
            );
          }
        } else {
          payload.append(
            `attendant${i}_passport`,
            formData[`attendant${i}Passport`]
          );
        }
      }
      payload.append("attendants", JSON.stringify(attendants));
      dispatch(vilRequest(payload));
      setHasUnsavedChanges(false);
    } else {
      setErrorMessage(true);
    }
  };

  // const handleHospitalChange = (selectedOption) => {
  //   let payload = { hospital: selectedOption.value };
  //   dispatch(fetchDoctorRequest(payload));
  //   setFormData({ ...formData, hospital: selectedOption });
  // };
  // const handleDoctorChange = (selectedOption) => {
  //   let payload = {
  //     doctor_id: selectedOption.value,
  //   };
  //   dispatch(fetchDoctorSpecialtyRequest(payload));
  //   setFormData({ ...formData, doctor: selectedOption });
  // };

  const validateForm = (values) => {
    const errors = {};
    if (!values.specialty) {
      errors.specialty = "This field is required";
    }
    if (!values.doctor) {
      errors.doctor = "This field is required";
    }
    if (!values.hospital) {
      errors.hospital = "This field is required";
    }
    if (!values.dateOfAppointment) {
      errors.dateOfAppointment = "This field is required";
    }
    if (!values.patientName) {
      errors.patientName = "This field is required";
    } else if (values.patientName.length < 3) {
      errors.patientName = "Name must be at least 3 characters";
    }
    if (!values.patientPassport) {
      errors.patientPassport = "Please upload passport";
    }
    if (!values.patientNationality) {
      errors.patientNationality = "This field is required";
    }
    if (!values.patientPassportNumber) {
      errors.patientPassportNumber = "This field is required";
    }
    if (!values.patientEmbassy) {
      errors.patientEmbassy = "This field is required";
    }

    const passportNumbers = new Set();

    const checkPassportNumber = (field, fieldName) => {
      if (!values[field]) {
        errors[field] = "This field is required";
      } else if (passportNumbers.has(values[field])) {
        errors[field] = `${fieldName} passport number must be unique`;
      } else {
        passportNumbers.add(values[field]);
      }
    };

    checkPassportNumber("patientPassportNumber", "Patient");

    for (let i = 1; i <= attendantsCount; i++) {
      if (!values[`attendant${i}Name`]) {
        errors[`attendant${i}Name`] = "This field is required";
      } else if (values[`attendant${i}Name`].length < 3) {
        errors[`attendant${i}Name`] = "Name must be at least 3 characters";
      }
      if (!values[`attendant${i}Passport`]) {
        errors[`attendant${i}Passport`] = "Please upload passport";
      }
      if (!values[`attendant${i}PassportNumber`]) {
        errors[`attendant${i}PassportNumber`] = "This field is required";
      }
      if (!values[`attendant${i}Embassy`]) {
        errors[`attendant${i}Embassy`] = "This field is required";
      }
      if (!values[`attendant${i}Nationality`]) {
        errors[`attendant${i}Nationality`] = "This field is required";
      }
      checkPassportNumber(`attendant${i}PassportNumber`, `Attendant ${i}`);
    }
    return errors;
  };

  const handleSelectChange = (selectedOption, { name }) => {
    // Update formData
    const newFormData = { ...formData, [name]: selectedOption };
    setFormData(newFormData);
    setFormErrors({ ...formErrors, [name]: false });

    // Update selection order
    const newSelectionOrder = [...selectionOrder];
    if (!newSelectionOrder.includes(name)) {
      newSelectionOrder.push(name);
    }
    setSelectionOrder(newSelectionOrder);
    if (newSelectionOrder.length === 1 || newSelectionOrder[0] === name) {
      // Handle API calls based on the order of selections
      // First selection
      const firstSelection = newSelectionOrder[0];
      fetchDataForSecondAndThird(firstSelection, newFormData[firstSelection]);
    } else if (
      newSelectionOrder.length === 2 ||
      newSelectionOrder[1] === name
    ) {
      // Second selection
      const [firstSelection, secondSelection] = newSelectionOrder;
      fetchDataForThird(
        firstSelection,
        secondSelection,
        newFormData[firstSelection],
        newFormData[secondSelection]
      );
    } else if (newSelectionOrder.length === 3) {
      // No additional API calls required
    }
  };

  const fetchDataForSecondAndThird = (firstSelection, firstValue) => {
    try {
      if (firstSelection === "specialty") {
        let payload = { health_concern: firstValue.value, is_master: false };
        setFormData((prevData) => ({
          ...prevData,
          hospital: "",
          doctor: "",
        }));
        dispatch(fetchHospitalsRequest(payload));
        dispatch(fetchDoctorRequest(payload));
      } else if (firstSelection === "doctor") {
        let payload = { doctor_id: firstValue.value, is_master: false };
        setFormData((prevData) => ({
          ...prevData,
          specialty: "",
          hospital: "",
        }));
        dispatch(fetchHealthConcernRequest(payload));
        dispatch(fetchHospitalsRequest(payload));
      } else if (firstSelection === "hospital") {
        let payload = { hospital: firstValue.value, is_master: false };
        setFormData((prevData) => ({
          ...prevData,
          specialty: "",
          doctor: "",
        }));
        dispatch(fetchHealthConcernRequest(payload));
        dispatch(fetchDoctorRequest(payload));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataForThird = (
    firstSelection,
    secondSelection,
    firstValue,
    secondValue
  ) => {
    console.log("fetchDataForSecondAndThird");
    try {
      if (
        (firstSelection === "specialty" && secondSelection === "doctor") ||
        (firstSelection === "doctor" && secondSelection === "specialty")
      ) {
        let payload = {};
        setFormData((prevData) => ({
          ...prevData,
          hospital: "",
        }));
        if (firstSelection === "specialty" && secondSelection === "doctor") {
          payload = {
            health_concern: firstValue.value,
            hospital: secondValue.value,
            is_master: false,
          };
        } else {
          payload = {
            hospital: firstValue.value,
            health_concern: secondValue.value,
            is_master: false,
          };
        }

        dispatch(fetchHospitalsRequest(payload));
      } else if (
        (firstSelection === "specialty" && secondSelection === "hospital") ||
        (firstSelection === "hospital" && secondSelection === "specialty")
      ) {
        setFormData((prevData) => ({
          ...prevData,
          doctor: "",
        }));
        let payload = {};
        if (firstSelection === "specialty" && secondSelection === "hospital") {
          payload = {
            health_concern: firstValue.value,
            hospital: secondValue.value,
            is_master: false,
          };
        } else {
          payload = {
            hospital: firstValue.value,
            health_concern: secondValue.value,
            is_master: false,
          };
        }
        dispatch(fetchDoctorRequest(payload));
      } else if (
        (firstSelection === "doctor" && secondSelection === "hospital") ||
        (firstSelection === "hospital" && secondSelection === "doctor")
      ) {
        setFormData((prevData) => ({
          ...prevData,
          specialty: "",
        }));
        let payload = {};
        if (firstSelection === "doctor" && secondSelection === "hospital") {
          payload = {
            doctor: firstValue.value,
            hospital: secondValue.value,
            is_master: false,
          };
        } else {
          payload = {
            hospital: firstValue.value,
            doctor: secondValue.value,
            is_master: false,
          };
        }
        dispatch(fetchHealthConcernRequest(payload));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpdateMasterData = () => {
    setSelectionOrder([]);
    setHealthConcernDetails(healthConcernMaster);
    setHospitalDetails(hospitalMaster);
    setDoctorDetails(doctorMaster);
  };
  return (
    <Layout header="Travel Request" iconUrl="/h/home">
      <Flex alignItems="center" mt="20px">
        {/* <PrimaryHeading ml="10px">VIL</PrimaryHeading>
         <img
          src={CollapseIcon}
          width="24px"
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            transition: "transform 0.3s ease",
            transform: isSectionOpen ? "rotate(0)" : "rotate(180deg)",
          }}
          onClick={toggleSection}
        /> */}
        <RadioGroup
          defaultValue="1"
          mb="20px"
          ml="10px"
          onChange={handleRadioButtonChange}
          value={selectedVIL}
        >
          <Stack spacing={5} direction="row">
            <Radio colorScheme="blue" value="1">
              <PrimaryFormLabel mt="10px">New VIL</PrimaryFormLabel>
            </Radio>
            <Radio colorScheme="blue" value="2">
              <PrimaryFormLabel mt="10px">Existing VIL</PrimaryFormLabel>
            </Radio>
          </Stack>
        </RadioGroup>
      </Flex>
      {selectedVIL === "2" && (
        <Flex mb="20px" ml="10px">
          <Box
            display="flex"
            flexDirection="column"
            bgColor="white"
            p="20px"
            borderRadius="10px"
            mb="10px"
            // flex={{ base: "50px", md: "calc(50% - 10px)" }}
            flex={{ base: "100%", md: "calc(50% - 10px)" }}
            mr="10px"
          >
            <Box
              display="flex"
              flexDirection={{ base: "column", md: "column" }}
              justifyContent="space-between"
              mb="20px"
              width={{ base: "100%", md: "49%" }}
            >
              <PrimaryFormLabel>Select TR-ID</PrimaryFormLabel>
              <Select
                placeholder="Select TRID"
                id={"trid"}
                name={"trid"}
                menuPortalTarget={document.body}
                classNamePrefix="chakra-react-select"
                options={tridData}
                onChange={(value) => setSelectTrid(value)}
                value={selectTrid}
              />
            </Box>
          </Box>
        </Flex>
      )}
      <Box p=" 0 10px" mb="10px">
        <form>
          <Collapse in={isSectionOpen} animateOpacity>
            <Box
              display="flex"
              flexDirection="column"
              bgColor="white"
              p="20px"
              borderRadius="10px"
              mb="10px"
            >
              <Box
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                justifyContent="space-between"
                mb="20px"
              >
                <FormSelector
                  id="hospital"
                  label="Hospital"
                  options={hospitalDetails}
                  placeholder="Select Hospital"
                  value={formData.hospital}
                  // onChange={(selectedOption, actionMeta) => {
                  //   handleSelectChange(
                  //     formData,
                  //     setFormData,
                  //     formErrors,
                  //     setFormErrors,
                  //     selectedOption,
                  //     actionMeta
                  //   );
                  //   handleHospitalChange(selectedOption);
                  // }}
                  onChange={handleSelectChange}
                  isInvalid={!!formErrors.hospital}
                  errorMessage={formErrors.hospital}
                  isRequired={true}
                  flex={{ base: "100%", md: "calc(50% - 10px)" }}
                  mr={{ base: "0", md: "20px" }}
                />
                <FormSelector
                  id="doctor"
                  label="Doctor"
                  options={doctorDetails}
                  placeholder="Select Doctor"
                  value={formData.doctor}
                  // onChange={(selectedOption, actionMeta) => {
                  //   handleSelectChange(
                  //     formData,
                  //     setFormData,
                  //     formErrors,
                  //     setFormErrors,
                  //     selectedOption,
                  //     actionMeta
                  //   );
                  //   handleDoctorChange(selectedOption);
                  // }}
                  onChange={handleSelectChange}
                  isInvalid={!!formErrors.doctor}
                  errorMessage={formErrors.doctor}
                  isRequired={true}
                  flex={{ base: "100%", md: "calc(50% - 10px)" }}
                />
              </Box>

              <Box
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                justifyContent="space-between"
                mb="10px"
              >
                <FormSelector
                  id="specialty"
                  label="Health Concern"
                  options={healthConcernDetails}
                  placeholder="Select Health Concern"
                  value={formData.specialty}
                  // onChange={(selectedOption, actionMeta) =>
                  //   handleSelectChange(
                  //     formData,
                  //     setFormData,
                  //     formErrors,
                  //     setFormErrors,
                  //     selectedOption,
                  //     actionMeta
                  //   )
                  // }
                  onChange={handleSelectChange}
                  isInvalid={!!formErrors.specialty}
                  errorMessage={formErrors.specialty}
                  isRequired={true}
                  flex={{ base: "100%", md: "calc(50% - 10px)" }}
                  mr={{ base: "0", md: "20px" }}
                />

                <FormInput
                  id="dateOfAppointment"
                  label="Date Of Appointment"
                  type="date"
                  value={formData.birthDate}
                  onChange={(e) =>
                    handleChange(
                      formData,
                      setFormData,
                      formErrors,
                      setFormErrors,
                      e
                    )
                  }
                  isInvalid={!!formErrors.dateOfAppointment}
                  errorMessage={formErrors.dateOfAppointment}
                  isRequired={true}
                  flex={{ base: "100%", md: "calc(50% - 10px)" }}
                  name="avoidPastDate"
                />
              </Box>
              <Box width="100%" display="flex" justifyContent="flex-end">
                <Button
                  mt="20px"
                  colorScheme="red"
                  variant="ghost"
                  onClick={(e) => {
                    handleClear(setFormData);
                    handleUpdateMasterData();
                  }}
                >
                  Clear Selection
                </Button>
              </Box>
            </Box>
          </Collapse>

          <PatientAttendantSection
            label="Patient"
            name="Patient"
            formData={formData}
            setFormData={setFormData}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
          {Array.from({ length: attendantsCount }).map((_, index) => (
            <PatientAttendantSection
              key={index}
              index={index}
              name={`Attender ${index + 1}`}
              label={`Attendant ${index + 1}`}
              formData={formData}
              setFormData={setFormData}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              onDelete={deleteAttendant}
              isDeleteVisible={attendantsCount > 1}
            />
          ))}
          <Flex justifyContent={"right"} mt="4">
            <SecondaryButton onClick={addAttendant}>
              + Add Attender
            </SecondaryButton>
          </Flex>
          <Flex justifyContent={"right"} mt="4">
            <FooterCheckBox
              msg="Kindly confirm the details are entered correctly"
              isChecked={isConfirmed}
              setIsChecked={setIsConfirmed}
              checkboxError={showConfirmError}
            />
            <PrimaryButton type="submit" onClick={onSubmit}>
              Submit
            </PrimaryButton>
          </Flex>
        </form>
        <Alert
          isOpen={errorMessage}
          onClose={() => {
            setErrorMessage(false);
          }}
          body="Please fill all mandatory fields"
        />
        <Alert
          isOpen={isUnsaveDialogOpen}
          onClose={() => {
            setIsUnsaveDialogOpen(false);
          }}
          showCancelButton={true}
          title="Unsaved Changes!"
          body="Are you sure you want to leave? You may lose unsaved changes."
          type="info"
          callback={blocker.proceed}
        />

        <QRCodeModal
          isOpen={showAlert}
          onClose={() => {
            setShowAlert(false);
            navigate("/existing-patients");
          }}
          name={vilResponse?.patient_name}
          trid={vilResponse?.tr_id}
          requestType="Travel Request"
          qrCodeImageUrl={vilResponse?.qr_code_url}
          qrCodeImageContentUrl={vilResponse?.qr_code_image_content}
        />
      </Box>
    </Layout>
  );
};

export default TravelRequest;
