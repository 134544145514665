export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const RESET_SEARCH = "RESET_SEARCH";
export const SEARCH_SECTION_SUCCESS = "SEARCH_SECTION_SUCCESS";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";

export const searchRequest = (payload) => {
  return {
    type: SEARCH_REQUEST,
    payload: payload,
  };
};

export const searchSuccess = (payload) => {
  return {
    type: SEARCH_SUCCESS,
    payload,
  };
};

export const resetSearch = (payload) => {
  return {
    type: RESET_SEARCH,
    payload,
  };
};

export const searchSectionSuccess = (payload) => {
  return {
    type: SEARCH_SECTION_SUCCESS,
    payload,
  };
};

export const setSearchQuery = (payload) => {
  return {
    type: SET_SEARCH_QUERY,
    payload,
  };
};
