import {
  fetchAirportDetailsRequest,
  fetchHospitalsRequest,
  fetchPassportDetailsRequest,
} from "../hcfStore/actions";

const phoneLengths = {
  BD: { min: 10, max: 10 },
  KE: { min: 10, max: 10 },
  SO: { min: 7, max: 8 },
  IQ: { min: 10, max: 10 },
  ET: { min: 9, max: 9 },
  MU: { min: 8, max: 8 },
  NG: { min: 8, max: 8 },
  OM: { min: 7, max: 8 },
  TZ: { min: 6, max: 6 },
  MM: { min: 8, max: 10 },
  YE: { min: 9, max: 9 },
  UZ: { min: 9, max: 9 },
  NP: { min: 10, max: 10 },
  MZ: { min: 12, max: 12 },
  TM: { min: 8, max: 8 },
};

export const handleSelectChange = (
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  selectedOption,
  actionMeta,
  dispatch
) => {
  if (actionMeta.name === "healthConcern") {
    let payload = { health_concern: selectedOption.value };
    dispatch(fetchHospitalsRequest(payload));
  }
  if (
    actionMeta.name.includes("FromCountry") ||
    actionMeta.name.includes("ToCountry")
  ) {
    let payload = { country: selectedOption.value };
    dispatch(fetchAirportDetailsRequest(payload, actionMeta.name));
    if (actionMeta.name.includes("FromCountry")) {
      const { name } = actionMeta;
      const prefix = name.split(/FromCountry|ToCountry/)[0];
      formData[`${prefix}FromAirport`] = null; // Reset the airport value
      dispatch(fetchAirportDetailsRequest(payload, `${prefix}FromAirport`));
    } else {
      dispatch(fetchAirportDetailsRequest(payload, actionMeta.name));
    }
    // if (actionMeta.name === "patientFromCountry") {
    //   formData.fromAirport = null;
    // }
    // if (actionMeta.name === "patientToCountry") {
    //   formData.toAirport = null;
    // }
  }
  setFormData({ ...formData, [actionMeta.name]: selectedOption });
  setFormErrors({ ...formErrors, [actionMeta.name]: false });
};

export const handleChange = (
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  e
) => {
  const { name, value } = e.target;
  let updatedFormData = { ...formData, [name]: value };
  setFormData(updatedFormData);
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    [`${name}`]: false,
  }));
};

export const handlePassportFileChange = (
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  formattedLabel,
  dispatch,
  e
) => {
  // const { name, value } = e.target;
  const { name } = e.target;

  const file = e.target.files[0];
  // setFormData({ ...formData, passport: file });
  let updatedFormData = { ...formData, [name]: file };
  setFormData(updatedFormData);
  if (file) {
    const payload = new FormData();
    payload.append("file", file);
    dispatch(fetchPassportDetailsRequest(payload, formattedLabel));
  }
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    [`${formattedLabel}Passport`]: false,
  }));
};

export const handleFileChange = (
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  e
) => {
  const { name } = e.target;
  const file = e.target.files[0];
  let updatedFormData = { ...formData, [name]: file };
  setFormData(updatedFormData);
  if (file) {
    const payload = new FormData();
    payload.append("file", file);
  }
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    [name]: false,
  }));
};

export const handleViewFile = (file) => {
  if (typeof file === "string") {
    window.open(file, "_blank");
  } else if (file instanceof File) {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  } else {
    console.error("Unsupported file type or invalid file.");
  }
};

export const removeFile = (
  fileInputRefs,
  fieldName,
  setFormData,
  setFormErrors
) => {
  if (fileInputRefs.current[fieldName]) {
    fileInputRefs.current[fieldName].value = null;
  }
  setFormData((prevFormData) => {
    const updatedFormData = { ...prevFormData };
    delete updatedFormData[fieldName];
    return updatedFormData;
  });
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    [fieldName]: "Please upload passport",
  }));
};

export const handleMultiFileChange = (
  event,
  selectedFile,
  setSelectedFile,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  name
) => {
  const files = Array.from(event.target.files);

  // Maximum number of files allowed
  const maxFiles = 50;

  if (files.length + selectedFile.length > maxFiles) {
    setFormErrors({
      ...formErrors,
      [name]: `You can upload a maximum of ${maxFiles} files.`,
    });
    return;
  }

  const updatedFiles = [...selectedFile, ...files];
  setSelectedFile(updatedFiles);
  setFormData({ ...formData, [name]: updatedFiles });
  setFormErrors({ ...formErrors, [name]: false });
};

export const handleMultiRemoveFile = (
  index,
  selectedFile,
  setSelectedFile,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  name
) => {
  const updatedFiles = [...selectedFile];
  updatedFiles.splice(index, 1);
  setSelectedFile(updatedFiles);
  setFormData({ ...formData, [name]: updatedFiles });
  if (updatedFiles.length < 1) {
    setFormErrors({
      ...formErrors,
      [name]: "Please upload at least one File",
    });
    return;
  }
};

export const validatePhoneNumber = (
  value,
  country,
  dialCode,
  name,
  setFormErrors
) => {
  const countryCode = country?.toUpperCase();
  if (countryCode && phoneLengths[countryCode]) {
    const { min, max } = phoneLengths[countryCode];
    const length = value.replace(/\D/g, "").length;
    const min_length =
      min + (dialCode ? dialCode.replace(/\D/g, "").length : 0);
    const max_length =
      max + (dialCode ? dialCode.replace(/\D/g, "").length : 0);

    if (length > max_length) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: `Phone number length for ${name} should be maximum ${max} digits.`,
      }));
      return false;
    } else if (length < min_length) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: `Phone number length for ${name} should be minimum ${min} digits.`,
      }));
      return false;
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumber: "",
      }));
      return true;
    }
  }
  return true;
};

export const handleClear = (setFormData) => {
  setFormData((prevData) => ({
    ...prevData,
    healthConcern: null,
    preferredCountry: null,
    hospital: null,
    doctor: null,
    specialty: null,
  }));
};

export const formatDate = (date) => {
  let d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
