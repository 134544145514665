import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react";
import React from "react";
// import { Text } from "@chakra-ui/react";

const SuccessAlert = ({ message }) => {
  return (
    <Alert status="success">
      <AlertIcon />
      <AlertDescription>{message}</AlertDescription>
    </Alert>
    // <Text fontSize="lg" color="green">
    //   {message}
    // </Text>
  );
};

export default SuccessAlert;
