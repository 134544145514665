import {
  fetchHospitalsRequest,
  fetchPassportDetailsRequest,
} from "../hcfStore/actions";

export const handleSelectChange = (
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  selectedOption,
  actionMeta,
  dispatch
) => {
  if (actionMeta.name === "healthConcern") {
    let payload = { health_concern: selectedOption.value };
    dispatch(fetchHospitalsRequest(payload));
  }
  setFormData({ ...formData, [actionMeta.name]: selectedOption });
  setFormErrors({ ...formErrors, [actionMeta.name]: false });
};

export const handleChange = (
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  e
) => {
  const { name, value } = e.target;
  let updatedFormData = { ...formData, [name]: value };

  if (name === "dob" && value) {
    updatedFormData = {
      ...updatedFormData,
      age: calculateAge(value),
    };
  }

  setFormData(updatedFormData);
  setFormErrors({ ...formErrors, [name]: false });
};

export const handlePassportFileChange = (
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  dispatch,
  event
) => {
  const file = event.target.files[0];
  setFormData({ ...formData, passport: file });
  if (file) {
    const payload = new FormData();
    payload.append("file", file);
    dispatch(fetchPassportDetailsRequest(payload));
  }
  setFormErrors({ ...formErrors, passport: false });
};

export const calculateAge = (dob) => {
  //1975-02-05
  console.log("dob", dob);
  if (!dob) return "";
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age.toString();
};

export const formatMobileNumber = (phoneNumber, countryCode) => {
  if (!phoneNumber || !countryCode) {
    return "";
  }
  const phoneNumberWithoutCountryCode = phoneNumber.startsWith(countryCode)
    ? phoneNumber.slice(countryCode.length)
    : phoneNumber;
  const formattedNumber = `+${countryCode}-${phoneNumberWithoutCountryCode}`;

  return formattedNumber;
};
