import {
  FETCH_HOSPITALS_SUCCESS,
  FETCH_HOSPITALS_FAILURE,
  FETCH_COUNTRY_MASTER_SUCCESS,
  FETCH_COUNTRY_MASTER_FAILURE,
  FETCH_HEALTHCONCERN_SUCCESS,
  FETCH_HEALTHCONCERN_ERROR,
  FETCH_DOCTOR_SUCCESS,
  FETCH_DOCTOR_FAILURE,
  FETCH_DOCTOR_SPECIALTY_ERROR,
  FETCH_DOCTOR_SPECIALTY_SUCCESS,
  FETCH_EMBASSY_SUCCESS,
  FETCH_EMBASSY_ERROR,
  FETCH_HOSPITAL_SPECIALTY_SUCCESS,
  FETCH_HOSPITAL_SPECIALTY_FAILURE,
  FETCH_PASSPORT_DETAILS_ERROR,
  FETCH_PASSPORT_DETAILS_SUCCESS,
  MEDICALOPINION_SUCCESS,
  MEDICALOPINION_ERROR,
  VIL_SUCCESS,
  VIL_ERROR,
  FLIGHT_TICKET_SUCCESS,
  FLIGHT_TICKET_ERROR,
  DASHBOARD_SUCCESS,
  DASHBOARD_ERROR,
  FETCH_EXISTING_PATIENTS_SUCCESS,
  FETCH_EXISTING_PATIENTS_ERROR,
  FETCH_TR_DETAILS_SUCCESS,
  FETCH_TR_DETAILS_ERROR,
  FETCH_DASHBOARD_SUMMARY_SUCCESS,
  FETCH_DASHBOARD_SUMMARY_ERROR,
  FETCH_DASHBOARD_SUMMARY_DETAILS_SUCCESS,
  SET_DASHBOARD_CURRENT_STATE,
  FETCH_AIRLINES_DETAILS_SUCCESS,
  FETCH_AIRLINES_DETAILS_ERROR,
  FETCH_FROM_AIRPORT_DETAILS_SUCCESS,
  FETCH_TO_AIRPORT_DETAILS_SUCCESS,
  FETCH_AIRPORT_DETAILS_ERROR,
  FETCH_PREFERRED_COUNTRY_REQUEST,
  FETCH_PREFERRED_COUNTRY_SUCCESS,
  FETCH_PREFERRED_COUNTRY_MASTER_SUCCESS,
  FETCH_HOSPITALS_MASTER_SUCCESS,
  FETCH_HEALTHCONCERN_MASTER_SUCCESS,
  FETCH_DOCTOR_MASTER_SUCCESS,
  FETCH_DOCTOR_REQUEST,
  RESET_TR_DETAILS,
  FETCH_TRIDS_SUCCESS,
  FETCH_TRIDS_ERROR,
  SET_TR_ID,
  RESET_TR_ID,
  VERIFY_EXISTING_PATIENT_SUCCESS,
  VERIFY_EXISTING_PATIENT_ERROR,
} from "./actions";

const initialState = {
  loading: false,
  hospitalData: [],
  healthConcernData: [],
  specialtyData: [],
  doctorData: [],
  embassyData: [],
  countryData: [],
  preferredCountry: [],
  passportDetails: false,
  hospitalSpecialty: [],
  errorResponse: false,
  medicalOpinionResponse: false,
  existingPatientsResponse: false,
  trDetails: false,
  vilResponse: false,
  flightTicketResponse: false,
  dashboardSummary: [],
  dashboardSummaryDetails: [],
  getDashboardCardData: [],
  passportLabel: false,
  airlinesData: [],
  fromAirportData: {},
  toAirportData: [],
  airportCountry: false,
  preferredCountryData: [],
  preferredCountryMaster: [],
  hospitalMaster: [],
  healthConcernMaste: [],
  doctorMaster: [],
  tridData: [],
  trid: false,
  existingPatientResponse: false,
};

const hcfReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOSPITALS_SUCCESS:
      return {
        ...state,
        hospitalData: action.payload,
        errorResponse: false,
      };
    case FETCH_HOSPITALS_FAILURE:
      return {
        ...state,
        hospitalData: [],
        errorResponse: action.payload,
      };
    case FETCH_COUNTRY_MASTER_SUCCESS:
      return {
        ...state,
        preferredCountry: action.payload.preferred_countries,
        countryData: action.payload.data,
        errorResponse: false,
      };
    case FETCH_COUNTRY_MASTER_FAILURE:
      return {
        ...state,
        preferredCountry: [],
        countryData: [],
        errorResponse: action.payload,
      };
    case FETCH_HEALTHCONCERN_SUCCESS:
      return {
        ...state,
        healthConcernData: action.payload,
        errorResponse: false,
      };
    case FETCH_HEALTHCONCERN_ERROR:
      return {
        ...state,
        healthConcernData: [],
        errorResponse: action.payload,
      };
    case FETCH_DOCTOR_REQUEST:
      return {
        ...state,
        doctorData: [],
        errorResponse: false,
      };
    case FETCH_DOCTOR_SUCCESS:
      return {
        ...state,
        doctorData: action.payload,
        errorResponse: false,
      };
    case FETCH_DOCTOR_FAILURE:
      return {
        ...state,
        doctorData: [],
        errorResponse: action.payload,
      };
    case FETCH_DOCTOR_SPECIALTY_SUCCESS:
      return {
        ...state,
        specialtyData: action.payload,
        errorResponse: false,
      };
    case FETCH_DOCTOR_SPECIALTY_ERROR:
      return {
        ...state,
        specialtyData: [],
        errorResponse: action.payload,
      };
    case FETCH_EMBASSY_SUCCESS:
      return {
        ...state,
        embassyData: action.payload,
        errorResponse: false,
      };
    case FETCH_EMBASSY_ERROR:
      return {
        ...state,
        embassyData: [],
        errorResponse: action.payload,
      };
    case FETCH_HOSPITAL_SPECIALTY_SUCCESS:
      return {
        ...state,
        hospitalSpecialty: action.payload,
        errorResponse: false,
      };
    case FETCH_HOSPITAL_SPECIALTY_FAILURE:
      return {
        ...state,
        hospitalSpecialty: [],
        errorResponse: action.payload,
      };
    case FETCH_PASSPORT_DETAILS_SUCCESS:
      return {
        ...state,
        passportDetails: action.payload,
        passportLabel: action.passportLabel,
        errorResponse: false,
      };
    case FETCH_PASSPORT_DETAILS_ERROR:
      return {
        ...state,
        passportDetails: false,
        passportLabel: false,
        errorResponse: action.payload,
      };
    case MEDICALOPINION_SUCCESS:
      return {
        ...state,
        medicalOpinionResponse: action.payload,
        errorResponse: false,
      };
    case MEDICALOPINION_ERROR:
      return {
        ...state,
        errorResponse: action.payload,
      };
    case VIL_SUCCESS:
      return {
        ...state,
        vilResponse: action.payload,
        errorResponse: false,
      };
    case VIL_ERROR:
      return {
        ...state,
        vilResponse: false,
        errorResponse: action.payload,
      };
    case FLIGHT_TICKET_SUCCESS:
      return {
        ...state,
        flightTicketResponse: action.payload,
        errorResponse: false,
      };
    case FLIGHT_TICKET_ERROR:
      return {
        ...state,
        flightTicketResponse: false,
        errorResponse: action.payload,
      };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        errorResponse: false,
      };
    case DASHBOARD_ERROR:
      return {
        ...state,
        errorResponse: action.payload,
      };
    case FETCH_EXISTING_PATIENTS_SUCCESS:
      return {
        ...state,
        existingPatientsResponse: action.payload,
        errorResponse: false,
      };
    case FETCH_EXISTING_PATIENTS_ERROR:
      return {
        ...state,
        existingPatientsResponse: false,
        errorResponse: action.payload,
      };
    case FETCH_TR_DETAILS_SUCCESS:
      return {
        ...state,
        trDetails: action.payload,
        errorResponse: false,
      };
    case FETCH_TR_DETAILS_ERROR:
      return {
        ...state,
        trDetails: false,
        errorResponse: action.payload,
      };
    case RESET_TR_DETAILS:
      return {
        ...state,
        trDetails: false,
        errorResponse: false,
      };
    case FETCH_DASHBOARD_SUMMARY_SUCCESS:
      return {
        ...state,
        dashboardSummary: action.payload,
        errorResponse: false,
      };
    case FETCH_DASHBOARD_SUMMARY_DETAILS_SUCCESS:
      return {
        ...state,
        dashboardSummaryDetails: action.payload,
        errorResponse: false,
      };
    case FETCH_DASHBOARD_SUMMARY_ERROR:
      return {
        ...state,
        dashboardSummary: false,
        errorResponse: action.payload,
      };
    case SET_DASHBOARD_CURRENT_STATE:
      return {
        ...state,
        getDashboardCardData: action.payload,
      };
    case FETCH_AIRLINES_DETAILS_SUCCESS:
      return {
        ...state,
        airlinesData: action.payload,
        errorResponse: false,
      };
    case FETCH_AIRLINES_DETAILS_ERROR:
      return {
        ...state,
        airlinesData: [],
        errorResponse: action.payload,
      };
    case FETCH_FROM_AIRPORT_DETAILS_SUCCESS:
      return {
        ...state,
        fromAirportData: {
          ...state.fromAirportData,
          [action.label]: action.payload,
        },
        errorResponse: false,
      };
    case FETCH_TO_AIRPORT_DETAILS_SUCCESS:
      return {
        ...state,
        toAirportData: action.payload,
        errorResponse: false,
      };
    case FETCH_AIRPORT_DETAILS_ERROR:
      return {
        ...state,
        airportData: [],
        errorResponse: action.payload,
      };
    case FETCH_PREFERRED_COUNTRY_REQUEST:
      return {
        ...state,
        preferredCountryData: [],
        errorResponse: false,
      };
    case FETCH_PREFERRED_COUNTRY_SUCCESS:
      return {
        ...state,
        preferredCountryData: action.payload,
        errorResponse: false,
      };
    case FETCH_PREFERRED_COUNTRY_MASTER_SUCCESS:
      return {
        ...state,
        preferredCountryMaster: action.payload,
        errorResponse: [],
      };
    case FETCH_HOSPITALS_MASTER_SUCCESS:
      return {
        ...state,
        hospitalMaster: action.payload,
        errorResponse: [],
      };
    case FETCH_HEALTHCONCERN_MASTER_SUCCESS:
      return {
        ...state,
        healthConcernMaster: action.payload,
        errorResponse: [],
      };
    case FETCH_DOCTOR_MASTER_SUCCESS:
      return {
        ...state,
        doctorMaster: action.payload,
        errorResponse: [],
      };
    case SET_TR_ID:
      return {
        ...state,
        trid: action.payload,
      };
    case RESET_TR_ID:
      return {
        ...state,
        trid: false,
      };
    case FETCH_TRIDS_SUCCESS:
      return {
        ...state,
        tridData: action.payload,
        errorResponse: false,
      };
    case FETCH_TRIDS_ERROR:
      return {
        ...state,
        tridData: false,
        errorResponse: action.payload,
      };
    case VERIFY_EXISTING_PATIENT_SUCCESS:
      return {
        ...state,
        existingPatientResponse: action.payload,
        errorResponse: false,
      };
    case VERIFY_EXISTING_PATIENT_ERROR:
      return {
        ...state,
        existingPatientResponse: false,
        errorResponse: action.payload,
      };
    default:
      return state;
  }
};

export default hcfReducer;
