import React from "react";
import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Select as ChakraSelect } from "chakra-react-select";

import PrimaryFormLabel from "./PrimaryFormLabel";

const FormSelector = ({
  id,
  label,
  options,
  placeholder,
  value,
  onChange,
  isInvalid,
  errorMessage,
  isRequired,
  flex,
  mr,
}) => {
  return (
    <FormControl
      isInvalid={isInvalid}
      flex={flex}
      mr={mr}
      isRequired={isRequired}
    >
      <PrimaryFormLabel htmlFor={id}>{label}</PrimaryFormLabel>
      <ChakraSelect
        id={id}
        name={id}
        placeholder={placeholder}
        options={options}
        menuPortalTarget={document.body}
        classNamePrefix="chakra-react-select"
        value={value}
        onChange={onChange}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default FormSelector;
