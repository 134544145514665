// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-qwjbfp,
.chakra-slide {
    width: 75% !important;
}

.css-ax5erp {
    width: 100% !important;
    max-width: -webkit-fill-available !important;
}

.active div {
    background-color: #2B67AF !important;
    color: white
}

button[type="submit"] {
    background-color: #2B67AF !important;
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/layout/Layout.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;IACtB,4CAA4C;AAChD;;AAEA;IACI,oCAAoC;IACpC;AACJ;;AAEA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B","sourcesContent":[".css-qwjbfp,\n.chakra-slide {\n    width: 75% !important;\n}\n\n.css-ax5erp {\n    width: 100% !important;\n    max-width: -webkit-fill-available !important;\n}\n\n.active div {\n    background-color: #2B67AF !important;\n    color: white\n}\n\nbutton[type=\"submit\"] {\n    background-color: #2B67AF !important;\n    color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
