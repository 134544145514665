import { Checkbox, FormControl, FormErrorMessage } from "@chakra-ui/react";
import React from "react";
import PrimaryFormLabel from "../../../components/PrimaryFormLabel";

const FooterCheckBox = ({ msg, isChecked, setIsChecked, checkboxError }) => {
  return (
    <FormControl isInvalid={!!checkboxError}>
      <Checkbox
        color={"primary.1"}
        size="lg"
        borderColor={"primary.1"}
        isChecked={isChecked}
        onChange={(e) => setIsChecked(e.target.checked)}
      >
        <PrimaryFormLabel mt="8px">{msg}</PrimaryFormLabel>
      </Checkbox>
      <FormErrorMessage>{checkboxError}</FormErrorMessage>
    </FormControl>
  );
};

export default FooterCheckBox;
