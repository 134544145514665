import { Button } from "@chakra-ui/react";
import React from "react";

const PrimaryButton = (props) => {
  return (
    <Button
      bg="primary.1"
      color="white"
      _hover={{ bg: "primary.3" }}
      _active={{ bg: "blue.700" }}
      {...props}
    />
  );
};

export default PrimaryButton;
