import React from "react";
import {
  Box,
  Checkbox,
  Flex,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import Layout from "../../../layout";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import PatientAttendantForm from "./PatientAttendantForm";
import PrimaryButton from "../../../components/PrimaryButton";
import SecondaryButton from "../../../components/SecondaryButton";
import {
  fetchAirlinesDetailsRequest,
  fetchCountryRequest,
  flightTicketsRequest,
  fetchHealthConcernRequest,
  fetchHospitalsRequest,
  fetchPreferredCountryRequest,
  fetchTridsRequest,
  fetchTrDetailsRequest,
  verifyExistingPatientRequest,
} from "../hcfStore/actions";
import { useSelector } from "react-redux";
import QRCodeModal from "../components/QRCodeModel";
import Alert from "../../../components/Alert";
import { useBlocker, useNavigate } from "react-router-dom";
import PrimaryHeading from "../../../components/PrimaryHeading";
import PrimaryFormLabel from "../../../components/PrimaryFormLabel";
import HealthConcernSection from "../medicalOpinion/HealthConcernSection";
import FooterCheckBox from "../components/FooterCheckbox";
import { Select } from "chakra-react-select";
import { FlightTicketFreezeFields } from "../constants";
import PatientDetailsModal from "../components/PatientDetailsModal";
// import { handleSelectChange } from "../form/utils";

const FlightTickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultFormData = {
    patientName: "",
    patientPassport: "",
    patientCountryOfRes: "",
    patientPassportNumber: "",
    patientDob: "",
    attendant1Name: "",
    attendant1Passport: "",
    attendant1PassportNumber: "",
    attendant1FlightTickets: "",
    attendant1AirlineDetails: "",
    attendant1FlightNumber: "",
    attendant1ArrivalDate: "",
    attendant1ArrivalTime: "",
    attendant1FromCountry: "",
    // attendant1ToCountry: "",
    attendant1FromAirport: "",
    attendant1ToAirport: "",
    attendant1SameFlightticketAsPatient: true,
    patientFlightTickets: "",
    patientAirlineDetails: "",
    patientFlightNumber: "",
    patientArrivalDate: "",
    patientArrivalTime: "",
    patientFromCountry: "",
    patientToCountry: "",
    patientFromAirport: "",
    patientToAirport: "",
    airportPickup: "",
    healthConcern: "",
    hospital: "",
    preferredCountry: "",
    patientNationality: "",
  };

  const [autoPopulatedFields, setAutoPopulatedFields] = useState({
    FlightTicketFreezeFields,
  });

  const [formData, setFormData] = useState(defaultFormData);

  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [attendantsCount, setAttendantsCount] = useState(1);
  const [airportPickupChecked, setAirportPickupChecked] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isUnsaveDialogOpen, setIsUnsaveDialogOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showConfirmError, setShowConfirmError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [existingPatientAlert, setExistingPatientAlert] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [isAutopopulateData, setIsAutopopulateData] = useState(false);
  const [selectTrid, setSelectTrid] = useState(false);
  const [selectedFlightTicket, setSelectedFlightTicket] = useState("1");
  const [isTrdetailsUpdated, setIsTrdetailsUpdated] = useState(false);
  const [initialPatientPassport, setInitialPatientPassport] = useState("");
  const [initialAttendantPassports, setInitialAttendantPassports] = useState(
    {}
  );
  const {
    flightTicketResponse,
    tridData,
    trDetails,
    trid,
    verifyExistingPatientResponse,
  } = useSelector((state) => state.hcf);

  useEffect(() => {
    let payload = {};
    dispatch(fetchCountryRequest());
    dispatch(fetchAirlinesDetailsRequest(payload));
    payload = { is_master: true };
    if (selectedFlightTicket === "1") {
      dispatch(fetchHospitalsRequest(payload));
      dispatch(fetchPreferredCountryRequest(payload));
    }
    dispatch(fetchHealthConcernRequest(payload));

    if (selectedFlightTicket === "2") {
      setIsTrdetailsUpdated(true);
    }

    payload = { request_type: "FT" };
    dispatch(fetchTridsRequest(payload));
  }, []);

  useEffect(() => {
    // resetTrdetails();
    if (selectTrid) {
      setFormData(defaultFormData);
      setAttendantsCount(1);
      setFormErrors({});
      let payload = { tr_id: selectTrid.value };
      if (isTrdetailsUpdated) {
        dispatch(fetchTrDetailsRequest(payload));
      } else {
        dispatch(fetchTrDetailsRequest(payload));
      }
    }
  }, [selectTrid]);

  useEffect(() => {
    if (flightTicketResponse) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [flightTicketResponse]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const hasValues = Object.values(formData).some(
      (value) =>
        Boolean(value) &&
        (typeof value !== "object" || Object.keys(value).length > 0)
    );
    setHasUnsavedChanges(hasValues);
  }, [formData]);

  useEffect(() => {
    if (!isAutopopulateData) {
      if (formData.patientPassportNumber && formData.hospital) {
        if (formData.patientPassportNumber.length > 6) {
          handlePatientCheck(
            formData.hospital.value,
            formData.patientPassportNumber
          );
        }
      }
    }
  }, [formData.patientPassportNumber, formData.hospital]);

  useEffect(() => {
    if (trDetails && trDetails.length > 0 && !isModalOpen) {
      if (!selectTrid) {
        let data = {
          label: trDetails[0].tr_id_label,
          value: trDetails[0].id,
        };

        setSelectTrid(data);
        setSelectedFlightTicket("2");
      }

      setInitialPatientPassport(trDetails[0].patient_passport || "");
      setSelectedOrder(["healthConcern", "hospital"]);
      setIsAutopopulateData(true);

      let payload = {
        health_concern: trDetails[0].health_concern.value,
        is_master: false,
      };
      dispatch(fetchHospitalsRequest(payload));

      payload = {
        health_concern: trDetails[0].health_concern.value,
        hospital: trDetails[0].hospital,
        is_master: false,
      };
      dispatch(fetchPreferredCountryRequest(payload));

      setFormData((prevFormData) => ({
        ...prevFormData,
        healthConcern: trDetails[0]?.health_concern || "",
        preferredCountry: trDetails[0].preferred_country
          ? {
              value: trDetails[0].preferred_country,
              label: trDetails[0].preferred_country,
            }
          : "",
        hospital: trDetails[0].hospital
          ? {
              value: trDetails[0].hospital,
              label: trDetails[0].hospital,
            }
          : "",
        patientPassport: trDetails[0].patient_passport || "",
        patientName: trDetails[0].patient_name || "",
        patientPassportNumber: trDetails[0].passport_no || "",
        patientDob: trDetails[0].dob || "",
        patientNationality: trDetails[0].nationality
          ? {
              value: trDetails[0].nationality,
              label: trDetails[0].nationality,
            }
          : "",
        patientCountryOfRes: trDetails[0].country_of_residency
          ? {
              value: trDetails[0].country_of_residency,
              label: trDetails[0].country_of_residency,
            }
          : "",
      }));

      // Initialize autopopulated fields
      if (trDetails[0].vil_status !== "Not Requested") {
        const initialAutoPopulatedFields = {
          patientName: !!trDetails[0].patient_name,
          patientPassport: !!trDetails[0].patient_passport,
          patientPassportNumber: !!trDetails[0].passport_no,
          patientDob: !!trDetails[0].dob,
          patientCountryOfRes: !!trDetails[0].country_of_residency,
          patientNationality: !!trDetails[0].nationality,
        };

        // Add autopopulated fields for attendants
        trDetails[0].attendant_details?.forEach((attendant, index) => {
          initialAutoPopulatedFields[
            `attendant${index + 1}Name`
          ] = !!attendant.attendant_name;
          initialAutoPopulatedFields[
            `attendant${index + 1}Passport`
          ] = !!attendant.passport_no;
          initialAutoPopulatedFields[
            `attendant${index + 1}PassportNumber`
          ] = !!attendant.passport_no;
        });

        setAutoPopulatedFields(initialAutoPopulatedFields);
      } else {
        setAutoPopulatedFields(FlightTicketFreezeFields);
      }

      if (
        trDetails[0].attendant_details &&
        trDetails[0].attendant_details.length > 0
      ) {
        setAttendantsCount(trDetails[0].attendant_details.length);
        trDetails[0].attendant_details.forEach((attendant, index) => {
          const key = `attendant${index + 1}Passport`;
          setInitialAttendantPassports((prevPassports) => ({
            ...prevPassports,
            [key]: attendant.attendant_passport || "",
          }));

          setFormData((prevFormData) => ({
            ...prevFormData,
            [`attendant${index + 1}Name`]: attendant.attendant_name
              ? attendant.attendant_name
              : "",
            [`attendant${index + 1}Passport`]: attendant.attendant_passport
              ? attendant.attendant_passport
              : "",
            [`attendant${index + 1}PassportNumber`]: attendant.passport_no
              ? attendant.passport_no
              : "",
            [`attendant${index + 1}Nationality`]: attendant.nationality
              ? {
                  value: attendant.nationality,
                  label: attendant.nationality,
                }
              : "",
            [`attendant${index + 1}Embassy`]: attendant.embassy
              ? {
                  value: attendant.embassy,
                  label: attendant.embassy,
                }
              : "",
          }));
        });
      }
    } else {
      setIsAutopopulateData(false);
      setSelectedOrder([]);
    }
  }, [trDetails]);

  useEffect(() => {
    if (verifyExistingPatientResponse) {
      setExistingPatientAlert(true);
    } else {
      setExistingPatientAlert(false);
    }
  }, [verifyExistingPatientResponse]);

  const handlePatientCheck = (hospital, passportNumber) => {
    const payload = {
      hospital: hospital,
      passport_no: passportNumber,
      request_type: "FT",
    };

    dispatch(verifyExistingPatientRequest(payload));
  };

  const handlePatientCard = (id) => {
    let payload = {
      tr_id: verifyExistingPatientResponse.data.value,
    };
    if (verifyExistingPatientResponse.data.is_existing_rec === "1") {
      setIsModalOpen(true);
    } else {
      let data = {
        label: verifyExistingPatientResponse.data.label,
        value: verifyExistingPatientResponse.data.value,
      };
      setSelectTrid(data);
      setSelectedFlightTicket("2");
      setIsModalOpen(false);
    }
    dispatch(fetchTrDetailsRequest(payload));
  };

  const handleRadioButtonChange = (value) => {
    setSelectedFlightTicket(value);
    if (value === "1") {
      setSelectedOrder([]);
    } else {
      setSelectedOrder(["healthConcern", "hospital"]);
    }
    setFormData(defaultFormData);
    setSelectTrid(false);
    setIsAutopopulateData(false);
    setAttendantsCount(1);
    setFormErrors({});
  };

  const shouldBlockNavigation = () => {
    if (hasUnsavedChanges) {
      setIsUnsaveDialogOpen(true);
      return "Are you sure you want to leave? You may lose unsaved changes.";
    }
    return null;
  };

  const blocker = useBlocker(shouldBlockNavigation);

  const addAttendant = () => {
    setAttendantsCount((prevCount) => prevCount + 1);
    const newIndex = attendantsCount + 1;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`attendant${newIndex}Name`]: "",
      [`attendant${newIndex}Passport`]: "",
      [`attendant${newIndex}PassportNumber`]: "",
      [`attendant${newIndex}SameFlightticketAsPatient`]: "",
      [`attendant${newIndex}FlightTickets`]: "",
      [`attendant${newIndex}AirlineDetails`]: "",
      [`attendant${newIndex}FlightNumber`]: "",
      [`attendant${newIndex}ArrivalDate`]: "",
      [`attendant${newIndex}ArrivalTime`]: "",
      [`attendant${newIndex}FromCountry`]: "",
      // [`attendant${newIndex}ToCountry`]: "",
      [`attendant${newIndex}FromAirport`]: "",
      [`attendant${newIndex}ToAirport`]: "",
    }));
  };

  const deleteAttendant = (index) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      // Remove fields for the attendant being deleted
      delete updatedFormData[`attendant${index + 1}Name`];
      delete updatedFormData[`attendant${index + 1}Passport`];
      delete updatedFormData[`attendant${index + 1}PassportNumber`];
      delete updatedFormData[`attendant${index + 1}SameFlightticketAsPatient`];
      delete updatedFormData[`attendant${index + 1}FlightTickets`];
      delete updatedFormData[`attendant${index + 1}AirlineDetails`];
      delete updatedFormData[`attendant${index + 1}FlightNumber`];
      delete updatedFormData[`attendant${index + 1}ArrivalDate`];
      delete updatedFormData[`attendant${index + 1}ArrivalTime`];
      delete updatedFormData[`attendant${index + 1}FromCountry`];
      // delete updatedFormData[`attendant${index + 1}ToCountry`];
      delete updatedFormData[`attendant${index + 1}FromAirport`];
      delete updatedFormData[`attendant${index + 1}ToAirport`];

      // Re-index the remaining attendants
      for (let i = index + 1; i < attendantsCount; i++) {
        updatedFormData[`attendant${i}Name`] =
          updatedFormData[`attendant${i + 1}Name`];
        updatedFormData[`attendant${i}Passport`] =
          updatedFormData[`attendant${i + 1}Passport`];
        updatedFormData[`attendant${i}PassportNumber`] =
          updatedFormData[`attendant${i + 1}PassportNumber`];
        updatedFormData[`attendant${i}SameFlightticketAsPatient`] =
          updatedFormData[`attendant${i + 1}SameFlightticketAsPatient`];
        updatedFormData[`attendant${i}FlightTickets`] =
          updatedFormData[`attendant${i + 1}FlightTickets`];
        updatedFormData[`attendant${i}AirlineDetails`] =
          updatedFormData[`attendant${i + 1}AirlineDetails`];
        updatedFormData[`attendant${i}FlightNumber`] =
          updatedFormData[`attendant${i + 1}FlightNumber`];
        updatedFormData[`attendant${i}ArrivalDate`] =
          updatedFormData[`attendant${i + 1}ArrivalDate`];
        updatedFormData[`attendant${i}ArrivalTime`] =
          updatedFormData[`attendant${i + 1}ArrivalTime`];
        updatedFormData[`attendant${i}FromCountry`] =
          updatedFormData[`attendant${i + 1}FromCountry`];
        updatedFormData[`attendant${i}ToCountry`] =
          //   updatedFormData[`attendant${i + 1}ToCountry`];
          updatedFormData[`attendant${i}FromAirport`] =
            updatedFormData[`attendant${i + 1}FromAirport`];
        updatedFormData[`attendant${i}ToAirport`] =
          updatedFormData[`attendant${i + 1}ToAirport`];
      }

      // Remove the last attendant (now duplicated)
      delete updatedFormData[`attendant${attendantsCount}Name`];
      delete updatedFormData[`attendant${attendantsCount}Passport`];
      delete updatedFormData[`attendant${attendantsCount}PassportNumber`];
      delete updatedFormData[
        `attendant${attendantsCount}SameFlightticketAsPatient`
      ];
      delete updatedFormData[`attendant${attendantsCount}FlightTickets`];
      delete updatedFormData[`attendant${attendantsCount}AirlineDetails`];
      delete updatedFormData[`attendant${attendantsCount}FlightNumber`];
      delete updatedFormData[`attendant${attendantsCount}ArrivalDate`];
      delete updatedFormData[`attendant${attendantsCount}ArrivalTime`];
      delete updatedFormData[`attendant${attendantsCount}FromCountry`];
      // delete updatedFormData[`attendant${attendantsCount}ToCountry`];
      delete updatedFormData[`attendant${attendantsCount}FromAirport`];
      delete updatedFormData[`attendant${attendantsCount}ToAirport`];
      return updatedFormData;
    });
    setAttendantsCount((prevCount) => Math.max(prevCount - 1, 0));
  };
  const handleCheckboxChange = (event) => {
    setAirportPickupChecked(event.target.checked);
    setFormData((prevFormData) => ({
      ...prevFormData,
      airportPickup: event.target.checked,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let errors = validateForm(formData);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (!isConfirmed) {
        setShowConfirmError("Please check the checkbox to continue..!");
        return;
      }
      let duplicatePassportError = false;
      let attendantPassportNumbers = new Set();
      for (let i = 1; i <= attendantsCount; i++) {
        if (
          formData[`attendant${i}PassportNumber`] ===
          formData.patientPassportNumber
        ) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [`attendant${i}PassportNumber`]: "Attendant's passport number cannot be the same as patient's passport number",
          }));
          duplicatePassportError = true;
        }

        if (
          attendantPassportNumbers.has(formData[`attendant${i}PassportNumber`])
        ) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [`attendant${i}PassportNumber`]: "Duplicate passport number",
            [`attendant${Array.from(attendantPassportNumbers).findIndex(
              (num) => num === formData[`attendant${i}PassportNumber`]
            ) + 1}PassportNumber`]: "Duplicate passport number",
          }));
          duplicatePassportError = true;
        } else {
          attendantPassportNumbers.add(formData[`attendant${i}PassportNumber`]);
        }
      }

      if (duplicatePassportError) {
        setErrorMessage(true);
        return;
      }

      let payload = new FormData();
      payload.append("patient_name", formData.patientName);
      // payload.append("passport", formData.patientPassport);
      payload.append("country_of_res", formData.patientCountryOfRes?.value);
      payload.append("passport_no", formData.patientPassportNumber);
      payload.append("dob", formData.patientDob);
      // payload.append("flight_ticket", formData.flightTickets);
      payload.append("airline_details", formData.patientAirlineDetails?.value);
      payload.append("flight_number", formData.patientFlightNumber);
      payload.append("arrival_date", formData.patientArrivalDate);
      payload.append("arrival_time", formData.patientArrivalTime);
      payload.append("from_country", formData.patientFromCountry?.value);
      payload.append("to_country", formData.patientToCountry?.value);
      payload.append("from_airport", formData.patientFromAirport?.value);
      payload.append("to_airport", formData.patientToAirport?.value);
      payload.append("airport_pickup", formData.airportPickup ? 1 : 0);
      payload.append("nationality", formData.patientNationality?.value);
      payload.append("health_concern", formData.healthConcern?.value);
      payload.append("hospital", formData.hospital?.value);
      payload.append("preferred_country", formData.preferredCountry?.value);

      if (isAutopopulateData) {
        payload.append("tr_id", selectTrid.value);
        if (formData.patientPassport !== initialPatientPassport) {
          payload.append("passport", formData.patientPassport);
        }
      } else {
        payload.append("passport", formData.patientPassport);
      }

      if (
        formData.patientFlightTickets &&
        formData.patientFlightTickets.length > 0
      ) {
        formData.patientFlightTickets.forEach((flightTickets) => {
          payload.append("flight_ticket", flightTickets);
        });
      }

      const attendants = [];
      for (let i = 1; i <= attendantsCount; i++) {
        const attendant = {
          name: formData[`attendant${i}Name`] || "",
          passport_no: formData[`attendant${i}PassportNumber`] || "",
          travelling_on_same_flight_as_patient: formData[
            `attendant${i}SameFlightticketAsPatient`
          ]
            ? 1
            : 0,
        };
        if (!formData[`attendant${i}SameFlightticketAsPatient`]) {
          attendant["airline_details"] =
            formData[`attendant${i}AirlineDetails`]?.value || false;
          attendant["flight_number"] =
            formData[`attendant${i}FlightNumber`] || false;
          attendant["arrival_date"] =
            formData[`attendant${i}ArrivalDate`] || false;
          attendant["arrival_time"] =
            formData[`attendant${i}ArrivalTime`] || false;
          attendant["from_country"] =
            formData[`attendant${i}FromCountry`]?.value || false;
          //attendant["to_country"] = formData[`attendant${i}ToCountry`]?.value || false
          attendant["from_airport"] =
            formData[`attendant${i}FromAirport`]?.value || false;
          attendant["to_airport"] =
            formData[`attendant${i}ToAirport`]?.value || false;
        }
        if (
          formData[`attendant${i}FlightTickets`] &&
          formData[`attendant${i}FlightTickets`].length > 0
        ) {
          formData[`attendant${i}FlightTickets`].forEach((flightTickets) => {
            payload.append(`attendant${i}_flight_ticket`, flightTickets);
          });
        }
        attendants.push(attendant);
        // payload.append(
        //   `attendant${i}_passport`,
        //   formData[`attendant${i}Passport`]
        // );
        if (isAutopopulateData) {
          if (
            formData[`attendant${i}Passport`] !==
            initialAttendantPassports[`attendant${i}Passport`]
          ) {
            payload.append(
              `attendant${i}_passport`,
              formData[`attendant${i}Passport`]
            );
          }
        } else {
          payload.append(
            `attendant${i}_passport`,
            formData[`attendant${i}Passport`]
          );
        }
      }
      payload.append("attendants", JSON.stringify(attendants));
      setHasUnsavedChanges(false);
      dispatch(flightTicketsRequest(payload));
    } else {
      setErrorMessage(true);
    }
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.patientName) {
      errors.patientName = "This field is required";
    } else if (values.patientName.length < 3) {
      errors.patientName = "Name must be at least 3 characters";
    }
    if (!values.patientPassport) {
      errors.patientPassport = "Please upload passport";
    }
    if (!values.patientCountryOfRes) {
      errors.patientCountryOfRes = "This field is required";
    }
    if (!values.patientPassportNumber) {
      errors.patientPassportNumber = "This field is required";
    }
    if (!values.patientDob) {
      errors.patientDob = "This field is required";
    } else if (values.patientDob) {
      const selectedDate = new Date(values.patientDob);
      const today = new Date();
      if (selectedDate > today.setHours(0, 0, 0, 0)) {
        errors.patientDob = "The Date of Birth cannot be in the future.";
      }
    }
    // if (!values.flightTickets) {
    //   errors.flightTickets = "This field is required";
    // }
    if (
      !values.patientFlightTickets ||
      values.patientFlightTickets?.length === 0
    ) {
      errors.patientFlightTickets = "Please upload Flight Tickets";
    }

    if (!values.patientAirlineDetails) {
      errors.patientAirlineDetails = "This field is required";
    }
    if (!values.patientFlightNumber) {
      errors.patientFlightNumber = "This field is required";
    }

    if (!values.patientArrivalDate) {
      errors.patientArrivalDate = "This field is required";
    }

    if (!values.patientArrivalTime) {
      errors.patientArrivalTime = "This field is required";
    }
    if (!values.patientToCountry) {
      errors.patientToCountry = "This field is required";
    }
    if (!values.patientFromCountry) {
      errors.patientFromCountry = "This field is required";
    }

    if (!values.healthConcern) {
      errors.healthConcern = "This field is required";
    }

    if (!values.hospital) {
      errors.hospital = "This field is required";
    }

    if (!values.preferredCountry) {
      errors.preferredCountry = "This field is required";
    }

    if (!values.patientNationality) {
      errors.patientNationality = "This field is required";
    }

    if (!values.patientFromAirport) {
      errors.patientFromAirport = "This field is required";
    }

    if (!values.patientToAirport) {
      errors.patientToAirport = "This field is required";
    }

    if (values.patientFromAirport?.value === values.patientToAirport?.value) {
      errors.patientToAirport =
        "From Airport and To Airport cannot be the same";
    }

    const passportNumbers = new Set();

    const checkPassportNumber = (field, fieldName) => {
      if (!values[field]) {
        errors[field] = "This field is required";
      } else if (passportNumbers.has(values[field])) {
        errors[field] = `${fieldName} passport number must be unique`;
      } else {
        passportNumbers.add(values[field]);
      }
    };

    checkPassportNumber("patientPassportNumber", "Patient");

    for (let i = 1; i <= attendantsCount; i++) {
      if (!values[`attendant${i}Name`]) {
        errors[`attendant${i}Name`] = "This field is required";
      } else if (values[`attendant${i}Name`].length < 3) {
        errors[`attendant${i}Name`] = "Name must be at least 3 characters";
      }
      if (!values[`attendant${i}Passport`]) {
        errors[`attendant${i}Passport`] = "Please upload passport";
      }
      if (!values[`attendant${i}PassportNumber`]) {
        errors[`attendant${i}PassportNumber`] = "This field is required";
      }
      if (!values["attendant1SameFlightticketAsPatient"]) {
        if (!values[`attendant1AirlineDetails`]) {
          errors[`attendant1AirlineDetails`] = "This field is required";
        }
        if (!values[`attendant1FlightNumber`]) {
          errors[`attendant1FlightNumber`] = "This field is required";
        }
        if (!values[`attendant1ArrivalDate`]) {
          errors[`attendant1ArrivalDate`] = "This field is required";
        }
        if (!values[`attendant1ArrivalTime`]) {
          errors[`attendant1ArrivalTime`] = "This field is required";
        }
        // if (!values[`attendant1FromCountry`]) {
        //   errors[`attendant1FromCountry`] = "This field is required";
        // }
        // if (!values[`attendant1ToCountry`]) {
        //   errors[`attendant1ToCountry`] = "This field is required";
        // }
        if (!values[`attendant1FromAirport`]) {
          errors[`attendant1FromAirport`] = "This field is required";
        }
        if (!values[`attendant1ToAirport`]) {
          errors[`attendant1ToAirport`] = "This field is required";
        }
        if (
          !values.attendant1FlightTickets ||
          values.attendant1FlightTickets?.length === 0
        ) {
          errors.attendant1FlightTickets = "Please upload Flight Tickets";
        }
      }
      checkPassportNumber(`attendant${i}PassportNumber`, `Attendant ${i}`);
    }
    return errors;
  };

  return (
    <Layout header="Flight Tickets" iconUrl="/h/home">
      <Flex alignItems="center" mt="20px">
        <RadioGroup
          defaultValue="1"
          ml="10px"
          onChange={handleRadioButtonChange}
          value={selectedFlightTicket}
        >
          <Stack spacing={5} direction="row">
            <Radio colorScheme="blue" value="1">
              <PrimaryFormLabel mt="10px">New Flight Tickets</PrimaryFormLabel>
            </Radio>
            <Radio colorScheme="blue" value="2">
              <PrimaryFormLabel mt="10px">Existing TR ID</PrimaryFormLabel>
            </Radio>
          </Stack>
        </RadioGroup>
      </Flex>
      {selectedFlightTicket === "2" && (
        <Flex className="collapse-section">
          <Box
            display="flex"
            flexDirection="column"
            bgColor="white"
            borderRadius="10px"
            flex={{ base: "50px", md: "calc(50% - 10px)" }}
          >
            <Box
              display="flex"
              flexDirection={{ base: "column", md: "column" }}
              justifyContent="space-between"
              width={{ base: "100%", md: "50%" }}
              className="existing-trid"
            >
              <PrimaryFormLabel>Select TR ID</PrimaryFormLabel>
              <Select
                placeholder="Select TRID"
                id={"trid"}
                name={"trid"}
                menuPortalTarget={document.body}
                classNamePrefix="chakra-react-select"
                options={tridData}
                onChange={(value) => setSelectTrid(value)}
                value={selectTrid}
              />
            </Box>
          </Box>
        </Flex>
      )}
      {(selectedFlightTicket === "1" ||
        (selectedFlightTicket === "2" && selectTrid)) && (
        <Box p="0 10px" mb="10px">
          <form>
            <HealthConcernSection
              formErrors={formErrors}
              formData={formData}
              setFormData={setFormData}
              setFormErrors={setFormErrors}
              selectedOrder={selectedOrder}
              isAutopopulateData={isAutopopulateData}
              trDetails={trDetails}
              fromScreen="FT"
              // handleSelectChange={(selectedOption, actionMeta) =>
              //   handleSelectChange(
              //     formData,
              //     setFormData,
              //     formErrors,
              //     setFormErrors,
              //     selectedOption,
              //     actionMeta,
              //     dispatch
              //   )
              // }
            />
            <PatientAttendantForm
              name="Patient"
              label="Patient"
              formData={formData}
              setFormData={setFormData}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              isAutopopulateData={isAutopopulateData}
              autoPopulatedFields={autoPopulatedFields}
            />
            {/* <FlightTicketForm
            formData={formData}
            setFormData={setFormData}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            handleReportsFileChange={handleReportsFileChange}
            handleViewFile={handleViewFile}
            removeFile={removeFile}
            fileInputRef={fileInputRef}
          /> */}
            {Array.from({ length: attendantsCount }).map((_, index) => (
              <PatientAttendantForm
                name={`Attender ${index + 1}`}
                key={index}
                index={index}
                label={`Attendant ${index + 1}`}
                formData={formData}
                setFormData={setFormData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                onDelete={deleteAttendant}
                isDeleteVisible={attendantsCount > 1}
                isAutopopulateData={isAutopopulateData}
                fromScreen="FT"
                trDetails={trDetails}
                autoPopulatedFields={autoPopulatedFields}
                disableDelete={
                  autoPopulatedFields[`attendant${index + 1}Passport`] || false
                }
              />
            ))}
            <Flex justifyContent={"right"} mt="4">
              <SecondaryButton onClick={addAttendant}>
                + Add Attender
              </SecondaryButton>
            </Flex>

            <Box>
              <PrimaryHeading>Airport Pickup</PrimaryHeading>
              <Box p="20px" bgColor={"white"} borderRadius={"10px"} mt="10px">
                <Checkbox
                  color={"primary.1"}
                  // iconSize="3rem"
                  size="lg"
                  // colorScheme="primary.1"

                  isChecked={airportPickupChecked}
                  onChange={handleCheckboxChange}
                >
                  <PrimaryFormLabel mt="8px">
                    Need Airport Pickup?
                  </PrimaryFormLabel>
                </Checkbox>
              </Box>
            </Box>

            <Flex justifyContent={"right"} mt="4">
              <FooterCheckBox
                msg="I kindly confirm the details are entered correctly"
                isChecked={isConfirmed}
                setIsChecked={setIsConfirmed}
                checkboxError={showConfirmError}
              />

              <PrimaryButton type="submit" onClick={onSubmit}>
                Submit
              </PrimaryButton>
            </Flex>
          </form>

          <Alert
            isOpen={errorMessage}
            onClose={() => {
              setErrorMessage(false);
            }}
            body="Please fill all mandatory fields"
          />
          <Alert
            isOpen={isUnsaveDialogOpen}
            onClose={() => {
              setIsUnsaveDialogOpen(false);
            }}
            showCancelButton={true}
            title="Unsaved Changes!"
            body="Are you sure you want to leave? You may lose unsaved changes."
            type="info"
            callback={blocker.proceed}
          />
          <Alert
            isOpen={existingPatientAlert}
            onClose={() => {
              setExistingPatientAlert(false);
              setFormData(defaultFormData);
              // setIsModalOpen(false);
            }}
            showCancelButton={true}
            title="Info"
            body={verifyExistingPatientResponse.message}
            type="info"
            callback={handlePatientCard}
          />
          <QRCodeModal
            isOpen={showAlert}
            onClose={() => {
              setShowAlert(false);
              navigate("/existing-patients");
            }}
            name={flightTicketResponse?.patient_name}
            trid={flightTicketResponse?.tr_id}
            requestType="Flight Tickets"
            qrCodeImageUrl={flightTicketResponse?.qr_code_url}
            qrCodeImageContentUrl={flightTicketResponse?.qr_code_image_content}
          />
          {trDetails && (
            <PatientDetailsModal
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                setExistingPatientAlert(false);
                setFormData(defaultFormData);
              }}
              patient={trDetails?.[0] || []}
            />
          )}
        </Box>
      )}
    </Layout>
  );
};

export default FlightTickets;
