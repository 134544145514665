import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import Filters from "./Filters";
import { Box, Button, Flex, IconButton } from "@chakra-ui/react";
import { HiEye } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import ReactDataTable from "../../../components/PrimaryTable";

import {
  fetchCountryRequest,
  fetchExistingPatientsRequest,
  fetchHealthConcernRequest,
  fetchHospitalsRequest,
  fetchTrDetailsRequest,
} from "../hcfStore/actions";
import PatientDetailsModal from "../components/PatientDetailsModal";
import PrimaryText from "../../../components/PrimaryText";
import { IoMdDownload } from "react-icons/io";

const ExistingPatients = () => {
  const dispatch = useDispatch();

  const { existingPatientsResponse, trDetails } = useSelector(
    (state) => state.hcf
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    let payload = { is_master: true };
    dispatch(fetchHospitalsRequest(payload));
    dispatch(fetchCountryRequest());
    dispatch(fetchHealthConcernRequest(payload));
    payload = {};
    dispatch(fetchExistingPatientsRequest(payload));
  }, []);

  useEffect(() => {
    if (trDetails && trDetails.length > 0) {
      setIsModalOpen(true);
    }
  }, [trDetails]);

  const CustomCell = ({ value }) => {
    return (
      <div title={value || "No data available"}>
        {value
          ? value.length > 20
            ? `${value.slice(0, 20)}...`
            : value
          : "N/A"}
      </div>
    );
  };

  // CSV conversion function
  const convertToCSV = (columns = [], data = []) => {
    if (!columns.length || !data.length) {
      console.error("No Data available to convert to CSV");
      return null;
    }

    const headers = columns.map((col) => col.name);

    const rows = data.map((row) =>
      columns.map((col) => {
        const selector = col.selector;
        return typeof selector === "function" ? selector(row) : row[selector];
      })
    );

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((e) => e.join(",")).join("\n");

    return csvContent;
  };

  const handleDownload = () => {
    const csvContent = convertToCSV(columns, existingPatientsResponse?.data);

    if (!csvContent) {
      alert("Failed to generate CSV. No valid data or columns.");
      return;
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "existing_patients_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      name: "TR-ID",
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => (
        <PrimaryText
          style={{ cursor: "pointer", textDecoration: "none" }}
          onClick={() => handleViewClick(row.id, "tr-id")}
        >
          {row.id}
        </PrimaryText>
      ),
      width: "100px",
    },

    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
      sortable: true,
      cell: (row) => <CustomCell value={row.patient_name} />,
    },
    {
      name: "Hospital",
      selector: (row) => row.hospital,
      sortable: true,
      cell: (row) => <CustomCell value={row.hospital} />,
    },
    // {
    //   name: "Passport Uploaded",
    //   selector: (row) => row.is_passport_uploaded,
    //   sortable: true,
    // },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: true,
    // },
    {
      name: "MO Stage",
      selector: (row) => row.medical_opinion_status,
      sortable: true,
    },
    {
      name: "VIL Stage",
      selector: (row) => row.vil_status,
      sortable: true,
    },
    {
      name: "FT Stage",
      selector: (row) => row.flight_ticket_status,
      sortable: true,
    },
    // {
    //   name: "Pre-Intimations",
    //   cell: (row) => (
    //     <input
    //       type="checkbox"
    //       checked={!!row.is_pre_intimation}
    //       readOnly
    //       disabled
    //     />
    //   ),
    //   sortable: true,
    //   cellStyle: "centerColumn",
    // },
    {
      name: "Pre-Intimation",
      selector: (row) => row.preintmation_status,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => row.created_date + " " + row.created_time,
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row) => row.modified_date + " " + row.modified_time,
      sortable: true,
    },
    {
      // name: "Action",
      cell: (row) => (
        <IconButton icon={<HiEye />} onClick={() => handleViewClick(row.id)} />
      ),
      ignoreRowClick: true,
      width: "70px",
      // allowOverflow: true,
      // button: true,
    },
  ];

  const handleViewClick = (id) => {
    let payload = {
      tr_id: id,
    };
    setSelectedPatient(id);
    dispatch(fetchTrDetailsRequest(payload));
  };

  const handleSearch = (filters) => {
    let payload = filters;
    dispatch(fetchExistingPatientsRequest(payload));
  };

  return (
    <Layout header={"Existing Patients"} iconUrl="/h/home">
      <Filters onSearch={handleSearch} />
      <Flex justifyContent={"end"}>
        {existingPatientsResponse?.data?.length > 0 && (
          <Button
            rightIcon={<IoMdDownload />}
            colorScheme="green"
            m={4}
            onClick={handleDownload}
          >
            Download
          </Button>
        )}
      </Flex>
      <Box
        minHeight="600px"
        bgColor={"#ffff"}
        ml="10px"
        shadow="md"
        borderWidth="1px"
        borderRadius="md"
      >
        <ReactDataTable
          columns={columns}
          data={existingPatientsResponse?.data || []}
          handleViewClick={handleViewClick}
          selectedPatientId={selectedPatient}
        />
        {selectedPatient && trDetails && (
          <PatientDetailsModal
            isOpen={isModalOpen}
            handleRefresh={handleViewClick}
            onClose={() => {
              setIsModalOpen(false);
              setSelectedPatient(null);
            }}
            patient={trDetails?.[0] || []}
          />
        )}
      </Box>
    </Layout>
  );
};

export default ExistingPatients;
