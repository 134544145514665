import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  VStack,
  Text,
  HStack,
  Box,
} from "@chakra-ui/react";
import PrimaryButton from "../../../components/PrimaryButton";
import PrimaryHeading from "../../../components/PrimaryHeading";
import {
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { useNavigate } from "react-router-dom";
import TertiaryButton from "../../../components/TertiaryButton";
// import ShareModal from "../../../components/ShareModal";

export const QRCodeModal = ({
  isOpen,
  onClose,
  name,
  trid,
  requestType,
  qrCodeImageUrl,
  qrCodeImageContentUrl,
}) => {
  // const shareDisclosure = useDisclosure();
  const navigate = useNavigate();
  const shareUrl = qrCodeImageUrl;
  const [shareModalOpen, setShareModalOpen] = useState(false);
  // const RequestType = requestType;
  useEffect(() => {
    let timeout;
    if (isOpen) {
      timeout = setTimeout(() => {
        handleSave();
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [isOpen]);

  const handleShare = () => {
    setShareModalOpen((prevState) => !prevState);
  };

  const handleSave = () => {
    const imageData = qrCodeImageContentUrl;
    const pageImage = new Image();

    const fileName = qrCodeImageUrl.split("/").pop();

    pageImage.src = `data:image/png;base64,${btoa(
      new Uint8Array(imageData).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    )}`;

    pageImage.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = pageImage.naturalWidth;
      canvas.height = pageImage.naturalHeight;

      const ctx = canvas.getContext("2d");
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(pageImage, 0, 0);

      const link = document.createElement("a");
      link.download = fileName || "image.png"; // Fallback if fileName is not available

      canvas.toBlob(function (blob) {
        link.href = URL.createObjectURL(blob);
        link.click();
      });
    };
  };

  // const handleShare = () => {
  //   shareDisclosure.onOpen();
  // };

  const navigateToRequest = () => {
    window.location.reload();
  };

  const getNewRequestButtonText = () => {
    switch (requestType) {
      case "Travel Request":
        return "New Travel Request";
      case "Medical Opinion":
        return "New Medical Opinion";
      case "Flight Tickets":
        return "New Flight Tickets";
      case "Pre-Intimation":
        return "New PreIntimation";
      default:
        return "New Request";
    }
  };

  return (
    <>
      <Modal
        m="10px"
        pt="40px"
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />

        <ModalContent p="10px">
          {/* <ModalCloseButton /> */}
          <ModalBody alignItems="center">
            <VStack spacing={4}>
              <PrimaryHeading fontWeight="bold">Name: {name}</PrimaryHeading>
              <PrimaryHeading fontWeight="bold">TRID: {trid}</PrimaryHeading>
              <Box bgColor="white" id="qrcode">
                {/* <QRCode
                  value={`Name: ${name}, TRID: ${trid}`}
                  logoImage={companyIcon}
                  size={300}
                  logoHeight={65}
                  logoWidth={65}
                  logoOpacity={1}
                  style={{
                    padding: "20px",
                    border: "3px solid black",
                    borderRadius: "15px",
                  }}
                /> */}
                <img
                  src={qrCodeImageUrl} // Replace with your actual image URL
                  alt="QR Code"
                  style={{
                    height: "300px",
                    border: "2px solid black",
                    borderRadius: "15px",
                  }}
                />
              </Box>
              <HStack
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                justifyContent="space-between"
              >
                <PrimaryButton
                  colorScheme="blue"
                  mr={7}
                  width="150px"
                  borderRadius="lg"
                  height="50px"
                  onClick={handleSave}
                >
                  Save
                </PrimaryButton>
                <PrimaryButton
                  onClick={handleShare}
                  width="150px"
                  borderRadius="lg"
                  height="50px"
                >
                  Share
                </PrimaryButton>
              </HStack>
              {shareModalOpen && (
                <HStack ml="170px">
                  <WhatsappShareButton
                    url={shareUrl}
                    title="Check out this QR code"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={shareUrl}
                    subject="Check out this QR code"
                    body="Here is a QR code you might find interesting:"
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </HStack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack alignItems="flex-start" spacing={2}>
              <Text fontWeight="bold">Note:</Text>
              <Text>
                Save the QR Code and share it with the patient for convenient
                hospital access
              </Text>
            </HStack>
          </ModalFooter>
          <HStack m="25px">
            {/* <Text
              ml="40px"
              mr="5px"
              w="20%"
              onClick={() => {
                navigate("/h/home");
              }}
              color="primary.1"
              fontSize={"lg"}
              cursor="pointer"
            >
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "5px",
                  backgroundColor: "#2B67AF",
                }}
              />
              Home
            </Text> */}

            <TertiaryButton
              onClick={() => {
                navigate("/h/home");
              }}
            >
              Home
            </TertiaryButton>

            <TertiaryButton onClick={navigateToRequest}>
              {getNewRequestButtonText()}
            </TertiaryButton>
            <TertiaryButton
              onClick={() => {
                navigate("/existing-patients");
              }}
            >
              Existing Patients
            </TertiaryButton>
          </HStack>
        </ModalContent>
      </Modal>
      {/* <ShareModal
        isOpen={shareDisclosure.isOpen}
        onClose={shareDisclosure.onClose}
        shareUrl={shareUrl}
        title={title}
      /> */}
    </>
  );
};

export default QRCodeModal;
