import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  IconButton,
  Box,
  Icon,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, InfoIcon, WarningIcon } from "@chakra-ui/icons";

const iconMap = {
  success: { icon: CheckIcon, color: "green" },
  info: { icon: InfoIcon, color: "blue" },
  warning: { icon: WarningIcon, color: "blue" },
  error: { icon: CloseIcon, color: "red" },
};

const Alert = ({
  isOpen,
  onClose,
  title = "Warning!",
  body = "Something went wrong, please try after sometime.",
  showCancelButton = false,
  showConfirmButton = true,
  confirmButtonText = "OK",
  confirmButtonColor = "#2B67AF",
  cancelButtonText = "Cancel",
  cancelButtonColor = "#2B67AF",
  type = "error",
  allowOutsideClick = false,
  callback,
  ...props
}) => {
  const handleConfirm = () => {
    if (callback) {
      callback();
    }
    onClose();
  };

  const { icon: AlertIcon, color: iconColor } = iconMap[type] || iconMap.info;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={allowOutsideClick}
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb={"20px"}>
          <IconButton
            aria-label="Close"
            isRound={true}
            icon={<AlertIcon />}
            colorScheme={iconColor}
            variant="solid"
            size="xl"
            position="absolute"
            top="-42px"
            left="50%"
            transform="translateX(-50%)"
            fontSize="40px"
            p="24px"
          />
          {/* <Box position="relative" textAlign="center" mt={3}>
            <IconButton
              aria-label="Alert Icon"
              isRound={true}
              icon={<AlertIcon />}
              colorScheme={iconColor}
              variant="solid"
              size="xl"
              fontSize="100px"
              border="4px solid"
              borderColor={`${iconColor}.500`}
            />
          </Box> */}
        </ModalHeader>

        <ModalBody textAlign="center" paddingTop="10px">
          <Text as="b" fontSize="2xl">
            {title}
          </Text>
          <Text fontSize="xl" mt={2} mb={"10px"}>
            {body}
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          {showCancelButton && (
            <Button
              variant="outline"
              // colorScheme="#2B67AF"
              colorScheme="blue"
              onClick={onClose}
              flex={1}
              mx={2}
            >
              {cancelButtonText}
            </Button>
          )}
          {showConfirmButton && (
            <Button
              variant="solid"
              colorScheme={iconColor}
              onClick={handleConfirm}
              flex={1}
              mx={2}
            >
              {confirmButtonText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Alert;
