import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
} from "./actions";

const initialState = {
  notifications: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        errorResponse: false,
      };
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
};

export default notificationReducer;
