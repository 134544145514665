import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Input,
} from "@chakra-ui/react";
import PrimaryText from "../../../components/PrimaryText";

const PreIntimationModal = ({
  isOpen,
  onClose,
  patientMovingFrom,
  ArrivalWithin,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="primary.1" textAlign={"center"} fontSize={"2xl"}>
          Pre-Intimation Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4} mt="10px">
            <PrimaryText fontWeight="bold">Patient Moving From</PrimaryText>
            <Input
              name="patientMovingFrom"
              value={patientMovingFrom}
              isReadOnly={true}
            />
          </Box>
          <Box mb={4}>
            <PrimaryText fontWeight="bold">Airlines Detail</PrimaryText>
            <Input
              name="ArrivalWithin"
              value={ArrivalWithin}
              isReadOnly={true}
            />
          </Box>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PreIntimationModal;
