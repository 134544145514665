import React, { useState } from "react";
import Layout from "../../../layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import DashboardCard from "./DashboardCard.js";
import { useNavigate } from "react-router-dom";
import {
  fetchDashboardSummaryRequest,
  setDashboardCurrentState,
} from "../hcfStore/actions.js";
import { filtersPayload } from "../../utils/index.js";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dashboardSummary } = useSelector((state) => state.hcf);

  const [filters, setFilters] = useState({
    country: [],
    healthConcern: [],
    hospital: [],
    gender: [],
    daysFilter: "",
    fromDate: "",
    toDate: "",
    dateError: "",
  });

  useEffect(() => {
    let payload = { filters: false };
    dispatch(fetchDashboardSummaryRequest(payload));
  }, []);

  const handleCard = (filter, status, data, label) => {
    localStorage.setItem("dashboard_filter", filter);
    localStorage.setItem("dashboard_status", status);
    localStorage.setItem("dashboard_label", label);
    dispatch(setDashboardCurrentState(data));
    navigate("/h/dashboard/summary");
  };

  const handleFilter = () => {
    let payload = filtersPayload(
      filters.healthConcern,
      filters.hospital,
      filters.gender,
      filters.country,
      filters.daysFilter,
      filters.fromDate,
      filters.toDate
    );
    payload.filters = true;
    dispatch(fetchDashboardSummaryRequest(payload));
  };

  return (
    <Layout
      header="Dashboard"
      iconUrl="/h/home"
      showFilters={true}
      handleFilter={handleFilter}
      setFilters={setFilters}
      filters={filters}
    >
      <DashboardCard
        heading="Hospital Opinion"
        data={dashboardSummary?.hsp_opinion || []}
        onClick={handleCard}
      />
      <DashboardCard
        heading="Travel Request"
        data={dashboardSummary?.travel_request || []}
        onClick={handleCard}
      />
      <DashboardCard
        heading="Flight Tickets"
        data={dashboardSummary?.flight_tickets || []}
        onClick={handleCard}
      />
      <DashboardCard
        heading="Pre-Intimation"
        data={dashboardSummary?.pre_intimation || []}
        onClick={handleCard}
      />
      {/* <DashboardCard
        heading="Discharge"
        data={dashboardSummary?.discharge || []}
        onClick={handleCard}
      /> */}
    </Layout>
  );
};

export default Dashboard;
