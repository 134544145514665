import { chakra } from "@chakra-ui/react";
import companyLogo from "../../../assets/images/companyLogo.png";

export const Logo = () => (
  <chakra.img
    src={companyLogo}
    alt="MediJourn Logo"
    style={{ padding: "0 10px" }}
    // style={{ padding: "0 80px" }}
  />
);
