import {
  Box,
  Flex,
  Container,
  Link,
  Stack,
  Text,
  HStack,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";

export const ValidateForgotPasswordOtp = () => {
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate("/change-password");
  };

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow="5px 5px 30px 0px rgb(224 222 222 / 89%)"
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              {/* <Text fontSize='xs'>Please enter OTP you received via email to reset your password</Text> */}
              <Text fontSize="xs" textAlign="center">
                Code is send to your mail address
              </Text>
              <Stack spacing="6">
                <Stack spacing="5">
                  <HStack justifyContent="center">
                    <PinInput
                      otp
                      type="number"
                      size="md"
                      manageFocus={true}
                      placeholder=""
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </Stack>
                <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                  <Text color="fg.muted">
                    Didn't receive code?{" "}
                    <Link href="#" style={{ color: "#57ACFF" }}>
                      Request again
                    </Link>
                  </Text>
                </Stack>
                <Stack spacing="6">
                  <PrimaryButton onClick={onSubmit}>Verify</PrimaryButton>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Flex>
  );
};
