export const handleReportsFileChange = (
  event,
  selectedFile,
  setSelectedFile,
  formData,
  setFormData,
  formErrors,
  setFormErrors,
  name
) => {
  const files = Array.from(event.target.files);

  // Maximum number of files allowed
  const maxFiles = 50;

  if (files.length + selectedFile.length > maxFiles) {
    setFormErrors({
      ...formErrors,
      reports: `You can upload a maximum of ${maxFiles} files.`,
    });
    return;
  }

  // const totalSize = calculateTotalSize([...selectedFile, ...files]);
  // if (totalSize > 25) {
  //   setTotalSizeExceeded(true);
  //   setFormErrors({ ...formErrors, reports: "File exceeded 25MB" });
  // } else {
  const updatedFiles = [...selectedFile, ...files];
  // setSelectedFile(totalSize <= 25 ? updatedFiles : selectedFile);
  setSelectedFile(updatedFiles);
  if (name === "reports") {
    setFormData({ ...formData, reports: updatedFiles });
    setFormErrors({ ...formErrors, reports: false });
  }
  if (name === "flightTickets") {
    setFormData({ ...formData, flightTickets: updatedFiles });
    setFormErrors({ ...formErrors, flightTickets: false });
  }
  // }
};

// export const calculateTotalSize = (files) => {
//   const totalSize = files.reduce(
//     (acc, file) => acc + file.size / (1024 * 1024),
//     0
//   );
//   return totalSize;
// };

export const removeFile = (
  index,
  selectedFile,
  setSelectedFile,
  formData,
  setFormData,
  formErrors,
  setFormErrors
) => {
  const updatedFiles = [...selectedFile];
  updatedFiles.splice(index, 1);
  setSelectedFile(updatedFiles);
  setFormData({ ...formData, reports: updatedFiles });
  if (updatedFiles.length < 1) {
    setFormErrors({
      ...formErrors,
      reports: "Please upload at least one File",
    });
    return;
  }
};

export const handleViewFile = (file) => {
  if (file.type.startsWith("image/") || file.type === "application/pdf") {
    window.open(URL.createObjectURL(file));
  } else {
    alert("Unsupported file type for preview. You can download the file.");
  }
};
