import React, { useMemo, useState } from "react";
import {
  Box,
  Text,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Divider,
} from "@chakra-ui/react";
import PrimaryHeading from "../../../components/PrimaryHeading";
import PrimaryText from "../../../components/PrimaryText";
import SecondaryButton from "../../../components/SecondaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { resetSearch, searchRequest } from "../store/actions";
import { useDispatch } from "react-redux";

const DropdownItems = ({ searchQuery }) => {
  const { searchResponse, isSearchResults } = useSelector(
    (state) => state.search
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [expandedSection, setExpandedSection] = useState(null);

  const handleSeeMore = (section) => {
    dispatch(resetSearch());
    localStorage.setItem("search_section", section);
    localStorage.setItem("search_query", searchQuery);

    if (location.pathname === "/search") {
      const payload = { query: searchQuery, section: section };
      dispatch(searchRequest(payload));
    } else {
      navigate("/search");
    }
  };

  const itemMap = useMemo(
    () => ({
      "TR-ID": searchResponse.tr_id.map((item) => ({
        id: item.tr_id,
        secondary: item.tr_id,
        text: item.patient_name || "",
        section: "tr_id",
      })),
      "Patient Name": searchResponse.patient_name.map((item) => ({
        id: item.patient_name,
        text: item.patient_name,
        section: "patient_name",
      })),
      "Passport Number": searchResponse.passport_no.map((item) => ({
        id: item.passport_no,
        secondary: item.passport_no,
        text: item.patient_name || "",
        section: "passport_no",
      })),
      Hospitals: searchResponse.hospital.map((item) => ({
        id: item.hospital,
        text: item.hospital,
        section: "hospital",
      })),
      "Health Concern": searchResponse.health_concern.map((item) => ({
        id: item.health_concern,
        text: item.patient_name,
        secondary: item.health_concern,
        section: "health_concern",
      })),
      Country: searchResponse.country.map((item) => ({
        id: item.patient_nationality,
        text: item.patient_name,
        secondary: item.patient_nationality,
        section: "country",
      })),
    }),
    [searchResponse]
  );

  const renderList = (header) => {
    const listItems = itemMap[header] || [];

    return (
      <Card
        key={header}
        borderRadius="md"
        borderWidth="1px"
        mb="20px"
        border="none"
      >
        <CardHeader bg="blue.100" p="10px">
          <PrimaryHeading size="md">{header}</PrimaryHeading>
        </CardHeader>
        <CardBody p="0px">
          {listItems.length > 0 ? (
            listItems.map((item, index) => (
              <React.Fragment key={index}>
                <Flex direction="row" align="center" p="10px">
                  <Text>{item.text}</Text>
                  {header !== "Hospitals" && header !== "Patient Name" && (
                    <Text ml={1}>
                      <PrimaryText>({item.secondary})</PrimaryText>
                    </Text>
                  )}
                </Flex>
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <Text p="15px" align="center">
              No results found
            </Text>
          )}
          {listItems.length > 0 && (
            <Flex justify="flex-end">
              <SecondaryButton
                p="5px"
                variant="link"
                onClick={() => handleSeeMore(listItems[0].section)}
              >
                See More
              </SecondaryButton>
            </Flex>
          )}
        </CardBody>
      </Card>
    );
  };

  return (
    <Box
      position="absolute"
      top="120%"
      left="10px"
      width="50%"
      zIndex="1"
      bg="white"
      boxShadow="lg"
      p={4}
      borderRadius="md"
      overflowY="auto"
      maxHeight="calc(100vh - 200px)"
      display={isSearchResults ? "block" : "none"}
    >
      {[
        "TR-ID",
        "Patient Name",
        "Passport Number",
        "Hospitals",
        "Health Concern",
        "Country",
      ].map((header) => renderList(header))}
    </Box>
  );
};

export default DropdownItems;
