import { all } from "redux-saga/effects";
import authSaga from "./../features/authentication/authStore/saga";
import hcfSaga from "../features/hcf/hcfStore/saga";
import notificationSaga from "../features/notifications/store/saga";
import messagesSaga from "../features/messages/store/saga";
import searchSaga from "../features/search/store/saga";
import commonSaga from "../features/common/store/saga";

export function* rootSaga() {
  yield all([
    authSaga(),
    hcfSaga(),
    notificationSaga(),
    messagesSaga(),
    searchSaga(),
    commonSaga(),
  ]);
}
