import React from "react";
import { Flex, Spinner, Center, Text } from "@chakra-ui/react";

const LoadingSpinner = () => {
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="9999"
      backgroundColor="rgba(255, 255, 255, 0.5)"
      justifyContent="center"
      alignItems="center"
    >
      <Center>
        <Flex flexDirection="column" alignItems="center" textAlign="center">
          <Spinner
            thickness="6px"
            // speed="0.8s"
            // emptyColor="gray.200"
            size="xl"
            color="primary.1"
          />
          <Text as="b" fontSize="xl" color="primary.1">
            Loading...
          </Text>
        </Flex>
      </Center>
    </Flex>
  );
};

export default LoadingSpinner;
