import React, { useEffect, useState } from "react";
import {
  Stack,
  Text,
  HStack,
  PinInput,
  PinInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import PrimaryButton from "../../../components/PrimaryButton";
import SecondaryButton from "../../../components/SecondaryButton";
// import ErrorText from "../../../components/ErrorText";
import { useDispatch, useSelector } from "react-redux";
import { resendOTPRequest } from "../authStore/actions";
import SuccessAlert from "../../../components/SuccessAlert";
import ErrorAlert from "../../../components/ErrorAlert";

const OtpModal = ({ isOpen, onClose, onSubmit, user, otpType }) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [otpError, setOTPError] = useState(false);
  const [resendOTP, setResendOtp] = useState(false);
  const [loadingText, setLoadingText] = useState("Verifying");
  const [countdown, setCountdown] = useState(0);
  const [isRequestDisabled, setIsRequestDisabled] = useState(false);

  const { otp_errors, resend_success, otp_loading } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (resend_success) setResendOtp("OTP Sent Successfully");
    setCountdown(20);
  }, [resend_success]);

  useEffect(() => {
    setResendOtp(false);
    setOTPError(false);
  }, []);

  useEffect(() => {
    if (otp_errors) {
      setIsRequestDisabled(false);
      setOTPError(otp_errors.message);
    }
  }, [otp_errors]);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleSubmit = () => {
    if (!otp) {
      setOTPError("Otp is Mandatory");
      return;
    } else {
      setOTPError("");
    }
    setOTPError(false);
    setResendOtp(false);
    setLoadingText("Verifying");
    setIsRequestDisabled(true);
    onSubmit(otp);
    //onClose(); // Close the modal after submission
  };

  const handleResendOtp = () => {
    setOTPError(false);
    setResendOtp(false);
    setLoadingText("Requesting otp");
    setIsRequestDisabled(true);
    let payload = {
      usr: user,
      otp_type: otpType,
    };
    dispatch(resendOTPRequest(payload));
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [otp]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>Verify OTP</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        <ModalBody m="10px">
          {/* <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow="5px 5px 30px 0px rgb(224 222 222 / 89%)"
            borderRadius={{ base: "none", sm: "xl" }}
          > */}
          <Stack spacing="6">
            <Text fontSize="md" textAlign="center">
              Code is sent to your email address
            </Text>
            <Stack>
              <HStack justifyContent="center">
                <PinInput
                  otp
                  type="number"
                  size="lg"
                  manageFocus={true}
                  placeholder=""
                  onChange={(value) => {
                    setOTPError(false);
                    setOtp(value);
                    setResendOtp(false);
                  }}
                >
                  <PinInputField
                    autoFocus
                    style={{
                      width: "60px",
                      height: "60px",
                      fontSize: "x-large",
                    }}
                  />
                  <PinInputField
                    style={{
                      width: "60px",
                      height: "60px",
                      fontSize: "x-large",
                    }}
                  />
                  <PinInputField
                    style={{
                      width: "60px",
                      height: "60px",
                      fontSize: "x-large",
                    }}
                  />
                  <PinInputField
                    style={{
                      width: "60px",
                      height: "60px",
                      fontSize: "x-large",
                    }}
                  />
                </PinInput>
              </HStack>
              <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                {/* {otpError && <ErrorText message={otpError} />} */}
                <Text color="fg.muted">
                  Didn't receive code?{" "}
                  <SecondaryButton
                    onClick={handleResendOtp}
                    pl="0px"
                    isDisabled={countdown > 0 || isRequestDisabled}
                  >
                    {countdown > 0
                      ? `Request again in ${countdown}s`
                      : "Request again"}
                  </SecondaryButton>
                  {otpError && <ErrorAlert message={otpError} />}
                  {resendOTP && <SuccessAlert message={resendOTP} />}
                </Text>
              </Stack>
              <Stack spacing="5">
                <PrimaryButton
                  onClick={handleSubmit}
                  isLoading={otp_loading}
                  loadingText={loadingText}
                  spinnerPlacement="end"
                >
                  Verify
                </PrimaryButton>
              </Stack>
            </Stack>
          </Stack>
          {/* </Box> */}
        </ModalBody>

        {/* <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default OtpModal;
