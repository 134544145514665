import React, { useEffect, useState } from "react";
import DashboardSecondaryCard from "../components/DashboardSecondaryCard";
import Layout from "../../../layout";
import { Box, IconButton } from "@chakra-ui/react";
import { HiEye } from "react-icons/hi";
import PatientDetailsModal from "../components/PatientDetailsModal";
import ReactDataTable from "../../../components/PrimaryTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboardSummaryRequest,
  fetchTrDetailsRequest,
  setDashboardCurrentState,
} from "../hcfStore/actions";

const DashboardView = ({}) => {
  const dispatch = useDispatch();
  const {
    getDashboardCardData,
    dashboardSummaryDetails,
    trDetails,
  } = useSelector((state) => state.hcf);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(false);

  useEffect(() => {
    let payload = {
      category: localStorage.getItem("dashboard_filter"),
      status_name: localStorage.getItem("dashboard_status"),
      summary: true,
    };
    dispatch(setDashboardCurrentState(getDashboardCardData));
    dispatch(fetchDashboardSummaryRequest(payload));
  }, []);

  useEffect(() => {
    if (trDetails && trDetails.length > 0) {
      setIsModalOpen(true);
    }
  }, [trDetails]);

  const columns = [
    {
      name: "TR-ID",
      selector: (row) => (
        <div
          style={{ cursor: "pointer", textDecoration: "none" }}
          onClick={() => handleViewClick(row.id, "tr-id")}
        >
          {row.id}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
      sortable: true,
    },
    { name: "Hospital", selector: (row) => row.hospital, sortable: true },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: true,
    // },
    {
      name: "MO Status",
      selector: (row) => row.medical_opinion_status,
      sortable: true,
    },
    {
      name: "VIL Status",
      selector: (row) => row.vil_status,
      sortable: true,
    },
    {
      name: "FT Status",
      selector: (row) => row.flight_ticket_status,
      sortable: true,
    },
    {
      name: "Pre-Intimations",
      selector: (row) => row.preintmation_status,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.created_date,
      sortable: true,
    },
    {
      name: "Created Date &  Time",
      selector: (row) => row.created_date + " " + row.created_time,
      sortable: true,
    },
    {
      name: "Updated Date & Time",
      selector: (row) => row.modified_date + " " + row.modified_time,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <IconButton icon={<HiEye />} onClick={() => handleViewClick(row.id)} />
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  const handleViewClick = (id) => {
    let payload = {
      tr_id: id,
    };
    setSelectedPatient(id);
    dispatch(fetchTrDetailsRequest(payload));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPatient(null);
  };

  const handleSectionClick = (section) => {
    console.log("SECTION CLICKED:", section);
  };

  const dashboardFilterHeader = localStorage.getItem("dashboard_filter");
  let Header;
  if (dashboardFilterHeader === "hsp_opinion") {
    Header = "Hospital Opinion";
  } else if (dashboardFilterHeader === "travel_request") {
    Header = "Travel Request";
  } else if (dashboardFilterHeader === "flight_tickets") {
    Header = "Flight Tickets";
  } else if (dashboardFilterHeader === "pre_intimation") {
    Header = "PreIntimation";
  } else if (dashboardFilterHeader === "discharge") {
    Header = "Discharge";
  }

  return (
    <Layout header={Header} iconUrl="/h/dashboard">
      <DashboardSecondaryCard
        data={getDashboardCardData}
        onSectionClick={handleSectionClick}
      />
      <Box minHeight="600px" bgColor={"#ffff"} mt="20px" ml="10px">
        <ReactDataTable
          columns={columns}
          data={dashboardSummaryDetails}
          handleViewClick={handleViewClick}
          selectedPatientId={selectedPatient}
        />
        {selectedPatient && trDetails && (
          <PatientDetailsModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            patient={trDetails?.[0] || []}
          />
        )}
      </Box>
    </Layout>
  );
};

export default DashboardView;
