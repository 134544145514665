import { extendTheme } from "@chakra-ui/react";

const colors = {
  primary: {
    1: "#2B67AF",
    2: "#57ACFF",
    3: "#79b4f1",
    4: "#C6DEEF",
  },
  grey: {
    1: "grey",
    2: "#222222",
    3: "#ccc",
    4: "#575353",
  },
  gray: {
    400: "#CBD5E0",
  },
  secondary: {
    1: "#00BC7D",
    400: "#38A169",
    500: "#2F855A",
    600: "#276749",
  },
};

const fonts = {
  body: "Inter, sans-serif",
  heading: "Inter, sans-serif",
};

const theme = extendTheme({
  colors,
  fonts,
});

export default theme;
