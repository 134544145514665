import {
  RESET_SEARCH,
  SEARCH_REQUEST,
  SEARCH_SECTION_SUCCESS,
  SEARCH_SUCCESS,
  SET_SEARCH_QUERY,
} from "./actions";

const initialState = {
  searchResponse: {},
  isSearchResults: false,
  searchSectionResponse: [],
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        searchResponse: {},
        isSearchResults: false,
        searchSectionResponse: [],
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        searchResponse: action.payload,
        isSearchResults: true,
      };

    case RESET_SEARCH:
      return {
        ...state,
        searchResponse: {},
        isSearchResults: false,
      };
    case SEARCH_SECTION_SUCCESS:
      return {
        ...state,
        isSearchResults: false,
        searchSectionResponse: action.payload,
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.query,
      };
    default:
      return state;
  }
};

export default searchReducer;
