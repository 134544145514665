import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { CloseIcon, Search2Icon } from "@chakra-ui/icons";
import DropdownItems from "./components/DropDownItems";
import { resetSearch, searchRequest } from "./store/actions";
import { useLocation } from "react-router-dom";

const Search = ({ placeholder }) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const location = useLocation();

  const { isSearchResults } = useSelector((state) => state.search);

  const [query, setQuery] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query.length >= 3) {
        let payload = { query: query.trim(), section: false };
        dispatch(searchRequest(payload));
      }
    }, 1200);
    return () => clearTimeout(delayDebounceFn);
  }, [query, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        dispatch(resetSearch());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClear = () => {
    setQuery("");
    dispatch(resetSearch());
    inputRef.current.focus();
  };

  // useEffect(() => {
  //   if (location !== "/search") {
  //     localStorage.setItem("search_query", "");
  //   }
  // }, [location]);

  return (
    <>
      <InputGroup ml={{ base: 4, md: 12 }}>
        <InputLeftElement pointerEvents="none">
          <Search2Icon color="primary.1" />
        </InputLeftElement>

        {query && query !== "" && (
          <InputRightElement>
            <IconButton
              aria-label="Clear input"
              icon={<CloseIcon color="red.500" />}
              size="sm"
              onClick={handleClear}
              variant="ghost"
            />
          </InputRightElement>
        )}
        <Input
          type="text"
          placeholder={placeholder}
          value={query}
          // onChange={handleInputChange}
          onChange={(e) => setQuery(e.target.value)}
          // onFocus={() => setDropdownVisible(query.length > 2)}
          size="md"
          variant="filled"
          width="100%"
          ref={inputRef}
        />
      </InputGroup>
      {isSearchResults && (
        <Box ref={dropdownRef}>
          <DropdownItems searchQuery={query} />
        </Box>
      )}
      {/* {isDropdownVisible && <DropdownItems searchResponse={searchResponse} />} */}
    </>
  );
};

export default Search;
