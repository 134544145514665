// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chakra-react-select__menu-portal {
    z-index: 100;
}

.react-tel-input .form-control {
    font-size: 16px !important;
    border: 1px solid !important;
    border-color: inherit !important;
    /* font-family: inherit !important; */
    font-family: system-ui !important;
}`, "",{"version":3,"sources":["webpack://./src/layout/base.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,gCAAgC;IAChC,qCAAqC;IACrC,iCAAiC;AACrC","sourcesContent":[".chakra-react-select__menu-portal {\n    z-index: 100;\n}\n\n.react-tel-input .form-control {\n    font-size: 16px !important;\n    border: 1px solid !important;\n    border-color: inherit !important;\n    /* font-family: inherit !important; */\n    font-family: system-ui !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
