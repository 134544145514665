import {
  FETCH_ALL_MESSAGES_SUMMARY_SUCCESS,
  FETCH_SINGLE_MESSAGE_HISTORY_SUCCESS,
  HIDE_MESSAGE_LOADER,
  POST_USER_RESPONSE_SUCCESS,
  SHOW_MESSAGE_LOADER,
  START_CONVERSATION_SUCCESS,
} from "./actions";

const initialState = {
  initialMessage: [],
  botUserResponse: [],
  messagesSummaryHistory: [],
  singleMessageHistory: [],
  loading: false,
  botUserResponseStatus: false,
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE_LOADER:
      return {
        ...state,
        loading: true,
      };

    case HIDE_MESSAGE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case START_CONVERSATION_SUCCESS:
      return {
        ...state,
        initialMessage: action.payload,
      };
    case POST_USER_RESPONSE_SUCCESS:
      return {
        ...state,
        botUserResponse: action.payload.data,
        botUserResponseStatus: action.payload,
      };
    case FETCH_ALL_MESSAGES_SUMMARY_SUCCESS:
      return {
        ...state,
        messagesSummaryHistory: action.payload,
      };
    case FETCH_SINGLE_MESSAGE_HISTORY_SUCCESS:
      return {
        ...state,
        singleMessageHistory: action.payload,
      };
    default:
      return state;
  }
};

export default messagesReducer;
