import { Button } from "@chakra-ui/react";
import React from "react";
// import { AddIcon } from "@chakra-ui/icons";

const SecondaryButton = (props) => {
  return (
    <Button
      //   leftIcon={<AddIcon />}
      color="primary.1"
      variant="ghost"
      //   _hover={{ bg: "primary.3" }}
      //   _active={{ bg: "blue.700" }}
      {...props}
    />
  );
};

export default SecondaryButton;
