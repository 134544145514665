import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Box,
  ModalFooter,
  Icon,
  Flex,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPassportDetailsRequest } from "../hcfStore/actions";
import PrimaryButton from "../../../components/PrimaryButton";
import Iframe from "react-iframe";
import PrimaryText from "../../../components/PrimaryText";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Select } from "chakra-react-select";

const PassportModal = ({
  isOpen,
  onClose,
  trDetails = {},
  isPatientPassport = true,
  handleSubmit = () => {},
}) => {
  const dispatch = useDispatch();
  const { passportDetails, countryData } = useSelector((state) => state.hcf);

  const [attendantIndex, setAttendantIndex] = useState(0);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [passportError, setPassportError] = useState("");
  const [attendantDetails, setAttendantDetails] = useState(
    trDetails.attendant_details || []
  );

  const [editableDetails, setEditableDetails] = useState({
    name: "",
    passportNo: "",
    nationality: "",
    dob: "",
    passportImage: "",
  });

  useEffect(() => {
    if (passportDetails) {
      if (isPatientPassport) {
        setEditableDetails({
          name: passportDetails.name,
          passportNo: passportDetails.passport_number,
          nationality: {
            label: passportDetails.nationality,
            value: passportDetails.nationality,
          },
          dob: passportDetails.dob.split("-").reverse().join("-"),
        });
      } else {
        setEditableDetails({
          name: passportDetails.name,
          passportNo: passportDetails.passport_number,
        });
      }
    }
  }, [passportDetails]);

  useEffect(() => {
    if (isPatientPassport) {
      setEditableDetails({
        name: trDetails.patient_name,
        passportNo: trDetails.passport_no,
        nationality: {
          label: trDetails.nationality,
          value: trDetails.nationality,
        },
        dob: trDetails.dob,
        passportImage: trDetails.patient_passport,
      });
    } else {
      if (attendantDetails.length > 0) {
        updateAttendantDetails(attendantIndex);
      }
    }
  }, [trDetails, isPatientPassport, attendantIndex]);

  const updateAttendantDetails = (index) => {
    const currentAttendant = attendantDetails[index];
    setEditableDetails({
      name: currentAttendant.attendant_name,
      passportNo: currentAttendant.passport_no,
      passportImage: currentAttendant.attendant_passport,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "passportNo") {
      setPassportError("");
    }
  };

  const handleSelectChange = (e) => {
    setEditableDetails((prevState) => ({
      ...prevState,
      nationality: {
        label: e.label,
        value: e.value,
      },
    }));
  };

  const nextAttendant = () => {
    if (attendantIndex < attendantDetails.length - 1) {
      setAttendantIndex(attendantIndex + 1);
    }
  };

  const previousAttendant = () => {
    if (attendantIndex > 0) {
      setAttendantIndex(attendantIndex - 1);
    }
  };

  const handleUpdate = () => {
    // Determine if there are changes
    let hasChanges = false;
    let payload = {
      "tr-id": trDetails.id,
      isPatientPassport: isPatientPassport,
    };

    if (
      !isPatientPassport &&
      editableDetails.passportNo === trDetails.passport_no
    ) {
      setPassportError(
        "Attendant passport and patient passport cannot be the same."
      );
      return; // Stop update process if validation fails
    }

    // Check patient details
    if (isPatientPassport) {
      if (
        editableDetails.name !== trDetails.patient_name ||
        editableDetails.passportNo !== trDetails.passport_no ||
        editableDetails.nationality?.value !== trDetails.nationality ||
        editableDetails.dob !== trDetails.dob ||
        uploadedImage
      ) {
        hasChanges = true;
        if (editableDetails.name !== trDetails.patient_name) {
          payload.name = editableDetails.name;
        }
        if (editableDetails.passportNo !== trDetails.passport_no) {
          payload.passportNo = editableDetails.passportNo;
        }
        if (editableDetails.nationality?.value !== trDetails.nationality) {
          payload.nationality = editableDetails.nationality?.value;
        }
        if (editableDetails.dob !== trDetails.dob) {
          payload.dob = editableDetails.dob;
        }
        if (uploadedImage) {
          payload.passportImage = uploadedImage;
        }
        handleSubmit(payload);
      }
    } else {
      // Check attendant details
      const currentAttendant = attendantDetails[attendantIndex];

      if (
        editableDetails.name !== currentAttendant.attendant_name ||
        editableDetails.passportNo !== currentAttendant.passport_no ||
        uploadedImage
      ) {
        hasChanges = true;
        payload.attendantId = currentAttendant.attendant_id;
        if (editableDetails.name !== currentAttendant.attendant_name) {
          payload.name = editableDetails.name;
        }
        if (editableDetails.passportNo !== currentAttendant.passport_no) {
          payload.passportNo = editableDetails.passportNo;
        }
        if (uploadedImage) {
          payload.passportImage = uploadedImage;
        }
        handleSubmit(payload);
        setPassportError("");
      }
    }

    // Notify if no changes found
    if (!hasChanges) {
      alert("No changes found");
      return;
    }

    // Close modal after submission
    onClose();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImage(file);
      const payload = new FormData();
      payload.append("file", file);
      dispatch(fetchPassportDetailsRequest(payload));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent maxH="90vh" overflowY="scroll">
        <ModalHeader color="primary.1" textAlign={"center"} fontSize={"2xl"}>
          {isPatientPassport
            ? "Patient Passport"
            : `Attender ${attendantIndex + 1}/${attendantDetails.length}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="end" align="end">
            <PrimaryButton
              // mr="10px"
              mb="10px"
              onClick={() => {
                const input = document.createElement("input");
                input.type = "file";
                input.accept = ".pdf,.jpg,.jpeg,.png";
                input.onchange = handleImageUpload;
                input.click();
              }}
              isDisabled={
                trDetails.vil_status === "Not Requested" ? false : true
              }
            >
              Update Passport
            </PrimaryButton>
          </Flex>
          <Box display="flex" alignItems="center" justifyContent="center">
            {/* {!isPatientPassport && attendantDetails.length > 1 && (
              <IconButton
                icon={<ChevronLeftIcon boxSize="24px" />} // Adjust size here
                aria-label="Previous Attendant"
                onClick={previousAttendant}
                isDisabled={attendantIndex === 0}
                position="absolute"
                left="10px"
                backgroundColor="transparent"
                border="none"
                _hover={{ backgroundColor: "transparent" }}
                _active={{ backgroundColor: "transparent" }}
                _focus={{ boxShadow: "none" }}
                boxSize="60px" // Adjust overall button size if needed
              />
            )} */}
            {(editableDetails.passportImage || uploadedImage) && (
              <Iframe
                height="250px"
                width="100%"
                src={
                  uploadedImage
                    ? URL.createObjectURL(uploadedImage)
                    : editableDetails.passportImage
                }
                // src={editableDetails.passportImage}
                alt="Passport"
              />
            )}
            {/* {!isPatientPassport && attendantDetails.length > 1 && (
              <IconButton
                icon={<ChevronRightIcon />}
                aria-label="Next Attendant"
                onClick={nextAttendant}
                isDisabled={attendantIndex === attendantDetails.length - 1}
                position="absolute"
                right="10px"
              />
            )} */}
            {!isPatientPassport && attendantDetails.length > 1 && (
              <>
                <Icon
                  as={ChevronLeftIcon}
                  boxSize="34px" // Adjust size here
                  position="absolute"
                  left="-4px"
                  top="35%"
                  transform="translateY(-50%)"
                  cursor="pointer"
                  color={attendantIndex === 0 ? "gray.500" : "blue.500"}
                  onClick={previousAttendant}
                  _hover={{ color: "blue.700" }}
                  isDisabled={attendantIndex === 0}
                />

                {/* Next Icon */}
                <Icon
                  as={ChevronRightIcon}
                  boxSize="34px" // Adjust size here
                  position="absolute"
                  right="-4px"
                  top="35%"
                  transform="translateY(-50%)"
                  cursor="pointer"
                  color={
                    attendantIndex === attendantDetails.length - 1
                      ? "gray.500"
                      : "blue.500"
                  }
                  onClick={nextAttendant}
                  _hover={{ color: "blue.700" }}
                  isDisabled={attendantIndex === attendantDetails.length - 1}
                />
              </>
            )}
          </Box>
          <Box mb={4} mt="10px">
            <PrimaryText mb={1} fontWeight="bold">
              Full Name
            </PrimaryText>
            <Input
              name="name"
              value={editableDetails.name}
              onChange={handleInputChange}
              placeholder="Full Name"
              // variant="filled"
            />
          </Box>
          <Box mb={4}>
            <PrimaryText mb={1} fontWeight="bold">
              Passport No
            </PrimaryText>
            <FormControl isInvalid={passportError}>
              <Input
                name="passportNo"
                value={editableDetails.passportNo || ""}
                onChange={handleInputChange}
                borderColor={passportError ? "red.500" : "gray.300"} // Highlight with red on error
                focusBorderColor={passportError ? "red.500" : "blue.500"} // Highlight when focused
              />
              {passportError && (
                <FormErrorMessage>{passportError}</FormErrorMessage>
              )}
            </FormControl>
          </Box>
          {isPatientPassport && (
            <>
              <Box mb={4}>
                <PrimaryText mb={1} fontWeight="bold">
                  Nationality
                </PrimaryText>
                <Select
                  id="nationality"
                  name="nationality"
                  value={editableDetails.nationality}
                  onChange={(e) => handleSelectChange(e)}
                  placeholder="Nationality"
                  options={countryData}
                  classNamePrefix="chakra-react-select"
                  // variant="filled"
                />
              </Box>

              <Box mb={4}>
                <PrimaryText mb={1} fontWeight="bold">
                  DOB
                </PrimaryText>
                <Input
                  name="dob"
                  value={editableDetails.dob}
                  onChange={handleInputChange}
                  placeholder="DOB"
                  type="date"
                  // variant="filled"
                />
              </Box>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <PrimaryButton
            colorScheme="blue"
            ml="auto"
            onClick={handleUpdate}
            isDisabled={trDetails.vil_status === "Not Requested" ? false : true}
          >
            Update
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PassportModal;
