import { put, all, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import {
  HIDE_LOADER,
  SHOW_LOADER,
  apiError,
  exceptionAPIError,
  exceptionError,
  exceptionSessionExpire,
} from "../../authentication/authStore/actions";
import { UPDATE_TR_DETAILS_REQUEST, updateTrDetailsSuccess } from "./actions";

function* updateTrDetailsWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.update_tr_details", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(updateTrDetailsSuccess(response));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

export default function* commonSaga() {
  yield all([takeLatest(UPDATE_TR_DETAILS_REQUEST, updateTrDetailsWorker)]);
}
