import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

export const RequireAuth = ({ allowedRoles }) => {
  const { isAuthenticated, authUser } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    authUser: state.auth.authUser,
  }));
  const location = useLocation();

  const hasPermission = () => {
    return (
      authUser &&
      authUser.role &&
      authUser.role.find((role) => allowedRoles.includes(role))
    );
  };

  return isAuthenticated ? (
    <>
      {hasPermission() ? (
        <Outlet />
      ) : (
        <Navigate
          to="/unauthorized"
          state={location.state ? { from: location.state.from } : null}
          replace
        />
      )}
    </>
  ) : (
    <Navigate
      to="/"
      state={location.state ? { from: location.state.from } : null}
      replace
    />
  );
};
