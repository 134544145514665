export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const SESSION_EXPIRE_ERROR = "SESSION_EXPIRE_ERROR";
export const EXCEPTION_ERROR = "EXCEPTION_ERROR";
export const RESET_EXCEPTION_ERROR = "RESET_EXCEPTION_ERROR";
export const SESSION_API_ERROR = "SESSION_API_ERROR";
export const API_ERROR = "API_ERROR";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_ERROR = "RESEND_OTP_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const FETCH_DESIGNATION_REQUEST = "FETCH_DESIGNATION_REQUEST";
export const FETCH_DESIGNATION_SUCCESS = "FETCH_DESIGNATION_SUCCESS";
export const FETCH_DESIGNATION_ERROR = "FETCH_DESIGNATION_ERROR";

export const FETCH_PROFILE_DETAILS_REQUEST = "FETCH_PROFILE_DETAILS_REQUEST";
export const FETCH_PROFILE_DETAILS_SUCCESS = "FETCH_PROFILE_DETAILS_SUCCESS";
export const FETCH_PROFILE_DETAILS_ERROR = "FETCH_PROFILE_DETAILS_ERROR";
export const FETCH_PROFILE_DETAILS_POST_SUCCESS =
  "FETCH_PROFILE_DETAILS_POST_SUCCESS";
export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const VERIFY_USER_OTP_REQUEST = "VERIFY_USER_OTP_REQUEST";
export const VERIFY_USER_OTP_SUCCESS = "VERIFY_USER_OTP_SUCCESS";
export const VERIFY_USER_OTP_FAILURE = "VERIFY_USER_OTP_FAILURE";

export const VERIFY_REGISTER_OTP_SUCCESS = "VERIFY_REGISTER_OTP_SUCCESS";

export const FETCH_UNAUTHENTICATED_HOSPITALS_REQUEST =
  "FETCH_UNAUTHENTICATED_HOSPITALS_REQUEST";
export const FETCH_HOSPITALS_SUCCESS = "FETCH_HOSPITALS_SUCCESS";
export const FETCH_HOSPITALS_ERROR = "FETCH_HOSPITALS_ERROR";

export const exceptionSessionExpire = (response, test) => {
  return {
    type: SESSION_EXPIRE_ERROR,
    sessionError: response,
    test: test,
  };
};

export const exceptionAPIError = (response) => {
  return {
    type: SESSION_API_ERROR,
    apiError: response,
  };
};

export const exceptionError = (response) => {
  return {
    type: EXCEPTION_ERROR,
    ISError: response,
  };
};

export const apiError = (response) => {
  return { type: API_ERROR, payload: response };
};

export const resetErrors = () => {
  return { type: RESET_EXCEPTION_ERROR };
};

export const loginRequest = (payload) => {
  return {
    type: LOGIN_REQUEST,
    payload,
  };
};

export const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

export const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    payload: response,
  };
};

export const loginFailure = (payload) => {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
};

export const registerRequest = (payload) => {
  return {
    type: REGISTER_REQUEST,
    payload,
  };
};

export const registerSuccess = (response) => {
  return {
    type: REGISTER_SUCCESS,
    payload: response,
  };
};

export const registerFailure = (payload) => {
  return {
    type: REGISTER_FAILURE,
    payload,
  };
};

export function verifyOTPRequest(payload) {
  return {
    type: VERIFY_OTP_REQUEST,
    payload,
  };
}

export function verifyOTPError(payload) {
  return {
    type: VERIFY_OTP_ERROR,
    payload,
  };
}

export function verifyOTPSuccess() {
  return {
    type: VERIFY_OTP_SUCCESS,
  };
}

export function resendOTPRequest(payload) {
  return {
    type: RESEND_OTP_REQUEST,
    payload,
  };
}

export function resendOTPError(payload) {
  return {
    type: RESEND_OTP_ERROR,
    payload,
  };
}

export function resendOTPSuccess(payload) {
  return {
    type: RESEND_OTP_SUCCESS,
    payload,
  };
}

export const forgotPasswordRequest = (payload) => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload,
  };
};

export const forgotPasswordSuccess = (response) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: response,
  };
};

export const forgotPasswordFailure = (payload) => {
  return {
    type: FORGOT_PASSWORD_ERROR,
    payload,
  };
};

export const resetPasswordRequest = (payload) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload,
  };
};

export const resetPasswordSuccess = (response) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: response,
  };
};

export const resetPasswordFailure = (payload) => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload,
  };
};

export const fetchProfileDetailsRequest = (payload) => {
  return {
    type: FETCH_PROFILE_DETAILS_REQUEST,
    payload,
  };
};

export const fetchProfileDetailsPostSuccess = (payload) => {
  return {
    type: FETCH_PROFILE_DETAILS_POST_SUCCESS,
    payload,
  };
};

export const fetchProfileDetailsSuccess = (payload) => {
  return {
    type: FETCH_PROFILE_DETAILS_SUCCESS,
    payload,
  };
};

export const fetchProfileDetailsFailure = (payload) => {
  return {
    type: FETCH_PROFILE_DETAILS_ERROR,
    payload,
  };
};

export const fetchDesignationRequest = (payload) => {
  return {
    type: FETCH_DESIGNATION_REQUEST,
    payload,
  };
};

export const fetchDesignationSuccess = (payload) => {
  return {
    type: FETCH_DESIGNATION_SUCCESS,
    payload,
  };
};

export const fetchDesignationFailure = (payload) => {
  return {
    type: FETCH_DESIGNATION_ERROR,
    payload,
  };
};

export function sendOTPRequest(payload) {
  return {
    type: SEND_OTP_REQUEST,
    payload,
  };
}

export function sendOTPSuccess(payload) {
  return {
    type: SEND_OTP_SUCCESS,
    payload: payload,
  };
}

export function sendOTPFailure(payload) {
  return {
    type: SEND_OTP_FAILURE,
    payload,
  };
}

export function verifyUserOTPRequest(payload) {
  return {
    type: VERIFY_USER_OTP_REQUEST,
    payload,
  };
}

export function verifyUserOTPSuccess(payload) {
  return {
    type: VERIFY_USER_OTP_SUCCESS,
    payload: payload,
  };
}

export function verifyUserOTPFailure(payload) {
  return {
    type: VERIFY_USER_OTP_FAILURE,
    payload,
  };
}

export function verifyRegOtpSuccess(payload) {
  return {
    type: VERIFY_REGISTER_OTP_SUCCESS,
    payload: payload,
  };
}

export const fetchHospitalsRequest = (payload) => {
  return {
    type: FETCH_UNAUTHENTICATED_HOSPITALS_REQUEST,
    payload,
  };
};

export const fetchHospitalsSuccess = (payload) => {
  return {
    type: FETCH_HOSPITALS_SUCCESS,
    payload,
  };
};

export const fetchHospitalsFailure = (payload) => {
  return {
    type: FETCH_HOSPITALS_ERROR,
    payload,
  };
};
