import { put, all, takeLatest } from "redux-saga/effects";
import {
  FETCH_HOSPITALS_REQUEST,
  fetchHospitalsSuccess,
  // fetchHospitalsFailure,
  fetchCountrySuccess,
  // fetchCountryFailure,
  FETCH_COUNTRY_MASTER_REQUEST,
  // fetchHealthConcernFailure,
  fetchHealthConcernSuccess,
  FETCH_HEALTHCONCERN_REQUEST,
  FETCH_PASSPORT_DETAILS_REQUEST,
  fetchPassportDetailsSuccess,
  // fetchPassportDetailsFailure,
  MEDICALOPINION_REQUEST,
  VIL_REQUEST,
  FLIGHT_TICKET_REQUEST,
  flightTicketsSuccess,
  // flightTicketsFailure,
  // vilFailure,
  vilSuccess,
  // medicalOpinionFailure,
  medicalOpinionSuccess,
  fetchExistingPatientsSuccess,
  // fetchExistingPatientsFailure,
  FETCH_EXISTING_PATIENTS_REQUEST,
  FETCH_TR_DETAILS_REQUEST,
  fetchTrDetailsRequestSuccess,
  // fetchTrDetailsRequestFailure,
  fetchEmbassySuccess,
  FETCH_DOCTOR_REQUEST,
  // fetchEmbassyFailure,
  fetchDoctorSuccess,
  // fetchDoctorFailure,
  FETCH_EMBASSY_REQUEST,
  FETCH_DOCTOR_SPECIALTY_REQUEST,
  // fetchDoctorSpecialtyFailure,
  fetchHospitalSpecialtySuccess,
  // fetchHospitalSpecialtyFailure,
  FETCH_HOSPITAL_SPECIALTY_REQUEST,
  FETCH_DASHBOARD_SUMMARY_REQUEST,
  fetchDashboardSummarySuccess,
  // fetchDashboardSummaryFailure,
  fetchDashboardSummaryDetailsSuccess,
  fetchDoctorSpecialtySuccess,
  FETCH_AIRLINES_DETAILS_REQUEST,
  fetchAirlinesDetailSuccess,
  FETCH_AIRPORT_DETAILS_REQUEST,
  fetchFromAirportDetailSuccess,
  fetchToAirportDetailSuccess,
  fetchPreferredCountryMasterSuccess,
  fetchPreferredCountrySuccess,
  FETCH_PREFERRED_COUNTRY_REQUEST,
  fetchHospitalsMasterSuccess,
  fetchHealthConcernMasterSuccess,
  fetchDoctorMasterSuccess,
  FETCH_TRIDS_REQUEST,
  fetchTridsSuccess,
  VERIFY_EXISTING_PATIENT_REQUEST,
  verifyExistingPatientSuccess,
} from "./actions";
import api from "../../../config/api";
import {
  HIDE_LOADER,
  SHOW_LOADER,
  apiError,
  exceptionAPIError,
  exceptionError,
  exceptionSessionExpire,
} from "../../authentication/authStore/actions";

function* hospitalDataWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  let response;
  try {
    if (payload) {
      response = yield api.get("qms.api.hospitals", payload, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    } else {
      var data = {};
      response = yield api.get("qms.api.hospitals", data, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    }
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (payload.is_master) {
          yield put(fetchHospitalsMasterSuccess(response.data));
        } else {
          yield put(fetchHospitalsSuccess(response.data));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* countryDataWorker({}) {
  var data = {};
  try {
    let response = yield api.get("qms.api.countries", data, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchCountrySuccess(response));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

function* healthConcernDataWorker({ payload }) {
  // var data = {};
  try {
    let response = yield api.get("qms.api.health_concerns", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (payload.is_master) {
          yield put(fetchHealthConcernMasterSuccess(response.data));
        } else {
          yield put(fetchHealthConcernSuccess(response.data));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

function* fetchEmbassyWorker({}) {
  var data = {};
  try {
    let response = yield api.get("qms.api.embassy", data, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchEmbassySuccess(response.data));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

function* fetchDoctorsWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.get("qms.api.doctors", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (payload.is_master) {
          yield put(fetchDoctorMasterSuccess(response.data));
        } else {
          yield put(fetchDoctorSuccess(response.data));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchDoctorSpecialtyWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.get("qms.api.doctor_specialty", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchDoctorSpecialtySuccess(response.data));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchHospitalSpecialtyWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.get("qms.api.hospital_specialty", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchHospitalSpecialtySuccess(response.data));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* passportDetailsWorker({ payload, passportLabel }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.passport_details", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (passportLabel) {
          yield put(fetchPassportDetailsSuccess(response.data, passportLabel));
        } else {
          passportLabel = "Patient";
          yield put(fetchPassportDetailsSuccess(response.data, passportLabel));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* createMedicalOpinionWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.medical_opinion", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(medicalOpinionSuccess(response));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* createVILWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.vil", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(vilSuccess(response));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* createFlightTickets({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.flight_tickets", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(flightTicketsSuccess(response));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchExistingPatientsWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.fetch_existing_patients", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchExistingPatientsSuccess(response));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchTrDetailsWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.get("qms.api.fetch_tr_details", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchTrDetailsRequestSuccess(response.data));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchDashboardSummaryWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response;
    if (payload.summary || payload.filters) {
      response = yield api.post("qms.api.fetch_dashboard_summary", payload, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    } else {
      let payload = {};
      response = yield api.post("qms.api.fetch_dashboard_summary", payload, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    }
    if (response.ok) {
      response = response.data;

      if (response.status === 200) {
        if (payload.summary) {
          yield put(fetchDashboardSummaryDetailsSuccess(response.data));
        } else {
          yield put(fetchDashboardSummarySuccess(response.data));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchAirlineDetailsWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  let response;
  try {
    response = yield api.get("qms.api.fetch_airlines", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });

    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchAirlinesDetailSuccess(response.data));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchAirportDetailsWorker({ payload, label }) {
  yield put({ type: SHOW_LOADER });
  let response;
  try {
    if (payload) {
      response = yield api.get("qms.api.fetch_airports", payload, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    } else {
      var data = {};
      response = yield api.get("qms.api.fetch_airports", data, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
    }
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (label.includes("FromAirport")) {
          yield put(fetchFromAirportDetailSuccess(response.data, label));
        } else {
          yield put(fetchToAirportDetailSuccess(response.data));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchPreferredCountryWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  let response;
  try {
    response = yield api.get("qms.api.fetch_preferred_countries", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });

    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (payload.is_master) {
          yield put(fetchPreferredCountryMasterSuccess(response.data));
        } else {
          yield put(fetchPreferredCountrySuccess(response.data));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchTridDataWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.get("qms.api.fetch_tr_ids", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        yield put(fetchTridsSuccess(response.data));
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

function* verifyExistingPatientWorker({ payload }) {
  yield put({ type: SHOW_LOADER });
  try {
    let response = yield api.post("qms.api.verify_patient", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });
    if (response.ok) {
      response = response.data;
      if (response.status === 409) {
        yield put(verifyExistingPatientSuccess(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

export default function* hcfSaga() {
  yield all([
    takeLatest(FETCH_HOSPITALS_REQUEST, hospitalDataWorker),
    takeLatest(FETCH_COUNTRY_MASTER_REQUEST, countryDataWorker),
    takeLatest(FETCH_HEALTHCONCERN_REQUEST, healthConcernDataWorker),
    takeLatest(FETCH_EMBASSY_REQUEST, fetchEmbassyWorker),
    takeLatest(FETCH_DOCTOR_REQUEST, fetchDoctorsWorker),
    takeLatest(FETCH_DOCTOR_SPECIALTY_REQUEST, fetchDoctorSpecialtyWorker),
    takeLatest(FETCH_HOSPITAL_SPECIALTY_REQUEST, fetchHospitalSpecialtyWorker),
    takeLatest(FETCH_PASSPORT_DETAILS_REQUEST, passportDetailsWorker),
    takeLatest(MEDICALOPINION_REQUEST, createMedicalOpinionWorker),
    takeLatest(VIL_REQUEST, createVILWorker),
    takeLatest(FLIGHT_TICKET_REQUEST, createFlightTickets),
    takeLatest(FETCH_EXISTING_PATIENTS_REQUEST, fetchExistingPatientsWorker),
    takeLatest(FETCH_TR_DETAILS_REQUEST, fetchTrDetailsWorker),
    takeLatest(FETCH_DASHBOARD_SUMMARY_REQUEST, fetchDashboardSummaryWorker),
    takeLatest(FETCH_AIRLINES_DETAILS_REQUEST, fetchAirlineDetailsWorker),
    takeLatest(FETCH_AIRPORT_DETAILS_REQUEST, fetchAirportDetailsWorker),
    takeLatest(FETCH_PREFERRED_COUNTRY_REQUEST, fetchPreferredCountryWorker),
    takeLatest(FETCH_TRIDS_REQUEST, fetchTridDataWorker),
    takeLatest(VERIFY_EXISTING_PATIENT_REQUEST, verifyExistingPatientWorker),
  ]);
}
