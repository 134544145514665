import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "chakra-react-select";
import { Box, Grid, Input, FormControl, Text } from "@chakra-ui/react";

import PrimaryButton from "../../../components/PrimaryButton";
import PrimaryFormLabel from "../../../components/PrimaryFormLabel";
import { Days, genderData } from "../constants";
import { formatDate } from "../form/utils";
import { filtersPayload } from "../../utils";

const Filters = ({ onSearch }) => {
  const dispatch = useDispatch();

  const [country, setCountry] = useState(null);
  const [healthConcern, setHealthConcern] = useState(null);
  const [hospital, setHospital] = useState(null);
  const [gender, setGender] = useState(null);
  const [daysFilter, setDaysFilter] = useState("custom");
  const [customDateRange, setCustomDateRange] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateError, setDateError] = useState("");

  const { hospitalMaster, healthConcernMaster, countryData } = useSelector(
    (state) => state.hcf
  );

  // useEffect(() => {
  //   if (hospitalData) {
  //     setHospital(hospitalData[0]);
  //   }
  // }, [hospitalData]);

  // useEffect(() => {
  //   if (healthConcernData) {
  //     setHealthConcern(healthConcernData[0]);
  //   }
  // }, [healthConcernData]);

  // useEffect(() => {
  //   if (countryData) {
  //     setCountry(countryData[0]);
  //   }
  // }, [countryData]);

  useEffect(() => {
    const today = new Date();
    const fifteenDaysAgo = new Date(today);
    fifteenDaysAgo.setDate(today.getDate() - 15);
    setFromDate(fifteenDaysAgo.toISOString().split("T")[0]);
    setToDate(today.toISOString().split("T")[0]);
  }, [customDateRange]);

  const handleDaysFilterChange = (selectedOption) => {
    if (selectedOption) {
      setDaysFilter(selectedOption.value);
      if (selectedOption.value === "custom") {
        setCustomDateRange(true);
      } else {
        setCustomDateRange(false);
      }
    }
  };

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);

    if (customDateRange && toDate < selectedFromDate) {
      setDateError("Please select a valid date range.");
    } else {
      setDateError("");
    }
  };

  const handleSearch = () => {
    if (dateError) {
      return;
    }
    const filters = filtersPayload(
      healthConcern,
      hospital,
      gender,
      country,
      daysFilter,
      fromDate,
      toDate
    );
    onSearch(filters);
  };

  return (
    <Box
      ml="10px"
      p={4}
      shadow="md"
      borderWidth="1px"
      borderRadius="md"
      bgColor={"#ffff"}
      flex={{ base: "100%", md: "calc(50% - 10px)" }}
    >
      <Box
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
        mb="20px"
      >
        <FormControl mr="10px">
          <PrimaryFormLabel>Health Concern</PrimaryFormLabel>
          <Select
            isMulti
            placeholder="Select Health Concern"
            closeMenuOnSelect={false}
            options={healthConcernMaster}
            value={healthConcern}
            onChange={(selectedOption) => setHealthConcern(selectedOption)}
          />
        </FormControl>

        <FormControl>
          <PrimaryFormLabel>Country</PrimaryFormLabel>
          <Select
            isMulti
            placeholder="Select Country"
            closeMenuOnSelect={false}
            options={countryData}
            value={country}
            onChange={(selectedOption) => setCountry(selectedOption)}
          />
        </FormControl>
      </Box>

      <Box
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
        mb="20px"
      >
        <FormControl mr="10px">
          <PrimaryFormLabel>Hospital</PrimaryFormLabel>
          <Select
            isMulti
            placeholder="Select Hospital"
            closeMenuOnSelect={false}
            options={hospitalMaster}
            value={hospital}
            onChange={(selectedOption) => setHospital(selectedOption)}
          />
        </FormControl>

        <FormControl>
          <PrimaryFormLabel>Gender</PrimaryFormLabel>
          <Select
            isMulti
            placeholder="Select Gender"
            closeMenuOnSelect={false}
            options={genderData}
            value={gender}
            onChange={(selectedOption) => setGender(selectedOption)}
          />
        </FormControl>
      </Box>

      <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4}>
        <FormControl>
          <PrimaryFormLabel>Days</PrimaryFormLabel>
          <Select
            value={Days.find((day) => day.value === daysFilter)}
            options={Days}
            onChange={(selectedOption) =>
              handleDaysFilterChange(selectedOption)
            }
          />
        </FormControl>

        {customDateRange && (
          <>
            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4}>
              <FormControl>
                <PrimaryFormLabel>From Date</PrimaryFormLabel>
                <Input
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  value={fromDate}
                  onChange={handleFromDateChange}
                />
                {dateError && <Text color="red.500">{dateError}</Text>}
              </FormControl>

              <FormControl>
                <PrimaryFormLabel>To Date</PrimaryFormLabel>
                <Input
                  type="date"
                  min={fromDate}
                  max={new Date().toISOString().split("T")[0]}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <PrimaryButton onClick={handleSearch}>Search</PrimaryButton>
      </div>
    </Box>
  );
};

export default Filters;
