import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  Input,
  Stack,
  Text,
  useDisclosure,
  Link,
  Flex,
  IconButton,
  FormErrorMessage,
} from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import PrimaryButton from "../../components/PrimaryButton";
import BrandedHeading from "../../components/BrandedHeading";
import OtpModal from "./components/OtpModal";
import {
  fetchHospitalsRequest,
  registerRequest,
  verifyOTPRequest,
} from "./authStore/actions";
import { useDispatch, useSelector } from "react-redux";
import { PasswordField } from "./components/PasswordField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ErrorAlert from "../../components/ErrorAlert";
import validator from "validator";
import AlphaInput from "../../components/AlphaInput";
import { fetchDesignationRequest } from "./authStore/actions";
import { Select } from "chakra-react-select";
import { handleSelectChange } from "../hcf/form/utils";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/LoadingSpinner";
import "../../layout/base.css";
import { companyTypes, roleOptions } from "../hcf/constants";

export const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    is_reg,
    error,
    regOtpResponse,
    designationData,
    hospitalsData,
    loading,
  } = useSelector((state) => state.auth);
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [phoneValid, setPhoneValid] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    role: "",
    company: "",
    designation: "",
    hospital: "",
    companyType: "",
  });

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [formData]);

  const phoneLengths = {
    BD: { min: 10, max: 10 },
    KE: { min: 10, max: 10 },
    SO: { min: 7, max: 8 },
    IQ: { min: 10, max: 10 },
    ET: { min: 9, max: 9 },
    MU: { min: 8, max: 8 },
    NG: { min: 8, max: 8 },
    OM: { min: 7, max: 8 },
    TZ: { min: 6, max: 6 },
    MM: { min: 8, max: 10 },
    YE: { min: 9, max: 9 },
    UZ: { min: 9, max: 9 },
    NP: { min: 10, max: 10 },
    MZ: { min: 12, max: 12 },
    TM: { min: 8, max: 8 },
  };

  useEffect(() => {
    refreshCaptcha();
    // dispatch(fetchDesignationRequest());
    dispatch(fetchHospitalsRequest());
  }, []);

  useEffect(() => {
    if (is_reg) {
      onOpen();
    }
  }, [is_reg]);

  useEffect(() => {
    if (regOtpResponse) {
      onClose();
      setIsAlertOpen(true);
    } else {
      setIsAlertOpen(false);
    }
  }, [regOtpResponse]);

  useEffect(() => {
    if (validator.isEmail(formData.email)) {
      if (formData.email && formData.email.length > 0) {
        const role = identifyRole(formData.email);
        setFormData((prev) => ({
          ...prev,
          role: role,
          company: "",
          designation: "",
        }));
        let payload = { role: role };
        dispatch(fetchDesignationRequest(payload));
      }
    }
  }, [formData.email, dispatch]);

  useEffect(() => {
    if (formData.companyType.value === "Individual") {
      setFormData((prevData) => ({ ...prevData, company: "" }));
    }
  }, [formData.companyType]);

  const identifyRole = (email) => {
    const domain = email.split("@")[1];
    if (domain === "apollohospital.com") {
      return "IPS";
    } else {
      return "HCF";
    }
  };

  const validatePhoneNumber = (
    value,
    country,
    dialCode,
    name,
    setFormErrors
  ) => {
    const countryCode = country?.toUpperCase();
    if (countryCode && phoneLengths[countryCode]) {
      const { min, max } = phoneLengths[countryCode];
      const length = value.replace(/\D/g, "").length;
      const min_length =
        min + (dialCode ? dialCode.replace(/\D/g, "").length : 0);
      const max_length =
        max + (dialCode ? dialCode.replace(/\D/g, "").length : 0);

      if (length > max_length) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phone: `Phone number length for ${name} should be maximum ${max} digits.`,
        }));
        return false;
      } else if (length < min_length) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phone: `Phone number length for ${name} should be minimum ${min} digits.`,
        }));
        return false;
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phone: "",
        }));
        return true;
      }
    }
    return true;
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "This field is required";
    } else if (values.name.length < 3) {
      errors.name = "Name must be at least 3 characters";
    }
    if (!values.email) {
      errors.email = "This field is required";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Please enter valid Email address";
    }
    // if (!values.phone) {
    //   errors.phone = "This field is required";
    // }
    if (formErrors.phone === undefined && values.phone === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: "This field is required",
      }));
      errors.phone = "This field is required";
    } else {
      if (formErrors.phone && formErrors.phone !== "") {
        errors.phone = formErrors.phone;
      }
    }

    if (!values.password) {
      errors.password = "This field is required";
    } else if (!validatePassword(values.password)) {
      errors.password =
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";
    }
    if (!values.confirm_password) {
      errors.confirm_password = "This field is required";
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Password do not match";
    }
    // if (!values.role) {
    //   errors.role = "This field is required";
    // }
    if (isAppoloHospitalEmail(values.email) && !values.role) {
      errors.role = "This field is required";
    }
    if (isAppoloHospitalEmail(values.email) && !values.hospital) {
      errors.hospital = "This field is required";
    } else if (
      !isAppoloHospitalEmail(values.email) &&
      values.companyType.value !== "Individual" &&
      !values.company
    ) {
      errors.company = "This field is required";
    }
    if (!isAppoloHospitalEmail(values.email) && !values.companyType) {
      errors.companyType = "This field is required";
    }
    if (!values.designation) {
      errors.designation = "This field is required";
    }
    if (!values.checkbox) {
      errors.checkbox =
        "Please agree to the Terms of Service and Privacy Policy";
    }
    return errors;
  };

  const isAppoloHospitalEmail = (email) => {
    if (email) {
      return email?.endsWith("@apollohospital.com");
    } else {
      return false;
    }
  };

  const handlePhoneChange = (val, country) => {
    setFormData({ ...formData, phone: val });
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/;
    return regex.test(password);
  };

  function generateCaptcha() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const refreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setUserInput("");
    setErrorMessage("");
  };

  const onSubmit = () => {
    const errors = validateForm(formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const { password, confirm_password } = formData;
      if (!validatePassword(password)) {
        setPasswordValidation(true);
        return;
      }

      if (password !== confirm_password) {
        setPasswordMismatchError(true);
        return;
      }

      if (userInput !== captcha) {
        setErrorMessage("Incorrect CAPTCHA. Please try again.");
        return;
      }

      const payload = {
        name: formData.name,
        email: formData.email,
        mobile_no: formData.phone,
        pwd: formData.password,
        repwd: formData.password,
        ...(isAppoloHospitalEmail(formData.email)
          ? { hospital: formData.hospital }
          : { company: formData.company }),
        designation: formData.designation?.value,
        company_type: formData.companyType?.value,
      };
      dispatch(registerRequest(payload));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleOtpSubmit = (otp) => {
    let payload = {
      usr: formData.email,
      otp: otp,
      otp_type: "Reg",
    };
    dispatch(verifyOTPRequest(payload));
  };

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      {loading && <LoadingSpinner />}
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow="5px 5px 30px 0px rgb(224 222 222 / 89%)"
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <Stack spacing="1" textAlign="center">
                  <BrandedHeading fontWeight="700">Registration</BrandedHeading>
                </Stack>
                <Stack spacing="1">
                  <BrandedHeading fontWeight="600" as="h2" size="md">
                    New Here? Set up your Profile
                  </BrandedHeading>
                  <Text fontSize="xs">
                    Create an account so you can manage your services faster
                  </Text>
                </Stack>
              </Stack>
              <Stack spacing="6">
                <FormControl isInvalid={!!formErrors.name}>
                  <AlphaInput
                    placeholder="Enter Your Full Name"
                    value={formData["name"]}
                    onChange={handleChange}
                    name="name"
                    isInvalid={!!formErrors.name}
                    regex={/^[a-zA-Z ]*$/}
                    maxLength={40}
                  />
                  <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formErrors.email}>
                  <Input
                    id="email"
                    placeholder="Enter Email"
                    value={formData["email"]}
                    onChange={handleChange}
                    name="email"
                    required
                  />
                  <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formErrors.phone || !phoneValid}>
                  <PhoneInput
                    id="mobileNumber"
                    name="mobileNumber"
                    preferredCountries={[
                      "bd",
                      "ke",
                      "so",
                      "iq",
                      "et",
                      "mu",
                      "ng",
                      "om",
                      "tz",
                      "mm",
                      "ye",
                      "uz",
                      "np",
                      "mz",
                      "Tm",
                    ]}
                    country={formData.country}
                    value={formData.phone}
                    onChange={(value, country) => {
                      setFormData({
                        ...formData,
                        phone: value,
                        countryCode: country.dialCode,
                      });
                      const isValid = validatePhoneNumber(
                        value,
                        country.countryCode,
                        country.dialCode,
                        country.name,
                        setFormErrors,
                        formErrors
                      );
                      setPhoneValid(isValid);
                      handlePhoneChange(value, country);
                    }}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      border: formErrors.phone
                        ? "2px solid red !important"
                        : "",
                    }}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    enableSearch="true"
                    international
                    isInvalid={!!formErrors.phone}
                    placeholder="Enter Phone Number"
                    countryCodeEditable={false}
                  />

                  <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={passwordValidation || !!formErrors.password}
                >
                  <PasswordField
                    id="password"
                    placeholder="Enter Password"
                    value={formData["password"]}
                    onChange={handleChange}
                    isInvalid={passwordValidation || !!formErrors.password}
                    name="password"
                    required
                  />
                  <FormErrorMessage>{formErrors.password}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    passwordMismatchError || !!formErrors.confirm_password
                  }
                >
                  <PasswordField
                    id="confirm_password"
                    placeholder="Confirm Password"
                    value={formData["confirm_password"]}
                    onChange={handleChange}
                    isInvalid={
                      passwordMismatchError || !!formErrors.confirm_password
                    }
                    name="confirm_password"
                    required
                  />
                  <FormErrorMessage>
                    {formErrors.confirm_password}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!formErrors.role}
                  display={
                    isAppoloHospitalEmail(formData.email) ? "block" : "none"
                  }
                >
                  <Select
                    name="role"
                    id="role"
                    placeholder="Select Role"
                    options={roleOptions}
                    value={formData.role}
                    menuPortalTarget={document.body}
                    classNamePrefix="chakra-react-select"
                    onChange={(selectedOption, actionMeta) =>
                      handleSelectChange(
                        formData,
                        setFormData,
                        formErrors,
                        setFormErrors,
                        selectedOption,
                        actionMeta
                      )
                    }
                  />
                  <FormErrorMessage>{formErrors.role}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!formErrors.hospital}
                  display={
                    isAppoloHospitalEmail(formData.email) ? "block" : "none"
                  }
                >
                  <Select
                    name="hospital"
                    id="hospital"
                    placeholder="Select Hospital"
                    options={hospitalsData?.data}
                    value={formData.hospital}
                    menuPortalTarget={document.body}
                    classNamePrefix="chakra-react-select"
                    onChange={(selectedOption, actionMeta) =>
                      handleSelectChange(
                        formData,
                        setFormData,
                        formErrors,
                        setFormErrors,
                        selectedOption,
                        actionMeta
                      )
                    }
                  />
                  <FormErrorMessage>{formErrors.hospital}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!formErrors.companyType}
                  display={
                    !isAppoloHospitalEmail(formData.email) ? "block" : "none"
                  }
                >
                  <Select
                    name="companyType"
                    id="companyType"
                    placeholder="Select Company Type"
                    options={companyTypes}
                    value={formData.companyType}
                    menuPortalTarget={document.body}
                    classNamePrefix="chakra-react-select"
                    onChange={(selectedOption, actionMeta) =>
                      handleSelectChange(
                        formData,
                        setFormData,
                        formErrors,
                        setFormErrors,
                        selectedOption,
                        actionMeta
                      )
                    }
                  />
                  <FormErrorMessage>{formErrors.companyType}</FormErrorMessage>
                </FormControl>
                {formData.companyType.value !== "Individual" && (
                  <FormControl
                    isInvalid={!!formErrors.company}
                    display={
                      isAppoloHospitalEmail(formData.email) ? "none" : "block"
                    }
                  >
                    <AlphaInput
                      placeholder="Company Name"
                      id="company"
                      value={formData["company"]}
                      onChange={handleChange}
                      name="company"
                      required
                      isInvalid={!!formErrors.company}
                      regex={/^[a-zA-Z ]*$/}
                      maxLength={40}
                    />
                    <FormErrorMessage>{formErrors.company}</FormErrorMessage>
                  </FormControl>
                )}

                <FormControl isInvalid={!!formErrors.designation}>
                  <Select
                    name="designation"
                    id="designation"
                    placeholder="Select Designation"
                    options={designationData?.data}
                    value={formData.designation}
                    menuPortalTarget={document.body}
                    classNamePrefix="chakra-react-select"
                    onChange={(selectedOption, actionMeta) =>
                      handleSelectChange(
                        formData,
                        setFormData,
                        formErrors,
                        setFormErrors,
                        selectedOption,
                        actionMeta
                      )
                    }
                  />
                  <FormErrorMessage>{formErrors.designation}</FormErrorMessage>
                </FormControl>
              </Stack>
              <Flex alignItems={"center"}>
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  letterSpacing="wider"
                  backgroundColor={"blue.600"}
                  color={"white"}
                >
                  {captcha}
                </Text>
                <IconButton
                  icon={<RepeatIcon />}
                  aria-label="Refresh captcha"
                  onClick={refreshCaptcha}
                  ml="1"
                  mr="3"
                />
                <Input
                  type="text"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  placeholder="Enter Captcha"
                  borderColor={errorMessage ? "red.500" : undefined}
                />
              </Flex>
              <Flex alignItems="flex-start" mt="-10px">
                {errorMessage && (
                  <Text color="red.500" fontSize="sm">
                    {errorMessage}
                  </Text>
                )}
              </Flex>
              <Flex alignItems="flex-start">
                <Checkbox
                  style={{ paddingTop: "4px" }}
                  isChecked={formData.checkbox}
                  onChange={(e) =>
                    setFormData({ ...formData, checkbox: e.target.checked })
                  }
                />
                <Text ml={3} flex="1">
                  By signing up, you agree to the Terms of Services and Privacy
                  Policy
                </Text>
              </Flex>
              <Flex alignItems="flex-start" mt="-20px">
                {formErrors.checkbox && (
                  <Text color="red.500" fontSize="sm">
                    {formErrors.checkbox}
                  </Text>
                )}
              </Flex>
              <Stack spacing="6">
                {error && error.message && (
                  <ErrorAlert message={error.message} />
                )}
                <PrimaryButton
                  onClick={onSubmit}
                  // isLoading={loading}
                  loadingText="Registering"
                  spinnerPlacement="end"
                >
                  Sign Up
                </PrimaryButton>
                <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                  <Text color="fg.muted">
                    Already have an account?{" "}
                    <Link href="/" style={{ color: "#57ACFF" }}>
                      Login Here
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
      <OtpModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleOtpSubmit}
        user={formData.email}
        otpType="Reg"
      />
      <Alert
        isOpen={isAlertOpen}
        title="Success!"
        type="success"
        onClose={() => {
          navigate("/");
          setIsAlertOpen(false);
        }}
        body={regOtpResponse?.message}
      />
    </Flex>
  );
};
