export const CONVERSATION_REQUEST = "CONVERSATION_REQUEST";
export const START_CONVERSATION_SUCCESS = "START_CONVERSATION_SUCCESS";
export const POST_USER_RESPONSE_SUCCESS = "POST_USER_RESPONSE_SUCCESS";

export const FETCH_MESSAGES_HISTORY_REQUEST = "FETCH_MESSAGES_HISTORY_REQUEST";
export const FETCH_ALL_MESSAGES_SUMMARY_SUCCESS =
  "FETCH_ALL_MESSAGES_HISTORY_SUCCESS";
export const FETCH_SINGLE_MESSAGE_HISTORY_SUCCESS =
  "FETCH_SINGLE_MESSAGE_HISTORY_SUCCESS";
export const SHOW_MESSAGE_LOADER = "SHOW_MESSAGE_LOADER";
export const HIDE_MESSAGE_LOADER = "HIDE_MESSAGE_LOADER";

export const conversationRequest = (payload) => {
  return {
    type: CONVERSATION_REQUEST,
    payload: payload,
  };
};

export const startConversationSuccess = (payload) => {
  return {
    type: START_CONVERSATION_SUCCESS,
    payload,
  };
};

export const postUserResponseSuccess = (payload) => {
  return {
    type: POST_USER_RESPONSE_SUCCESS,
    payload,
  };
};

export const fetchMessagesHistoryRequest = (payload) => {
  return {
    type: FETCH_MESSAGES_HISTORY_REQUEST,
    payload,
  };
};

export const fetchAllMessagesSummarySuccess = (payload) => {
  return {
    type: FETCH_ALL_MESSAGES_SUMMARY_SUCCESS,
    payload,
  };
};

export const fetchSingleMessageHistorySuccess = (payload) => {
  return {
    type: FETCH_SINGLE_MESSAGE_HISTORY_SUCCESS,
    payload,
  };
};
