import React from "react";
import { Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  //const location = useLocation();
  // const { state } = location;
  // const { from } = state;
  //navigate(from.pathname);

  return (
    <Center h="88vh">
      <Flex direction="column" alignItems="center">
        <Heading as="h2" size="xl">
          404 - Page Not Found
        </Heading>
        <Text fontSize="xl">
          Opps! The page you're looking for doesn't exist. It might have been
          moved or delete
        </Text>
        <Button onClick={() => navigate(-1)} mt="4" colorScheme="teal">
          Go Back
        </Button>
      </Flex>
    </Center>
  );
};

export default PageNotFound;
