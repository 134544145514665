export const FETCH_HOSPITALS_REQUEST = "FETCH_HOSPITALS_REQUEST";
export const FETCH_HOSPITALS_SUCCESS = "FETCH_HOSPITALS_SUCCESS";
export const FETCH_HOSPITALS_FAILURE = "FETCH_HOSPITALS_FAILURE";

export const FETCH_COUNTRY_MASTER_REQUEST = "FETCH_COUNTRY_MASTER_REQUEST";
export const FETCH_COUNTRY_MASTER_SUCCESS = "FETCH_COUNTRY_MASTER_SUCCESS";
export const FETCH_COUNTRY_MASTER_FAILURE = "FETCH_COUNTRY_MASTER_FAILURE";

export const FETCH_HEALTHCONCERN_REQUEST = "FETCH_HEALTHCONCERN_REQUEST";
export const FETCH_HEALTHCONCERN_SUCCESS = "FETCH_HEALTHCONCERN_SUCCESS";
export const FETCH_HEALTHCONCERN_ERROR = "FETCH_HEALTHCONCERN_ERROR";

export const FETCH_DOCTOR_REQUEST = "FETCH_DOCTOR_REQUEST";
export const FETCH_DOCTOR_SUCCESS = "FETCH_DOCTOR_SUCCESS";
export const FETCH_DOCTOR_FAILURE = "FETCH_DOCTOR_FAILURE";

export const FETCH_HOSPITAL_SPECIALTY_REQUEST =
  "FETCH_HOSPITAL_SPECIALTY_REQUEST";
export const FETCH_HOSPITAL_SPECIALTY_SUCCESS =
  "FETCH_HOSPITAL_SPECIALTY_SUCCESS";
export const FETCH_HOSPITAL_SPECIALTY_FAILURE =
  "FETCH_HOSPITAL_SPECIALTY_FAILURE";

export const FETCH_DOCTOR_SPECIALTY_REQUEST = "FETCH_DOCTOR_SPECIALTY_REQUEST";
export const FETCH_DOCTOR_SPECIALTY_SUCCESS = "FETCH_DOCTOR_SPECIALTY_SUCCESS";
export const FETCH_DOCTOR_SPECIALTY_ERROR = "FETCH_DOCTOR_SPECIALTY_ERROR";

export const FETCH_EMBASSY_REQUEST = "FETCH_EMBASSY_REQUEST";
export const FETCH_EMBASSY_SUCCESS = "FETCH_EMBASSY_SUCCESS";
export const FETCH_EMBASSY_ERROR = "FETCH_EMBASSY_ERROR";

export const FETCH_PASSPORT_DETAILS_REQUEST = "FETCH_PASSPORT_DETAILS_REQUEST";
export const FETCH_PASSPORT_DETAILS_SUCCESS = "FETCH_PASSPORT_DETAILS_SUCCESS";
export const FETCH_PASSPORT_DETAILS_ERROR = "FETCH_PASSPORT_DETAILS_ERROR";

export const MEDICALOPINION_REQUEST = "MEDICALOPINION_REQUEST";
export const MEDICALOPINION_SUCCESS = "MEDICALOPINION_SUCCESS";
export const MEDICALOPINION_ERROR = "MEDICALOPINION_ERROR";

export const VIL_REQUEST = "VIL_REQUEST";
export const VIL_SUCCESS = "VIL_SUCCESS";
export const VIL_ERROR = "VIL_ERROR";

export const FLIGHT_TICKET_REQUEST = "FLIGHT_TICKET_REQUEST";
export const FLIGHT_TICKET_SUCCESS = "FLIGHT_TICKET_SUCCESS";
export const FLIGHT_TICKET_ERROR = "FLIGHT_TICKET_ERROR";

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";

export const FETCH_EXISTING_PATIENTS_REQUEST =
  "FETCH_EXISTING_PATIENTS_REQUEST";
export const FETCH_EXISTING_PATIENTS_SUCCESS =
  "FETCH_EXISTING_PATIENTS_SUCCESS";
export const FETCH_EXISTING_PATIENTS_ERROR = "FETCH_EXISTING_PATIENTS_ERROR";

export const FETCH_TR_DETAILS_REQUEST = "FETCH_TR_DETAILS_REQUEST";
export const FETCH_TR_DETAILS_SUCCESS = "FETCH_TR_DETAILS_SUCCESS";
export const FETCH_TR_DETAILS_ERROR = "FETCH_TR_DETAILS_ERROR";
export const RESET_TR_DETAILS = "RESET_TR_DETAILS";

export const FETCH_DASHBOARD_SUMMARY_REQUEST =
  "FETCH_DASHBOARD_SUMMARY_COUNT_REQUEST";
export const FETCH_DASHBOARD_SUMMARY_SUCCESS =
  "FETCH_DASHBOARD_SUMMARY_COUNT_SUCCESS";
export const FETCH_DASHBOARD_SUMMARY_DETAILS_SUCCESS =
  "FETCH_DASHBOARD_SUMMARY_DETAILS_SUCCESS";
export const FETCH_DASHBOARD_SUMMARY_ERROR =
  "FETCH_DASHBOARD_SUMMARY_COUNT_ERROR";
export const SET_DASHBOARD_CURRENT_STATE = "SET_DASHBOARD_CURRENT_STATE";

export const FETCH_AIRLINES_DETAILS_REQUEST = "FETCH_AIRLINES_DETAILS_REQUEST";
export const FETCH_AIRLINES_DETAILS_SUCCESS = "FETCH_AIRLINES_DETAILS_SUCCESS";
export const FETCH_AIRLINES_DETAILS_ERROR = "FETCH_AIRLINES_DETAILS_ERROR";

export const FETCH_AIRPORT_DETAILS_REQUEST = "FETCH_AIRPORT_DETAILS_REQUEST";
export const FETCH_FROM_AIRPORT_DETAILS_SUCCESS =
  "FETCH_FROM_AIRPORT_DETAILS_SUCCESS";
export const FETCH_TO_AIRPORT_DETAILS_SUCCESS =
  "FETCH_TO_AIRPORT_DETAILS_SUCCESS";
export const FETCH_AIRPORT_DETAILS_ERROR = "FETCH_AIRPORT_DETAILS_ERROR";

export const FETCH_PREFERRED_COUNTRY_REQUEST =
  "FETCH_PREFERRED_COUNTRY_REQUEST";
export const FETCH_PREFERRED_COUNTRY_SUCCESS =
  "FETCH_PREFERRED_COUNTRY_SUCCESS";
export const FETCH_PREFERRED_COUNTRY_MASTER_SUCCESS =
  "FETCH_PREFERRED_COUNTRY_MASTER_SUCCESS";
export const FETCH_HOSPITALS_MASTER_SUCCESS = "FETCH_HOSPITALS_MASTER_SUCCESS";
export const FETCH_HEALTHCONCERN_MASTER_SUCCESS =
  "FETCH_HEALTHCONCERN_MASTER_SUCCESS";
export const SET_TR_ID = "SET_TR_ID";
export const RESET_TR_ID = "RESET_TR_ID";

export const FETCH_DOCTOR_MASTER_SUCCESS = "FETCH_DOCTOR_MASTER_SUCCESS";

export const FETCH_TRIDS_REQUEST = "FETCH_TRIDS_REQUEST";
export const FETCH_TRIDS_SUCCESS = "FETCH_TRIDS_SUCCESS";
export const FETCH_TRIDS_ERROR = "FETCH_TRIDS_ERROR";

export const VERIFY_EXISTING_PATIENT_REQUEST =
  "VERIFY_EXISTING_PATIENT_REQUEST";
export const VERIFY_EXISTING_PATIENT_SUCCESS =
  "VERIFY_EXISTING_PATIENT_SUCCESS";
export const VERIFY_EXISTING_PATIENT_ERROR = "VERIFY_PASSPORT_ERROR";

export const fetchHospitalsRequest = (payload) => {
  return {
    type: FETCH_HOSPITALS_REQUEST,
    payload: payload,
  };
};

export const fetchHospitalsSuccess = (payload) => {
  return {
    type: FETCH_HOSPITALS_SUCCESS,
    payload,
  };
};

export const fetchHospitalsFailure = (payload) => {
  return {
    type: FETCH_HOSPITALS_FAILURE,
    payload,
  };
};

export const fetchHospitalSpecialtyRequest = () => {
  return {
    type: FETCH_HOSPITAL_SPECIALTY_REQUEST,
  };
};

export const fetchHospitalSpecialtySuccess = (payload) => {
  return {
    type: FETCH_HOSPITAL_SPECIALTY_SUCCESS,
    payload,
  };
};

export const fetchHospitalSpecialtyFailure = (payload) => {
  return {
    type: FETCH_HOSPITAL_SPECIALTY_FAILURE,
    payload,
  };
};

export const fetchCountryRequest = () => {
  return {
    type: FETCH_COUNTRY_MASTER_REQUEST,
  };
};

export const fetchCountrySuccess = (payload) => {
  return {
    type: FETCH_COUNTRY_MASTER_SUCCESS,
    payload,
  };
};

export const fetchCountryFailure = (payload) => {
  return {
    type: FETCH_COUNTRY_MASTER_FAILURE,
    payload,
  };
};

export const fetchHealthConcernRequest = (payload) => {
  return {
    type: FETCH_HEALTHCONCERN_REQUEST,
    payload,
  };
};

export const fetchHealthConcernSuccess = (payload) => {
  return {
    type: FETCH_HEALTHCONCERN_SUCCESS,
    payload,
  };
};

export const fetchHealthConcernFailure = (payload) => {
  return {
    type: FETCH_HEALTHCONCERN_ERROR,
    payload,
  };
};

export const fetchDoctorRequest = (payload) => {
  return {
    type: FETCH_DOCTOR_REQUEST,
    payload,
  };
};

export const fetchDoctorSuccess = (payload) => {
  return {
    type: FETCH_DOCTOR_SUCCESS,
    payload,
  };
};

export const fetchDoctorFailure = (payload) => {
  return {
    type: FETCH_DOCTOR_FAILURE,
    payload,
  };
};

export const fetchDoctorSpecialtyRequest = (payload) => {
  return {
    type: FETCH_DOCTOR_SPECIALTY_REQUEST,
    payload,
  };
};

export const fetchDoctorSpecialtySuccess = (payload) => {
  return {
    type: FETCH_DOCTOR_SPECIALTY_SUCCESS,
    payload,
  };
};

export const fetchDoctorSpecialtyFailure = (payload) => {
  return {
    type: FETCH_DOCTOR_SPECIALTY_ERROR,
    payload,
  };
};

export const fetchEmbassyRequest = () => {
  return {
    type: FETCH_EMBASSY_REQUEST,
  };
};

export const fetchEmbassySuccess = (payload) => {
  return {
    type: FETCH_EMBASSY_SUCCESS,
    payload,
  };
};

export const fetchEmbassyFailure = (payload) => {
  return {
    type: FETCH_EMBASSY_ERROR,
    payload,
  };
};

export const fetchPassportDetailsRequest = (payload, passportLabel) => {
  return {
    type: FETCH_PASSPORT_DETAILS_REQUEST,
    payload,
    passportLabel,
  };
};

export const fetchPassportDetailsSuccess = (payload, passportLabel) => {
  return {
    type: FETCH_PASSPORT_DETAILS_SUCCESS,
    payload,
    passportLabel,
  };
};

export const fetchPassportDetailsFailure = (payload) => {
  return {
    type: FETCH_PASSPORT_DETAILS_ERROR,
    payload,
  };
};

export const medicalOpinionRequest = (payload) => {
  return {
    type: MEDICALOPINION_REQUEST,
    payload,
  };
};

export const medicalOpinionSuccess = (payload) => {
  return {
    type: MEDICALOPINION_SUCCESS,
    payload,
  };
};

export const medicalOpinionFailure = (payload) => {
  return {
    type: MEDICALOPINION_ERROR,
    payload,
  };
};

export const vilRequest = (payload) => {
  return {
    type: VIL_REQUEST,
    payload,
  };
};

export const vilSuccess = (payload) => {
  return {
    type: VIL_SUCCESS,
    payload,
  };
};

export const vilFailure = (payload) => {
  return {
    type: VIL_ERROR,
    payload,
  };
};

export const flightTicketsRequest = (payload) => {
  return {
    type: FLIGHT_TICKET_REQUEST,
    payload,
  };
};

export const flightTicketsSuccess = (payload) => {
  return {
    type: FLIGHT_TICKET_SUCCESS,
    payload,
  };
};

export const flightTicketsFailure = (payload) => {
  return {
    type: FLIGHT_TICKET_ERROR,
    payload,
  };
};

export const dashboardRequest = (payload) => {
  return {
    type: DASHBOARD_REQUEST,
    payload,
  };
};

export const dashboardSuccess = (payload) => {
  return {
    type: DASHBOARD_SUCCESS,
    payload,
  };
};

export const dashboardFailure = (payload) => {
  return {
    type: DASHBOARD_ERROR,
    payload,
  };
};

export const fetchExistingPatientsRequest = (payload) => {
  return {
    type: FETCH_EXISTING_PATIENTS_REQUEST,
    payload,
  };
};

export const fetchExistingPatientsSuccess = (payload) => {
  return {
    type: FETCH_EXISTING_PATIENTS_SUCCESS,
    payload,
  };
};

export const fetchExistingPatientsFailure = (payload) => {
  return {
    type: FETCH_EXISTING_PATIENTS_ERROR,
    payload,
  };
};

export const fetchTrDetailsRequest = (payload) => {
  return {
    type: FETCH_TR_DETAILS_REQUEST,
    payload,
  };
};

export const fetchTrDetailsRequestSuccess = (payload) => {
  return {
    type: FETCH_TR_DETAILS_SUCCESS,
    payload,
  };
};

export const fetchTrDetailsRequestFailure = (payload) => {
  return {
    type: FETCH_TR_DETAILS_ERROR,
    payload,
  };
};

export const fetchDashboardSummaryRequest = (payload) => {
  return {
    type: FETCH_DASHBOARD_SUMMARY_REQUEST,
    payload,
  };
};

export const fetchDashboardSummarySuccess = (payload) => {
  return {
    type: FETCH_DASHBOARD_SUMMARY_SUCCESS,
    payload,
  };
};

export const fetchDashboardSummaryDetailsSuccess = (payload) => {
  return {
    type: FETCH_DASHBOARD_SUMMARY_DETAILS_SUCCESS,
    payload,
  };
};

export const fetchDashboardSummaryFailure = (payload) => {
  return {
    type: FETCH_DASHBOARD_SUMMARY_ERROR,
    payload,
  };
};

export const setDashboardCurrentState = (payload) => {
  return {
    type: SET_DASHBOARD_CURRENT_STATE,
    payload,
  };
};

export const fetchAirlinesDetailsRequest = (payload) => {
  return {
    type: FETCH_AIRLINES_DETAILS_REQUEST,
    payload,
  };
};

export const fetchAirlinesDetailSuccess = (payload) => {
  return {
    type: FETCH_AIRLINES_DETAILS_SUCCESS,
    payload,
  };
};

export const fetchAirlinesDetailsFailure = (payload) => {
  return {
    type: FETCH_AIRLINES_DETAILS_ERROR,
    payload,
  };
};

export const fetchAirportDetailsRequest = (payload, label) => {
  return {
    type: FETCH_AIRPORT_DETAILS_REQUEST,
    payload,
    label,
  };
};

export const fetchFromAirportDetailSuccess = (payload, label) => {
  return {
    type: FETCH_FROM_AIRPORT_DETAILS_SUCCESS,
    payload,
    label,
  };
};

export const fetchToAirportDetailSuccess = (payload) => {
  return {
    type: FETCH_TO_AIRPORT_DETAILS_SUCCESS,
    payload,
  };
};

export const fetchAirportDetailsFailure = (payload) => {
  return {
    type: FETCH_AIRPORT_DETAILS_ERROR,
    payload,
  };
};

export const fetchPreferredCountryRequest = (payload) => {
  return {
    type: FETCH_PREFERRED_COUNTRY_REQUEST,
    payload: payload,
  };
};
export const fetchPreferredCountrySuccess = (payload) => {
  return {
    type: FETCH_PREFERRED_COUNTRY_SUCCESS,
    payload,
  };
};

export const fetchPreferredCountryMasterSuccess = (payload) => {
  return {
    type: FETCH_PREFERRED_COUNTRY_MASTER_SUCCESS,
    payload,
  };
};

export const fetchHospitalsMasterSuccess = (payload) => {
  return {
    type: FETCH_HOSPITALS_MASTER_SUCCESS,
    payload,
  };
};
export const fetchHealthConcernMasterSuccess = (payload) => {
  return {
    type: FETCH_HEALTHCONCERN_MASTER_SUCCESS,
    payload,
  };
};
export const fetchDoctorMasterSuccess = (payload) => {
  return {
    type: FETCH_DOCTOR_MASTER_SUCCESS,
    payload,
  };
};

export const resetTrdetails = () => {
  return {
    type: RESET_TR_DETAILS,
  };
};

export const setTrid = (payload) => {
  return {
    type: SET_TR_ID,
    payload,
  };
};

export const resetTrid = () => {
  return {
    type: RESET_TR_ID,
  };
};

export const fetchTridsRequest = (payload) => {
  return {
    type: FETCH_TRIDS_REQUEST,
    payload: payload,
  };
};

export const fetchTridsSuccess = (payload) => {
  return {
    type: FETCH_TRIDS_SUCCESS,
    payload,
  };
};

export const fetchTridsFailure = (payload) => {
  return {
    type: FETCH_TRIDS_ERROR,
    payload,
  };
};

export const verifyExistingPatientRequest = (payload) => {
  return {
    type: VERIFY_EXISTING_PATIENT_REQUEST,
    payload: payload,
  };
};

export const verifyExistingPatientSuccess = (payload) => {
  return {
    type: VERIFY_EXISTING_PATIENT_SUCCESS,
    payload,
  };
};

export const verifyExistingPatientFailure = (payload) => {
  return {
    type: VERIFY_EXISTING_PATIENT_ERROR,
    payload,
  };
};
