import { Box, Flex, Container, Stack, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import BrandedHeading from "../../components/BrandedHeading";
import { PasswordField } from "./components/PasswordField";
import { resetPasswordRequest } from "./authStore/actions";
import { useSelector, useDispatch } from "react-redux";
import ErrorAlert from "../../components/ErrorAlert";
import Alert from "../../components/Alert";

export const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const { resetPassword, error, isLoading } = useSelector((state) => ({
    resetPassword: state.auth.reset_pwd_res,
    error: state.auth.error,
    isLoading: state.auth.loading,
  }));

  // useEffect(() => {
  //   if (resetPassword) navigate("/");
  // }, [resetPassword]);

  useEffect(() => {
    if (error) setErrorMsg(error.message);
  }, [error]);

  const onSubmit = () => {
    setErrorMsg(false);
    if (password === "") {
      setErrorMsg("Please enter password");
    }
    let payload = {
      usr: localStorage.getItem("email"),
      pwd: password,
    };
    dispatch(resetPasswordRequest(payload));
  };

  useEffect(() => {
    if (resetPassword) {
      setShowAlert(true);
    }
  }, [resetPassword]);

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow="5px 5px 30px 0px rgb(224 222 222 / 89%)"
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="1">
                <BrandedHeading fontWeight="600" as="h2" size="md">
                  Do you want to change password?
                </BrandedHeading>
                <Text fontSize="xs">
                  Follow the steps to change your password
                </Text>
              </Stack>
              <Stack spacing="5">
                <PasswordField
                  placeholder="Enter password"
                  LeftinputIconRequired={false}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <PasswordField
                  placeholder="Confirm password"
                  LeftinputIconRequired={false}
                  value={repassword}
                  onChange={(e) => setRepassword(e.target.value)}
                />
                <Stack spacing="6">
                  {errorMsg && <ErrorAlert message={errorMsg} />}
                  <PrimaryButton
                    onClick={onSubmit}
                    isLoading={isLoading}
                    loadingText="Validating"
                    spinnerPlacement="end"
                  >
                    Next
                  </PrimaryButton>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Alert
          isOpen={showAlert}
          title="Success!"
          onClose={() => {
            setShowAlert(false);
          }}
          body="Your password has been successfully changed"
          type="success"
          callback={() => {
            setShowAlert(false);
            navigate("/");
          }}
        />
      </Container>
    </Flex>
  );
};
