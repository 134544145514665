import React, { useState, useEffect } from "react";
import {
  Collapse,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import CollapseIcon from "../../../assets/images/collapse.svg";
import PrimaryHeading from "../../../components/PrimaryHeading";
import PrimaryFormLabel from "../../../components/PrimaryFormLabel";
import { Select } from "chakra-react-select";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { calculateAge } from "./formUtils";
import AlphaInput from "../../../components/AlphaInput";
import "react-phone-input-2/lib/style.css";
import {
  genderData,
  patientMovingFromData,
  arrivalWithinData,
} from "../constants";

import { validatePhoneNumber } from "../form/utils";

const PatientDetailsSection = ({
  formErrors,
  setFormErrors,
  formData,
  setFormData,
  handleSelectChange,
  handleChange,
  isPassportRequired,
  name,
}) => {
  const { isOpen: isSectionOpen, onToggle: toggleSection } = useDisclosure({
    defaultIsOpen: true,
  });

  const [maxDate, setMaxDate] = useState();

  const { countryData, passportDetails } = useSelector((state) => state.hcf);

  const [phoneValid, setPhoneValid] = useState(true);

  useEffect(() => {
    if (
      formData.healthConcern &&
      formData.healthConcern?.value !== "PEDIATRIC CARDIAC SURGERY"
    ) {
      const currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      setMaxDate(currentDate.toISOString().split("T")[0]);
    } else {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 2);
      setMaxDate(currentDate.toISOString().split("T")[0]);
    }
  }, [formData.healthConcern?.value]);

  useEffect(() => {
    if (passportDetails) {
      const formattedDob = passportDetails.dob
        .split("-")
        .reverse()
        .join("-");
      let age = calculateAge(formattedDob);
      setFormData({
        ...formData,
        name: passportDetails.name,
        passportNumber: passportDetails.passport_number,
        dob: formattedDob,
        gender: genderData.find((g) => g.value === passportDetails.gender),
        nationality: countryData.find(
          (c) => c.value === passportDetails.nationality
        ),
        age: age,
      });
    }
  }, [passportDetails]);

  return (
    <>
      <Flex alignItems="center" mt="20px">
        <PrimaryHeading>Patient Details</PrimaryHeading>
        <img
          src={CollapseIcon}
          width={"24px"}
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            transition: "transform 0.3s ease",
            transform: isSectionOpen ? "rotate(0)" : "rotate(180deg)",
          }}
          onClick={toggleSection}
        />
      </Flex>
      <Collapse in={isSectionOpen} animateOpacity>
        <Box
          display="flex"
          flexDirection="column"
          bgColor="white"
          p="15px 20px"
          borderRadius="10px"
          mt="10px"
        >
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            mb="20px"
          >
            <FormControl
              isInvalid={formErrors.name}
              flex="50%"
              mr="20px"
              isRequired
            >
              <PrimaryFormLabel htmlFor="name">Full Name</PrimaryFormLabel>
              {/* <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter Your Name"
                pattern="[A-Za-z ]+"
                minLength={3}
                maxLength={60}
              /> */}
              <AlphaInput
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter Your Full Name"
                regex={/^[a-zA-Z ]*$/}
                maxLength={40}
                isInvalid={!!formErrors.name}
              />
              <FormErrorMessage>{formErrors.name}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formErrors.passportNumber}
              flex="50%"
              isRequired={isPassportRequired}
            >
              <PrimaryFormLabel htmlFor="passportNumber">
                Passport Number
              </PrimaryFormLabel>
              <Input
                id="passportNumber"
                name="passportNumber"
                placeholder="Enter Passport Number"
                value={formData.passportNumber}
                onChange={handleChange}
                pattern="[a-zA-Z0-9]+"
                minLength={3}
                maxLength={10}
                variant={"filled"}
              />
              <FormErrorMessage>{formErrors.passportNumber}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            mb="20px"
          >
            <FormControl
              isInvalid={formErrors.dob}
              flex="50%"
              mr="20px"
              isRequired
            >
              <PrimaryFormLabel htmlFor="dob">Date Of Birth</PrimaryFormLabel>
              {/* <Input
                id="dob"
                name="dob"
                type="date"
                pattern="\d{2}-\d{2}-\d{4}"
                placeholder="dd-mm-yyyy"
                value={formData.dob}
                onChange={handleChange}
              /> */}
              <Input
                id="dob"
                name="dob"
                type="date"
                // pattern="\d{4}-\d{2}-\d{2}"
                // placeholder="yyyy-mm-dd"
                value={formData.dob}
                onChange={handleChange}
                variant={"filled"}
                max={maxDate}
              />
              <FormErrorMessage>{formErrors.dob}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formErrors.age} flex="50%">
              <PrimaryFormLabel htmlFor="age">Age</PrimaryFormLabel>
              <Input
                id="age"
                name="age"
                type="number"
                placeholder="Enter Your Age"
                isDisabled
                value={formData.age}
                onChange={handleChange}
                variant={"filled"}
              />
              <FormErrorMessage>{formErrors.age}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
            mb="20px"
          >
            <FormControl
              isInvalid={formErrors.mobileNumber || !phoneValid}
              flex="50%"
              mr="20px"
            >
              <PrimaryFormLabel htmlFor="mobileNumber">
                Mobile Number
              </PrimaryFormLabel>
              <PhoneInput
                id="mobileNumber"
                name="mobileNumber"
                preferredCountries={[
                  "bd",
                  "ke",
                  "so",
                  "iq",
                  "et",
                  "mu",
                  "ng",
                  "om",
                  "tz",
                  "mm",
                  "ye",
                  "uz",
                  "np",
                  "mz",
                  "tm",
                ]}
                enableSearch={true}
                international
                countryCodeEditable={false}
                inputStyle={{
                  width: "100%",
                  height: "40px",
                  borderColor: formErrors.mobileNumber ? "red.500" : "blue.1",
                  color: "blue.800",
                  placeholderColor: "green.400",
                }}
                placeholder="Enter Mobile Number"
                onChange={(value, country) => {
                  setFormData({
                    ...formData,
                    mobileNumber: value,
                    countryCode: country.dialCode,
                  });
                  const isValid = validatePhoneNumber(
                    value,
                    country.countryCode,
                    country.dialCode,
                    country.name,
                    setFormErrors,
                    formErrors
                  );
                  setPhoneValid(isValid);
                }}
                value={formData.mobileNumber}
              />
              <FormErrorMessage>{formErrors.mobileNumber}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={formErrors.gender} flex="50%" isRequired>
              <PrimaryFormLabel htmlFor="gender">Gender</PrimaryFormLabel>
              <Select
                id="gender"
                name="gender"
                placeholder="Select Gender"
                options={genderData}
                menuPortalTarget={document.body}
                classNamePrefix="chakra-react-select"
                value={formData.gender}
                onChange={handleSelectChange}
              />
              <FormErrorMessage>{formErrors.gender}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <FormControl
              isInvalid={formErrors.nationality}
              flex="50%"
              mr="20px"
              isRequired
            >
              <PrimaryFormLabel htmlFor="nationality">
                Nationality
              </PrimaryFormLabel>
              <Select
                id="nationality"
                name="nationality"
                placeholder="Select Nationality"
                options={countryData}
                menuPortalTarget={document.body}
                classNamePrefix="chakra-react-select"
                value={formData.nationality}
                onChange={handleSelectChange}
              />
              <FormErrorMessage>{formErrors.nationality}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formErrors.countryOfResidence}
              flex="50%"
              isRequired
            >
              <PrimaryFormLabel htmlFor="countryOfResidence">
                Country Of Residence
              </PrimaryFormLabel>
              <Select
                id="countryOfResidence"
                name="countryOfResidence"
                placeholder="Select Country Of Residence"
                options={countryData}
                menuPortalTarget={document.body}
                classNamePrefix="chakra-react-select"
                value={formData.countryOfResidence}
                onChange={handleSelectChange}
              />
              <FormErrorMessage>
                {formErrors.countryOfResidence}
              </FormErrorMessage>
            </FormControl>
          </Box>
          {name === "Preintimation" && (
            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              justifyContent="space-between"
              mt="20px"
            >
              <FormControl
                isInvalid={formErrors.patientMovingFrom}
                flex="50%"
                mr="20px"
                isRequired
              >
                <PrimaryFormLabel htmlFor="patientMovingFrom">
                  Patient Moving From
                </PrimaryFormLabel>
                <Select
                  id="patientMovingFrom"
                  name="patientMovingFrom"
                  placeholder="Select Patient Moving From"
                  options={patientMovingFromData}
                  menuPortalTarget={document.body}
                  classNamePrefix="chakra-react-select"
                  value={formData.patientMovingFrom}
                  menuPlacement="top"
                  onChange={handleSelectChange}
                />
                <FormErrorMessage>
                  {formErrors.patientMovingFrom}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formErrors.arrivalWithin}
                flex="50%"
                isRequired
              >
                <PrimaryFormLabel htmlFor="arrivalWithin">
                  Arrival Within{" "}
                </PrimaryFormLabel>
                <Select
                  id="arrivalWithin"
                  name="arrivalWithin"
                  placeholder="Select Arrival Within "
                  options={arrivalWithinData}
                  menuPortalTarget={document.body}
                  classNamePrefix="chakra-react-select"
                  value={formData.arrivalWithin}
                  onChange={handleSelectChange}
                  menuPlacement="top"
                />
                <FormErrorMessage>{formErrors.arrivalWithin}</FormErrorMessage>
              </FormControl>
            </Box>
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default PatientDetailsSection;
