"use client";
import React, { useState, useEffect } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Spacer,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiMenu,
  FiBell,
  FiChevronDown,
} from "react-icons/fi";
import companyLogo from "../assets/images/companyLogo.png";
import { NavLink } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { FiMessageCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Search from "../features/search";

const LinkItems = [
  { name: "Home", icon: FiHome, url: "/h/home" },
  { name: "Dashboard", icon: RxDashboard, url: "/h/dashboard" },
  { name: "Reports", icon: FiTrendingUp, url: "/test2" },
];

export const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        mb="10px"
      >
        {/* <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          Logo
        </Text> */}
        <Image src={companyLogo} alt="Logo" mt="10px" />
        {/* <Image src={companyLogo} alt="Logo" boxSize="100px" mr="3" /> */}
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          url={link.url}
          style={{ fontSize: "20px" }}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

export const NavItem = ({ icon, children, url, ...rest }) => {
  return (
    <NavLink to={url} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="3"
        mx="3"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        // _hover={{
        //   bg: "cyan.400",
        //   color: "orange",
        // }}
        // bg="blue.500"
        // color="white"
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="20"
            // _groupHover={{
            //   color: "white",
            // }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </NavLink>
  );
};

export const MobileNav = ({ onOpen, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const allowedRoutes = ["/h/home", "/existing-patients", "/search"];
  const shouldDisplaySearch = allowedRoutes.includes(location.pathname);

  const isNotificationSectionActive = location.pathname === "/notifications";
  const isMessagesActive = location.pathname === "/messages";

  // const locationPlaceholders = [
  //   'Select "Country"',
  //   'Select "State"',
  //   'Select "Zip Code"',
  // ];
  const placeholders = [
    'Search "TR ID"',
    'Search "Patient Name"',
    'Search "Hospital Name"',
    'Search "Health Concern"',
    'Search "Passport Number"',
  ];
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  // const [locationPlaceholderIndex, setLocationPlaceholderIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 2000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [placeholders.length]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setLocationPlaceholderIndex(
  //       (prevIndex) => (prevIndex + 1) % locationPlaceholders.length
  //     );
  //   }, 2000);
  //   return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, [locationPlaceholders.length]);

  return (
    <Flex
      position="sticky" // Make the header fixed
      top="0" // Position it at the top of the screen
      left="0"
      right="0"
      zIndex="999" // Ensure it's on top of other content
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      width="70%"
      maxWidth="70%"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      {/* <Image src={companyIcon} alt="Logo" boxSize="32px" mr="2" /> */}
      {/* {shouldDisplaySearch && (
        <Flex alignItems="center" flexBasis="35%">
          <InputGroup ml={{ base: 4, md: 12 }}>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="primary.1" />
            </InputLeftElement>
            <Input
              fontSize={{ base: "md", md: "lg" }}
              placeholder={placeholders[placeholderIndex]}
            />
          </InputGroup>
        </Flex>
      )} */}
      <Flex alignItems="center" flexBasis="35%">
        {shouldDisplaySearch && (
          <Search placeholder={placeholders[placeholderIndex]} />
        )}
      </Flex>
      <Spacer />
      <HStack spacing={{ base: "0", md: "2" }}>
        <IconButton
          size="lg"
          aria-label="Open Messages"
          fontSize="28px"
          isRound={true}
          icon={<FiMessageCircle />}
          variant={isMessagesActive ? "solid" : "ghost"}
          color={isMessagesActive ? "white" : "grey.4"}
          bg={isMessagesActive ? "blue.500" : undefined}
          onClick={() => {
            navigate("/messages");
          }}
          // border="1px solid"
          // borderColor="grey.3"
          // bg="primary.1"
        />
        <IconButton
          size="lg"
          aria-label="open notifications"
          icon={<FiBell />}
          fontSize="28px"
          // border="1px solid"
          // borderColor="grey.3"
          isRound={true}
          variant={isNotificationSectionActive ? "solid" : "ghost"}
          color={isNotificationSectionActive ? "white" : "grey.4"}
          bg={isNotificationSectionActive ? "blue.500" : undefined}
          onClick={() => {
            navigate("/notifications");
          }}
        />
        <Flex alignItems={"center"} ml="12px">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={FaUserCircle} />
                {/* <IconButton
                  size="lg"
                  variant="ghost"
                  aria-label="Account Settings"
                  fontSize="25px"
                  isRound={true}
                  icon={<FaUserCircle />}
                  border="1px solid"
                  borderColor="grey.3"
                  // bg="primary.1"
                /> */}
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{localStorage.getItem("full_name")}</Text>
                  {/* <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text> */}
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem onClick={() => navigate("/profile")}> Profile</MenuItem>
              {/* <MenuItem>Settings</MenuItem> */}
              <MenuItem>Tutorials</MenuItem>
              <MenuItem>Privacy Policy</MenuItem>
              <MenuItem>Need Help?</MenuItem>
              <MenuDivider />
              <MenuItem>
                {/* <a href="/logout">Logout</a> */}
                <a href="/">Logout</a>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
