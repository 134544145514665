import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Stack,
  Heading,
  Button,
  VStack,
  Avatar,
  Flex,
  HStack,
  Input,
  InputRightElement,
  IconButton,
  InputGroup,
} from "@chakra-ui/react";
import { IoSend, IoAttach } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  conversationRequest,
  fetchMessagesHistoryRequest,
} from "./store/actions";
import chatbotIcon from "../../assets/images/chatbot.png";
import { ThreeDots } from "react-loader-spinner";

const ChatBot = ({ openChat, selectedTaskId, newChat, setNewChat }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const {
    initialMessage,
    botUserResponse,
    singleMessageHistory,
    loading,
    botUserResponseStatus,
  } = useSelector((state) => state.messages);

  const [conversation, setConversation] = useState([]);
  const [taskId, setTaskId] = useState(selectedTaskId);
  const [userTextInput, setUserTextInput] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");

  useEffect(() => {
    setTaskId(selectedTaskId);
  }, [selectedTaskId]);

  useEffect(() => {
    const payload = { is_start_conversation: true };
    dispatch(conversationRequest(payload));
  }, [dispatch]);

  useEffect(() => {
    if (singleMessageHistory?.comments?.length > 0 && openChat) {
      setConversation(singleMessageHistory.comments);
    } else {
      setConversation([]);
    }
  }, [singleMessageHistory, openChat]);

  useEffect(() => {
    if (newChat) {
      setConversation([]);
      setTaskId(null);
      let payload = { is_start_conversation: true };
      dispatch(conversationRequest(payload));
      payload = { is_summary: true };
      dispatch(fetchMessagesHistoryRequest(payload));
    }
  }, [newChat, dispatch]);

  useEffect(() => {
    if (initialMessage && initialMessage.question && openChat === false) {
      setNewChat(false);
      setTaskId(null);
      setCurrentQuestion(initialMessage.question);
      setConversation(() => [
        {
          user_type: "BOT",
          message: initialMessage.question,
          options: initialMessage.options,
          answer_type: initialMessage.answer_type,
          next_question: initialMessage?.next_question,
          patient_details: botUserResponse?.patient_details,
          created_at: new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
        },
      ]);
    }
  }, [initialMessage, openChat]);

  useEffect(() => {
    if (botUserResponse && botUserResponse.question) {
      setCurrentQuestion(botUserResponse.question);
      if (botUserResponseStatus.status === 400) {
        setConversation((prev) => [
          ...prev,
          {
            user_type: "BOT",
            message: botUserResponseStatus.message,
            created_at: new Date().toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }),
          },
        ]);
      }
      setConversation((prev) => [
        ...prev,
        {
          user_type: "BOT",
          message: botUserResponse.question,
          options: botUserResponse.options,
          answer_type: botUserResponse.answer_type,
          next_question: botUserResponse?.next_question,
          patient_details: botUserResponse?.patient_details,
          created_at: new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
        },
      ]);
      if (botUserResponse.task_id) {
        setTaskId(botUserResponse.task_id);
      }
    }
  }, [botUserResponse]);

  const handleOptionClick = (option) => {
    setConversation((prev) => [
      ...prev,
      {
        user_type: "HCF",
        message: option.option,
        created_at: new Date().toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
      },
    ]);

    if (option) {
      let payload = new FormData();
      payload.append("is_start_conversation", false);
      payload.append("user_input", option.option);
      payload.append("is_tr_id", 0);
      payload.append("current_question_id", currentQuestion);
      payload.append("next_question_id", option.next_question);

      if (taskId) {
        payload.append("task_id", taskId);
      }
      dispatch(conversationRequest(payload));
    }
  };

  const handleTextSubmit = () => {
    if (userTextInput.trim() || selectedFile.length > 0) {
      setConversation((prev) => [
        ...prev,
        {
          user_type: "HCF",
          message: userTextInput,
          attached_files: selectedFile ? [selectedFile] : [],
          created_at: new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
        },
      ]);
      const lastEntry = conversation[conversation.length - 1];
      const nextQuestion = lastEntry?.next_question || null;
      let payload = new FormData();
      payload.append("is_start_conversation", false);
      payload.append("user_input", userTextInput);
      payload.append(
        "is_tr_id",
        lastEntry.message === "Please share patient TR ID" ? 1 : 0
      );
      payload.append("current_question_id", currentQuestion);
      payload.append("next_question_id", nextQuestion);

      if (selectedFile.length > 0) {
        selectedFile.forEach((file, index) => {
          payload.append(`attached_files[${index}]`, file);
        });
      }

      if (taskId) {
        payload.append("task_id", taskId);
      }
      dispatch(conversationRequest(payload));
      setUserTextInput("");
      setSelectedFile([]);
    }
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFile((prevFiles) => [...prevFiles, ...files]);
  };
  return (
    <>
      <Box>
        <Box
          bg="#2B67AF"
          color="white"
          p={5}
          width="100%"
          height="60px"
          borderTopRadius="lg"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stack spacing={0} align="center" mt="10px">
            <Heading size="md" mt="6px">
              MediSupport Chat
            </Heading>
            <Text size="md" mb="10px">
              Get help 24x7
            </Text>
          </Stack>
        </Box>

        <Box
          p={4}
          pb={8}
          borderWidth="1px"
          height={"712px"}
          width="100%"
          overflowY="auto"
        >
          <VStack align="start" spacing={4}>
            {conversation.map((entry, index) => (
              <HStack
                key={index}
                alignSelf={
                  entry.user_type === "BOT" ? "flex-start" : "flex-end"
                }
              >
                {entry.user_type === "BOT" && (
                  <Avatar size="md" name="medijournbot" src={chatbotIcon} />
                )}
                <VStack>
                  {entry.patient_details && entry.patient_details.length > 0 && (
                    <Box
                      p={5}
                      bg={entry.user_type === "BOT" ? "#AFE1AF" : ""}
                      borderRadius="lg"
                    >
                      {entry.patient_details.map((detail, idx) => (
                        <Box key={idx} mb={2}>
                          <Text fontWeight="bold">Patient ID:</Text>
                          <Text>{detail.id}</Text>
                          <Text fontWeight="bold">Name:</Text>
                          <Text>{detail.patient_name}</Text>
                          <Text fontWeight="bold">Country:</Text>
                          <Text>{detail.patient_nationality}</Text>
                          <Text fontWeight="bold">Hospital:</Text>
                          <Text>{detail.hospital}</Text>
                          <Text fontWeight="bold">Health Concern:</Text>
                          <Text>{detail.health_concern}</Text>
                        </Box>
                      ))}
                    </Box>
                  )}

                  <Box
                    key={index}
                    bg={entry.user_type === "BOT" ? "blue.100" : "gray.200"}
                    borderRadius="lg"
                    p={3}
                  >
                    <Text
                      fontWeight="bold"
                      color={entry.user_type === "BOT" ? "black" : "blue.500"}
                    >
                      {entry.message}
                    </Text>
                    {entry.attached_files && entry.attached_files.length > 0 && (
                      <Box mt={2}>
                        {entry.attached_files.map((file, idx) => (
                          <Text key={idx} fontSize="sm" color="gray.500">
                            {file.name}
                          </Text>
                        ))}
                      </Box>
                    )}
                    <Text
                      fontSize="sm"
                      color="gray.500"
                      mt={2}
                      textAlign={"right"}
                    >
                      {entry.created_at}
                    </Text>
                  </Box>
                </VStack>
              </HStack>
            ))}
            {conversation.length > 0 &&
              conversation[conversation.length - 1].options &&
              conversation[conversation.length - 1].options.map(
                (option, idx) => (
                  <Button
                    key={idx}
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.option}
                  </Button>
                )
              )}
            {conversation.length > 0 &&
              (conversation[conversation.length - 1].answer_type === "Text" ||
                conversation[conversation.length - 1].answer_type ===
                  "Text/Upload") && (
                <Flex mt={4} width="100%" alignItems="center">
                  <InputGroup mt="55%">
                    <Input
                      id="user-input"
                      value={userTextInput}
                      onChange={(e) => setUserTextInput(e.target.value)}
                      placeholder="Enter your response..."
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleTextSubmit();
                        }
                      }}
                    />
                    <InputRightElement mr="20px">
                      {/* {conversation[conversation.length - 1].answer_type ===
                        "Text/Upload" && (
                        <>
                          {" "}
                          <IconButton
                            aria-label="Attach file"
                            icon={<IoAttach className="primary-color" />}
                            variant="text"
                            fontSize="20px"
                            onClick={handleFileUpload}
                          />
                          <input
                            type="file"
                            multiple="true"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                handleTextSubmit();
                              }
                            }}
                          />
                        </>
                      )} */}

                      <IconButton
                        aria-label="Send"
                        icon={<IoSend className="primary-color" />}
                        onClick={handleTextSubmit}
                        variant="text"
                      />
                    </InputRightElement>
                  </InputGroup>
                </Flex>
              )}
            {loading && (
              <HStack spacing={4} align="center">
                <Avatar size="md" name="medijournbot" src={chatbotIcon} />
                <ThreeDots
                  visible={true}
                  height="40"
                  width="40"
                  color="#2B67AF"
                  radius="5"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </HStack>
            )}
          </VStack>
        </Box>
      </Box>
    </>
  );
};

export default ChatBot;
