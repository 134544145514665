import React, { useEffect } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Box,
  Flex,
  Input,
  Text,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "chakra-react-select";
import {
  fetchCountryRequest,
  fetchHealthConcernRequest,
  fetchHospitalsRequest,
} from "../features/hcf/hcfStore/actions";
import PrimaryFormLabel from "./PrimaryFormLabel";
import PrimaryHeading from "./PrimaryHeading";
import { genderData, Days } from "../features/hcf/constants";
import PrimaryButton from "./PrimaryButton";

const FilterDrawer = ({
  isOpen,
  onClose,
  handleFilter,
  setFilters,
  filters,
}) => {
  const dispatch = useDispatch();
  const { hospitalMaster, healthConcernMaster, countryData } = useSelector(
    (state) => state.hcf
  );

  useEffect(() => {
    const payload = { is_master: true };
    dispatch(fetchHospitalsRequest(payload));
    dispatch(fetchCountryRequest());
    dispatch(fetchHealthConcernRequest(payload));
  }, [dispatch]);

  const {
    country,
    healthConcern,
    hospital,
    gender,
    daysFilter,
    fromDate,
    toDate,
    customDateRange,
    dateError,
  } = filters;

  useEffect(() => {
    if (customDateRange) {
      const today = new Date();
      const fifteenDaysAgo = new Date(today);
      fifteenDaysAgo.setDate(today.getDate() - 15);
      setFilters((prevFilters) => ({
        ...prevFilters,
        fromDate: fifteenDaysAgo.toISOString().split("T")[0],
        toDate: today.toISOString().split("T")[0],
      }));
    }
  }, [customDateRange, setFilters]);

  const handleDaysFilterChange = (selectedOption) => {
    if (selectedOption) {
      const isCustom = selectedOption.value === "custom";
      setFilters((prevFilters) => ({
        ...prevFilters,
        daysFilter: selectedOption.value,
        customDateRange: isCustom,
        fromDate: isCustom ? prevFilters.fromDate : "",
        toDate: isCustom ? prevFilters.toDate : "",
      }));
    }
  };

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      fromDate: selectedFromDate,
      dateError:
        prevFilters.toDate < selectedFromDate
          ? "Please select a valid date range."
          : "",
    }));
  };

  const handleToDateChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      toDate: e.target.value,
    }));
  };

  const handleApplyFilters = () => {
    if (dateError) {
      return;
    }
    handleFilter();
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader borderBottomWidth="1px">
          <PrimaryHeading>Filters</PrimaryHeading>
        </DrawerHeader>
        <DrawerBody>
          <Flex direction="column" gap={4}>
            <Flex wrap="wrap" gap={4}>
              <Box flex="1" minWidth="200px">
                <PrimaryFormLabel>Health Concern</PrimaryFormLabel>
                <Select
                  isMulti
                  placeholder="Select Health Concern"
                  closeMenuOnSelect={false}
                  options={healthConcernMaster}
                  value={healthConcern}
                  onChange={(selectedOption) =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      healthConcern: selectedOption,
                    }))
                  }
                />
              </Box>
              <Box flex="1" minWidth="200px">
                <PrimaryFormLabel>Country</PrimaryFormLabel>
                <Select
                  isMulti
                  placeholder="Select Country"
                  closeMenuOnSelect={false}
                  options={countryData}
                  value={country}
                  onChange={(selectedOption) =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      country: selectedOption,
                    }))
                  }
                />
              </Box>
            </Flex>
            <Flex wrap="wrap" gap={4}>
              <Box flex="1" minWidth="200px">
                <PrimaryFormLabel>Hospital</PrimaryFormLabel>
                <Select
                  isMulti
                  placeholder="Select Hospital"
                  closeMenuOnSelect={false}
                  options={hospitalMaster}
                  value={hospital}
                  onChange={(selectedOption) =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      hospital: selectedOption,
                    }))
                  }
                />
              </Box>
              <Box flex="1" minWidth="200px">
                <PrimaryFormLabel>Gender</PrimaryFormLabel>
                <Select
                  isMulti
                  placeholder="Select Gender"
                  closeMenuOnSelect={false}
                  options={genderData}
                  value={gender}
                  onChange={(selectedOption) =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      gender: selectedOption,
                    }))
                  }
                />
              </Box>
            </Flex>
            <Flex wrap="wrap" gap={4} width="50%">
              <Box flex="1" minWidth="200px">
                <PrimaryFormLabel>Days</PrimaryFormLabel>
                <Select
                  value={Days.find((day) => day.value === daysFilter)}
                  options={Days}
                  onChange={(selectedOption) =>
                    handleDaysFilterChange(selectedOption)
                  }
                />
              </Box>
            </Flex>

            {daysFilter === "custom" && (
              <Box>
                <Flex direction="row" gap={4}>
                  <Box flex="1">
                    <PrimaryFormLabel>From Date</PrimaryFormLabel>
                    <Input
                      type="date"
                      max={new Date().toISOString().split("T")[0]}
                      value={fromDate}
                      onChange={handleFromDateChange}
                    />
                  </Box>
                  <Box flex="1">
                    <PrimaryFormLabel>To Date</PrimaryFormLabel>
                    <Input
                      type="date"
                      min={fromDate}
                      max={new Date().toISOString().split("T")[0]}
                      value={toDate}
                      onChange={handleToDateChange}
                    />
                  </Box>
                </Flex>
                {filters.dateError && (
                  <Text color="red.500">{filters.dateError}</Text>
                )}
              </Box>
            )}
          </Flex>
        </DrawerBody>
        <DrawerFooter
          display="flex"
          justifyContent="end"
          alignItems="center"
          gap={4}
        >
          <PrimaryButton onClick={handleApplyFilters} width="220px">
            Apply
          </PrimaryButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default FilterDrawer;
