export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";

export const fetchNotificationRequest = (payload) => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
    payload: payload,
  };
};

export const fetchNotificationSuccess = (payload) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload,
  };
};
