import { put, all, takeLatest } from "redux-saga/effects";

import {
  HIDE_LOADER,
  SHOW_LOADER,
  apiError,
  exceptionAPIError,
  exceptionError,
  exceptionSessionExpire,
} from "../../authentication/authStore/actions";

import api from "../../../config/api";
import { SEARCH_REQUEST, searchSectionSuccess, searchSuccess } from "./actions";

function* searchDataWorker({ payload }) {
  console.log("payload", payload);
  yield put({ type: SHOW_LOADER });
  let response;
  try {
    response = yield api.post("qms.api.search_tr", payload, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    });

    if (response.ok) {
      response = response.data;
      if (response.status === 200) {
        if (payload.section) {
          yield put(searchSectionSuccess(response.data));
        } else {
          yield put(searchSuccess(response.data));
        }
      } else {
        yield put(apiError(response));
      }
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else if (response.status === 401) {
      yield put(exceptionSessionExpire(response.data.exception));
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}

export default function* searchSaga() {
  yield all([takeLatest(SEARCH_REQUEST, searchDataWorker)]);
}
