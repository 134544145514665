import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
  Center,
  useDisclosure,
} from "@chakra-ui/react";
import { PasswordField } from "./components/PasswordField";
import { Logo } from "./components/Logo";
import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest, verifyOTPRequest } from "./authStore/actions";
import ErrorAlert from "../../components/ErrorAlert";
import OtpModal from "./components/OtpModal";
import LoadingSpinner from "../../components/LoadingSpinner";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  const { is_logged_in, error, loading } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   // redirect to home if already logged in
  //   if (isAuthenticated) navigate("/h/home");
  // }, [isAuthenticated]);

  useEffect(() => {
    if (is_logged_in) {
      onOpen();
    }
  }, [is_logged_in]);

  useEffect(() => {
    if (error) setLoginError(error.message);
  }, [error]);

  const onSubmit = () => {
    if (username === "") {
      setLoginError("Please enter email");
    } else if (password === "") {
      setLoginError("Please enter password");
    }
    let payload = {
      usr: username,
      pwd: password,
    };
    dispatch(loginRequest(payload));
  };

  const handleOtpSubmit = (otp) => {
    let payload = {
      usr: username,
      otp: otp,
      otp_type: "Login",
      token: localStorage.getItem("otp_token"),
      navigate: navigate,
      redirectPath: "/h/home",
    };
    dispatch(verifyOTPRequest(payload));
  };

  return (
    <Center minHeight="100vh" alignItems="center" justifyContent="center">
      {loading && <LoadingSpinner />}
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            // boxShadow={{ base: 'none', sm: 'lg' }}
            boxShadow="5px 5px 30px 0px rgb(224 222 222 / 89%)"
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="0">
                <Logo />
                {/* <Stack spacing="0" textAlign="center">
                  <BrandedHeading fontWeight="700">Login</BrandedHeading>
                </Stack> */}
              </Stack>
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl>
                    {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                    <InputGroup>
                      <InputLeftElement>
                        <IconButton
                          variant="text"
                          aria-label={"Email"}
                          icon={<FaUser />}
                        />
                      </InputLeftElement>
                      <Input
                        placeholder="Enter your email"
                        id="email"
                        name="email"
                        type="email"
                        onChange={(e) => {
                          setUsername(e.target.value);
                          setLoginError(false);
                        }}
                        required
                      />
                    </InputGroup>
                  </FormControl>
                  <PasswordField
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setLoginError(false);
                    }}
                  />
                </Stack>
                <HStack justify="space-between">
                  {/* <Checkbox defaultChecked>Remember me</Checkbox> */}
                  <Checkbox>Remember me</Checkbox>
                  <Button
                    variant="text"
                    color="grey.1"
                    onClick={(e) => navigate("/forgot-password")}
                  >
                    Forgot password?
                  </Button>
                </HStack>
                <Stack spacing="6">
                  {loginError && <ErrorAlert message={loginError} />}
                  <PrimaryButton
                    onClick={onSubmit}
                    // isLoading={isLoading}
                    loadingText="Login"
                    colorScheme="teal"
                    variant="outline"
                    spinnerPlacement="end"
                  >
                    Login
                  </PrimaryButton>
                  <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                    <Text color="fg.muted">
                      Don't have an account?{" "}
                      <Link href="/register" style={{ color: "#57ACFF" }}>
                        Register Here
                      </Link>
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
      <OtpModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleOtpSubmit}
        user={username}
        otpType="Login"
      />
    </Center>
  );
};
