export const UPDATE_TR_DETAILS_REQUEST = "UPDATE_TR_DETAILS_REQUEST";
export const UPDATE_TR_DETAILS_SUCCESS = "UPDATE_TR_DETAILS_SUCCESS";
export const UPDATE_TR_DETAILS_FAILURE = "UPDATE_TR_DETAILS_FAILURE";

export const updateTrDetailsRequest = (payload) => {
  return {
    type: UPDATE_TR_DETAILS_REQUEST,
    payload: payload,
  };
};

export const updateTrDetailsSuccess = (payload) => {
  return {
    type: UPDATE_TR_DETAILS_SUCCESS,
    payload,
  };
};

export const updateTrDetailsFailure = (payload) => {
  return {
    type: UPDATE_TR_DETAILS_FAILURE,
    payload,
  };
};
