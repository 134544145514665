import { create } from "apisauce";

const API_ROOT =
  process.env.REACT_APP_FRAPPE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const api = create({
  baseURL: API_ROOT,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000,
});

// api.addRequestTransform((request) => {
//   if (localStorage.getItem("token")) {
//     request.headers["Authorization"] = `Bearer ${localStorage.getItem(
//       "token"
//     )}`;
//   }
// });

const navMoniter = (response) => {
  if (response.ok) {
    // Update UI or application state based on successful response
  } else {
    // Handle errors and display appropriate messages to the user
  }
};
api.addMonitor(navMoniter);

export default api;
