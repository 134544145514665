import { Heading } from "@chakra-ui/react";

const BrandedHeading = (props) => {
  return (
    <Heading
      size={{ base: "md", md: "lg" }}
      color="primary.1"
      fontWeight={props.fontWeight}
      {...props}
    />
  );
};

export default BrandedHeading;
