import {
  useDisclosure,
  FormControl,
  InputGroup,
  InputRightElement,
  IconButton,
  Input,
  InputLeftElement,
} from "@chakra-ui/react";
import { useRef } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { FaLock } from "react-icons/fa";

export const PasswordField = ({ LeftinputIconRequired = true, ...props }) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);

  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  return (
    <FormControl>
      <InputGroup>
        {LeftinputIconRequired && (
          <InputLeftElement>
            <IconButton
              variant="text"
              aria-label={"Reveal password"}
              icon={<FaLock />}
            />
          </InputLeftElement>
        )}
        <InputRightElement>
          <IconButton
            variant="text"
            aria-label={isOpen ? "Mask password" : "Reveal password"}
            icon={isOpen ? <HiEye /> : <HiEyeOff />}
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          placeholder="Enter your password"
          id="password"
          ref={inputRef}
          name="password"
          type={isOpen ? "text" : "password"}
          autoComplete="current-password"
          required
          {...props}
        />
      </InputGroup>
    </FormControl>
  );
};
