import { combineReducers } from "redux";

import authReducer from "./../features/authentication/authStore/reducer";
import hcfReducer from "../features/hcf/hcfStore/reducer";
import notificationReducer from "../features/notifications/store/reducer";
import messagesReducer from "../features/messages/store/reducer";
import searchReducer from "../features/search/store/reducer";
import commonReducer from "../features/common/store/reducer";

const allReducers = combineReducers({
  auth: authReducer,
  hcf: hcfReducer,
  notification: notificationReducer,
  messages: messagesReducer,
  search: searchReducer,
  common: commonReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_REQUEST") {
    state = undefined;
    localStorage.clear();
  }

  return allReducers(state, action);
};

export default rootReducer;
