// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chakra-react-select__menu-portal {
    z-index: 100;
}

.react-tel-input .form-control {
    font-size: 16px !important;
    border: 1px solid !important;
    border-color: inherit !important;
    /* font-family: inherit !important; */
    font-family: system-ui !important;
}

.primary{
    color: #2B67AF;
}

.blurred {
    /* filter: blur(4px); */
    pointer-events: none;
    /* opacity: 0.7; */
}

.chakra-modal__content{
    margin: 20px !important;
}

.form-input{
    padding: 0px 0px 15px;
}

.form-box{
    padding: 0px 20px;
}

.single-box{
    padding: 15px 20px;
}

.form-upload-box{
    padding: 15px 20px 0px 20px;
}

.form-checkbox{
    padding: 0px 20px 15px;
}

.collapse-section{
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.collapse-section-header{
    margin-top: 20px;
    margin-bottom: 10px;
}

.existing-trid{
    padding:15px 20px;
}`, "",{"version":3,"sources":["webpack://./src/layout/base.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,gCAAgC;IAChC,qCAAqC;IACrC,iCAAiC;AACrC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".chakra-react-select__menu-portal {\n    z-index: 100;\n}\n\n.react-tel-input .form-control {\n    font-size: 16px !important;\n    border: 1px solid !important;\n    border-color: inherit !important;\n    /* font-family: inherit !important; */\n    font-family: system-ui !important;\n}\n\n.primary{\n    color: #2B67AF;\n}\n\n.blurred {\n    /* filter: blur(4px); */\n    pointer-events: none;\n    /* opacity: 0.7; */\n}\n\n.chakra-modal__content{\n    margin: 20px !important;\n}\n\n.form-input{\n    padding: 0px 0px 15px;\n}\n\n.form-box{\n    padding: 0px 20px;\n}\n\n.single-box{\n    padding: 15px 20px;\n}\n\n.form-upload-box{\n    padding: 15px 20px 0px 20px;\n}\n\n.form-checkbox{\n    padding: 0px 20px 15px;\n}\n\n.collapse-section{\n    margin-top: 20px;\n    margin-bottom: 10px;\n    margin-right: 10px;\n    margin-left: 10px;\n}\n\n.collapse-section-header{\n    margin-top: 20px;\n    margin-bottom: 10px;\n}\n\n.existing-trid{\n    padding:15px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
