const formatDate = (date) => {
  let d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const filtersPayload = (
  healthConcern,
  hospital,
  gender,
  country,
  daysFilter,
  fromDate,
  toDate
) => {
  const filters = {};
  if (healthConcern && healthConcern.length > 0) {
    const healthConcernList = healthConcern.map((item) => item.value);
    filters["health_concern"] = healthConcernList;
  }
  if (hospital && hospital.length > 0) {
    const hospitalList = hospital.map((item) => item.value);
    filters["hospital"] = hospitalList;
  }
  if (gender && gender.length > 0) {
    const genderList = gender.map((item) => item.value);
    filters["gender"] = genderList;
  }
  if (country && country.length > 0) {
    const countryList = country.map((item) => item.value);
    filters["country"] = countryList;
  }
  console.log("daysFilter", daysFilter);

  let today = new Date();
  let today_f = formatDate(today);

  if (daysFilter === "today") {
    filters["from_date"] = today_f;
    filters["to_date"] = today_f;
  } else if (daysFilter === "last_week") {
    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);
    const lastWeekFormatted = formatDate(lastWeek);
    filters["from_date"] = lastWeekFormatted;
    filters["to_date"] = today_f;
  } else if (daysFilter === "last_month") {
    const lastMonth = new Date();
    lastMonth.setDate(today.getDate() - 30);
    const lastMonthFormatted = formatDate(lastMonth);
    filters["from_date"] = lastMonthFormatted;
    filters["to_date"] = today_f;
  } else {
    filters["from_date"] = fromDate;
    filters["to_date"] = toDate;
  }
  return filters;
};
