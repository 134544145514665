import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import Filters from "./Filters";
import { Box, IconButton } from "@chakra-ui/react";
import { HiEye } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import ReactDataTable from "../../../components/PrimaryTable";

import {
  fetchCountryRequest,
  fetchExistingPatientsRequest,
  fetchHealthConcernRequest,
  fetchHospitalsRequest,
  fetchTrDetailsRequest,
} from "../hcfStore/actions";
import PatientDetailsModal from "../components/PatientDetailsModal";
import PrimaryText from "../../../components/PrimaryText";

const ExistingPatients = () => {
  const dispatch = useDispatch();

  const { existingPatientsResponse, trDetails } = useSelector(
    (state) => state.hcf
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    let payload = { is_master: true };
    dispatch(fetchHospitalsRequest(payload));
    dispatch(fetchCountryRequest());
    dispatch(fetchHealthConcernRequest(payload));
    payload = {};
    dispatch(fetchExistingPatientsRequest(payload));
  }, []);

  useEffect(() => {
    if (trDetails && trDetails.length > 0) {
      setIsModalOpen(true);
    }
  }, [trDetails]);

  const CustomCell = ({ value }) => {
    return (
      <div title={value || "No data available"}>
        {value
          ? value.length > 20
            ? `${value.slice(0, 20)}...`
            : value
          : "N/A"}
      </div>
    );
  };
  const columns = [
    {
      name: "TR-ID",
      selector: (row) => (
        <PrimaryText
          style={{ cursor: "pointer", textDecoration: "none" }}
          onClick={() => handleViewClick(row.id, "tr-id")}
        >
          {row.id}
        </PrimaryText>
      ),
      sortable: true,
    },

    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
      sortable: true,
      cell: (row) => <CustomCell value={row.patient_name} />,
    },
    {
      name: "Hospital",
      selector: (row) => row.hospital,
      sortable: true,
      cell: (row) => <CustomCell value={row.hospital} />,
    },
    // {
    //   name: "Passport Uploaded",
    //   selector: (row) => row.is_passport_uploaded,
    //   sortable: true,
    // },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: true,
    // },
    {
      name: "MO Status",
      selector: (row) => row.medical_opinion_status,
      sortable: true,
    },
    {
      name: "VIL Status",
      selector: (row) => row.vil_status,
      sortable: true,
    },
    {
      name: "FT Status",
      selector: (row) => row.flight_ticket_status,
      sortable: true,
    },
    // {
    //   name: "Pre-Intimations",
    //   cell: (row) => (
    //     <input
    //       type="checkbox"
    //       checked={!!row.is_pre_intimation}
    //       readOnly
    //       disabled
    //     />
    //   ),
    //   sortable: true,
    //   cellStyle: "centerColumn",
    // },
    {
      name: "Pre-Intimations",
      selector: (row) => row.preintmation_status,
      sortable: true,
    },
    {
      name: "Created Date & Time",
      selector: (row) => row.created_date + " " + row.created_time,
      sortable: true,
    },
    {
      name: "Updated Date & Time",
      selector: (row) => row.modified_date + " " + row.modified_time,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <IconButton icon={<HiEye />} onClick={() => handleViewClick(row.id)} />
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  const handleViewClick = (id) => {
    let payload = {
      tr_id: id,
    };
    setSelectedPatient(id);
    dispatch(fetchTrDetailsRequest(payload));
  };

  const handleSearch = (filters) => {
    console.log("Filters applied:", filters);
    let payload = filters;
    dispatch(fetchExistingPatientsRequest(payload));
  };

  return (
    <Layout header={"Existing Patients"} iconUrl="/h/home">
      <Filters onSearch={handleSearch} />

      <Box
        minHeight="600px"
        bgColor={"#ffff"}
        mt="20px"
        ml="10px"
        shadow="md"
        borderWidth="1px"
        borderRadius="md"
      >
        <ReactDataTable
          columns={columns}
          data={existingPatientsResponse?.data || []}
          handleViewClick={handleViewClick}
          selectedPatientId={selectedPatient}
        />
        {selectedPatient && trDetails && (
          <PatientDetailsModal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              setSelectedPatient(null);
            }}
            patient={trDetails?.[0] || []}
          />
        )}
      </Box>
    </Layout>
  );
};

export default ExistingPatients;
