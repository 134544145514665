const baseValidateForm = (values, formErrors) => {
  const errors = {};

  if (!values.healthConcern) {
    errors.healthConcern = "This field is required";
  }
  if (!values.preferredCountry) {
    errors.preferredCountry = "This field is required";
  }
  if (!values.hospital) {
    errors.hospital = "This field is required";
  }
  if (!values.name) {
    errors.name = "This field is required";
  } else if (values.name.length < 3) {
    errors.name = "Name must be at least 3 characters";
  }

  if (!values.dob) {
    errors.dob = "This field is required";
  } else if (values.dob) {
    const selectedDate = new Date(values.dob);
    const today = new Date();
    if (selectedDate > today.setHours(0, 0, 0, 0)) {
      errors.dob = "The Date of Birth cannot be in the future.";
    }
  }
  if (!values.gender) {
    errors.gender = "This field is required";
  }
  if (!values.nationality) {
    errors.nationality = "This field is required";
  }
  if (!values.countryOfResidence) {
    errors.countryOfResidence = "This field is required";
  }
  if (values.mobileNumber && values.mobileNumber !== "") {
    if (formErrors.mobileNumber && formErrors.mobileNumber !== "") {
      errors.mobileNumber = formErrors.mobileNumber;
    }
  }
  return errors;
};

export const medicalOpinionValidateForm = (values, formErrors) => {
  const errors = baseValidateForm(values, formErrors);

  if (!values.reports || values.reports?.length === 0) {
    errors.reports = "Please upload patient reports";
  }

  return errors;
};

export const preIntimationValidateForm = (values, formErrors) => {
  const errors = baseValidateForm(values, formErrors);

  if (!values.passport || values.passport?.length === 0) {
    errors.passport = "Please upload passport";
  }

  if (!values.passportNumber) {
    errors.passportNumber = "This field is required";
  }

  if (!values.arrivalWithin) {
    errors.arrivalWithin = "This field is required";
  }
  if (!values.patientMovingFrom) {
    errors.patientMovingFrom = "This field is required";
  }
  return errors;
};
