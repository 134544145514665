import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Box,
  Text,
  HStack,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
// import { ImQrcode } from "react-icons/im";
import PatientInfo from "./PatientInfo";
import { PhoneIcon } from "@chakra-ui/icons";
import PatientAdditionalFilesModal from "./PatientAdditionalFilesModal";
import PassportModal from "./PassportModal";
import { updateTrDetailsRequest } from "../../common/store/actions";
import { useDispatch, useSelector } from "react-redux";
import FlightTicketsModal from "./FlightTicketsModal";
import Alert from "../../../components/Alert";
import PreIntimationModal from "./PreIntimationModal";
import PickUpDetailsModal from "./PickUpDetailsModal";

const PatientDetailsModal = ({
  isOpen,
  onClose,
  patient,
  handleRefresh = () => {},
}) => {
  const dispatch = useDispatch();
  const { updateTrDetails, isTrDetailsNotUpdated } = useSelector(
    (state) => state.common
  );
  const isAttendantPassportsEmpty = patient?.attendant_passports.length === 0;
  const isReportsEmpty = patient?.patient_reports.length === 0;
  const isPatientPassportEmpty = patient?.patient_passport === null;

  const patientPassportFiles = patient?.patient_passport
    ? [patient.patient_passport]
    : [];
  const [reports, setReports] = useState([]);
  const [isPatientPassport, setIsPatientPassport] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Success!");
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const {
    isOpen: isUploadModalOpen,
    onOpen: onOpenUploadModal,
    onClose: onCloseUploadModal,
  } = useDisclosure();

  const {
    isOpen: isPassportModalOpen,
    onOpen: onOpenPassportModal,
    onClose: onClosePassportModal,
  } = useDisclosure();

  const {
    isOpen: isFlightTicketModalOpen,
    onOpen: onOpenFlightTicketModal,
    onClose: onCloseFlightTicketModal,
  } = useDisclosure();

  const {
    isOpen: isPreIntimationModalOpen,
    onOpen: onOpenPreIntimationModal,
    onClose: onClosePreIntimationModal,
  } = useDisclosure();

  const {
    isOpen: isPickupDetailsModalOpen,
    onOpen: onOpenPickupDetailsModal,
    onClose: onClosePickupDetailsModal,
  } = useDisclosure();

  useEffect(() => {
    if (updateTrDetails) {
      setShowAlert(true);
      setAlertMessage("Details Updated Successfully");
    } else {
      setShowAlert(false);
    }
  }, [updateTrDetails]);

  useEffect(() => {
    if (isTrDetailsNotUpdated) {
      setShowAlert(true);
      setAlertTitle("Error!");
      setAlertType("error");
      setAlertMessage("Error occurred while updating the details.");
    } else {
      setShowAlert(false);
    }
  }, [isTrDetailsNotUpdated]);

  const reportsModal = (reports) => {
    setReports(reports);
    onOpenUploadModal();
  };

  const handlePassportModal = (is_patient_passport) => {
    setIsPatientPassport(is_patient_passport);
    onOpenPassportModal();
  };

  const handleFlightTicketModal = () => {
    onOpenFlightTicketModal();
  };

  const handlePreIntimation = () => {
    onOpenPreIntimationModal();
  };

  const handlePickupDetailsModal = () => {
    onOpenPickupDetailsModal();
  };

  const handleSubmit = (updatedDetails) => {
    const payload = new FormData();
    payload.append("tr_id", patient.id);
    if (updatedDetails) {
      if (isPatientPassport) {
        if (updatedDetails.name) {
          payload.append("patient_name", updatedDetails.name);
        }
        if (updatedDetails.passportNo) {
          payload.append("passport_no", updatedDetails.passportNo);
        }
        if (updatedDetails.nationality) {
          payload.append("nationality", updatedDetails.nationality);
        }
        if (updatedDetails.dob) {
          payload.append("dob", updatedDetails.dob);
        }
        if (updatedDetails.passportImage) {
          payload.append("passport", updatedDetails.passportImage);
        }
      } else {
        if (updatedDetails.name) {
          payload.append("attendant_name", updatedDetails.name);
        }
        if (updatedDetails.passportNo) {
          payload.append("passport_no", updatedDetails.passportNo);
        }
        if (updatedDetails.passportImage) {
          payload.append("attendant_passport", updatedDetails.passportImage);
        }
        payload.append("attendant_id", updatedDetails.attendantId);
      }
    }
    dispatch(updateTrDetailsRequest(payload));
  };

  const handleReportsSubmit = (reports) => {
    let payload = new FormData();
    payload.append("tr_id", patient.id);
    if (reports && reports.length > 0) {
      reports.forEach((report) => {
        payload.append("reports", report);
      });
    }
    dispatch(updateTrDetailsRequest(payload));
  };

  const handleFlightTicketSubmit = () => {
    console.log("123456");
  };

  // const handleDownload = () => {
  //   const payload = { url: patient.qr_code_url };
  //   dispatch(downloadImageRequest(payload));
  // };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        size="2xl"
        p="10px"
        isCentered
      >
        <ModalOverlay />
        <ModalContent pb="20px" maxH="90vh" overflowY="scroll">
          <ModalHeader color="primary.1" textAlign={"center"} fontSize={"3xl"}>
            Patient Details
          </ModalHeader>
          {/* <ImQrcode
            style={{
              color: "var(--chakra-colors-primary-1)",
            }}
          /> */}
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Box
                p={4}
                shadow="md"
                borderWidth="1px"
                bgColor={"primary.1"}
                color="white"
                borderRadius={"20px"}
              >
                <HStack justify="space-between">
                  <VStack align="flex-start">
                    <Text fontSize="xl" fontWeight="bold">
                      {patient.patient_name
                        ? patient.patient_name
                        : "Patient Name: - "}
                    </Text>
                    <Text fontSize="sm" mt="-10px">
                      {patient.hospital ? patient.hospital : "Hospital : -"}
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                      {patient.id ? patient.id : "TRID: - "}
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                      <PhoneIcon mr="10px" />
                      {patient.mobile_no ? patient.mobile_no : "-"}
                    </Text>
                    <Text>{`Passport No. ${
                      patient.passport_no ? patient.passport_no : "-"
                    }`}</Text>
                  </VStack>
                  <VStack align="flex-end" mt="20%">
                    <Text fontSize="lg" fontWeight="bold" mt="-30px">
                      {" "}
                      {`${patient.gender ? patient.gender : "-"} | ${
                        patient.age ? patient.age : "-"
                      }`}
                    </Text>
                    <Text fontWeight="bold">{patient.nationality}</Text>
                  </VStack>
                </HStack>
              </Box>
              <Box width="100%" borderRadius={"20px"}>
                <Box
                  bgColor={"#50C878	"}
                  fontSize="lg"
                  fontWeight="bold"
                  color="white"
                  p={2}
                >
                  Summary
                </Box>
                <Box pl={4} pr={4} shadow="md" borderWidth="1px">
                  <PatientInfo
                    label="Health Concern"
                    showButton={false}
                    name={patient?.health_concern?.value || "-Nil-"}
                  />
                  <PatientInfo
                    label="Appointed Doctor"
                    showButton={false}
                    name={`${patient.appointed_doctor || "-Nil-"}`}
                  />
                  <PatientInfo
                    label="Medical Opinion"
                    showButton={true}
                    name={
                      patient.medical_opinion_status === "Not Requested"
                        ? "Request"
                        : patient.medical_opinion_status === "Received"
                        ? "View"
                        : "Pending"
                    }
                    disabled={
                      patient.medical_opinion_status !== "Not Requested" &&
                      patient.medical_opinion_status !== "Received"
                    }
                    files={patient.medical_op_reports}
                  />
                  <PatientInfo
                    label="Reports"
                    showButton={true}
                    addFiles={true}
                    name="View"
                    disabled={isReportsEmpty}
                    files={patient.patient_reports}
                    reportsModal={reportsModal}
                  />
                  <PatientInfo
                    label="Patient Passport"
                    showButton={true}
                    name="View"
                    disabled={isPatientPassportEmpty}
                    files={patientPassportFiles}
                    addFiles={
                      !patientPassportFiles || patientPassportFiles.length === 0
                    }
                    handlePassportModal={() => handlePassportModal(true)}
                    handleSubmit={handleSubmit}
                  />
                  <PatientInfo
                    label="Attenders Passport"
                    showButton={true}
                    name="View"
                    disabled={isAttendantPassportsEmpty}
                    files={patient.attendant_passports}
                    // addFiles={true}
                    handlePassportModal={() => handlePassportModal(false)}
                    handleSubmit={handleSubmit}
                  />
                  <PatientInfo
                    label="Travel Request"
                    showButton={true}
                    name={
                      patient.vil_status === "Not Requested"
                        ? "Request"
                        : patient.vil_status === "Received"
                        ? "View"
                        : "Pending"
                    }
                    disabled={
                      patient.vil_status !== "Received" &&
                      patient.vil_status !== "Not Requested"
                    }
                    patient_tr_id={patient.id}
                    files={patient.vil_reports}
                  />
                  <PatientInfo
                    label="Flight Tickets"
                    showButton={true}
                    name={
                      patient.flight_ticket_status === "Not Uploaded"
                        ? "Request"
                        : "View"
                    }
                    // name={
                    //   patient.flight_ticket_status === "Not Requested"
                    //     ? "Request"
                    //     : patient.flight_ticket_status === "Uploaded with VIL"
                    //     ? "View"
                    //     : "Pending"
                    // }
                    // disabled={patient.flight_ticket_status !== "Not Requested"}
                    patient_tr_id={patient.id}
                    handleFlightTicketModal={() =>
                      handleFlightTicketModal(true)
                    }
                    handleFlightTicketSubmit={handleFlightTicketSubmit}
                    files={patient.flight_ticket_details.flight_tickets}
                  />
                  {patient.is_pre_intimation === 1 && (
                    <PatientInfo
                      label="Pre Intimation"
                      showButton={true}
                      name="View"
                      handlePreIntimation={handlePreIntimation}
                    />
                  )}
                  <PatientInfo
                    label="Airport Pickup"
                    showButton={true}
                    name={
                      patient.pickup_details_status === "Not Requested"
                        ? "Not Requested"
                        : patient.pickup_details_status === "Requested"
                        ? "Requested"
                        : "View"
                    }
                    disabled={
                      patient.pickup_details_status === "Not Requested" ||
                      patient.pickup_details_status === "Requested"
                    }
                    handlePickupDetailsModal={handlePickupDetailsModal}
                  />
                </Box>
              </Box>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <PatientAdditionalFilesModal
        isOpen={isUploadModalOpen}
        onClose={onCloseUploadModal}
        reports={reports}
        handleReportsSubmit={handleReportsSubmit}
      />
      <PassportModal
        isOpen={isPassportModalOpen}
        onClose={onClosePassportModal}
        // images={passportImage}
        trDetails={patient}
        handleSubmit={handleSubmit}
        isPatientPassport={isPatientPassport}
      />
      <FlightTicketsModal
        isOpen={isFlightTicketModalOpen}
        onClose={onCloseFlightTicketModal}
        trDetails={patient}
        handleSubmit={handleFlightTicketSubmit}
      />
      <PreIntimationModal
        isOpen={isPreIntimationModalOpen}
        onClose={onClosePreIntimationModal}
        patientMovingFrom={patient.patient_moving_from}
        ArrivalWithin={patient.arrival_within}
      />
      <PickUpDetailsModal
        isOpen={isPickupDetailsModalOpen}
        onClose={onClosePickupDetailsModal}
        pickup_details={patient.pickup_details}
      />
      <Alert
        isOpen={showAlert}
        title={alertTitle}
        onClose={() => {
          setShowAlert(false);
        }}
        body={alertMessage}
        type={alertType}
        callback={
          alertType === "success" ? () => handleRefresh(patient.id) : undefined
        }
      />
    </>
  );
};

export default PatientDetailsModal;
