import {
  UPDATE_TR_DETAILS_FAILURE,
  UPDATE_TR_DETAILS_SUCCESS,
} from "./actions";

const initialState = {
  updateTrDetails: false,
  isTrDetailsNotUpdated: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TR_DETAILS_SUCCESS:
      return {
        ...state,
        updateTrDetails: action.payload,
        errorResponse: false,
      };
    case UPDATE_TR_DETAILS_FAILURE:
      return {
        ...state,
        updateTrDetails: [],
        isTrDetailsNotUpdated: true,
        errorResponse: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
