import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  List,
  ListItem,
  Flex,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { IoClose } from "react-icons/io5";
const PatientAdditionalFilesModal = ({
  isOpen,
  onClose,
  reports = [],
  handleReportsSubmit = () => {},
}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (reports && reports.length > 0) {
      const fileArray = Array.from(reports).map((file) => ({
        name: file.name,
        url: URL.createObjectURL(file),
        file: file,
      }));
      setFileList(fileArray);
    }
  }, [reports]);

  const handleViewFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const handleRemoveFile = (indexToRemove) => {
    const updatedList = fileList.filter((_, index) => index !== indexToRemove);
    setFileList(updatedList);
  };

  const handleSubmit = () => {
    const selectedFiles = fileList.map((fileObj) => fileObj.file);
    handleReportsSubmit(selectedFiles);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Uploaded Reports</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <List spacing={3}>
            {fileList.map((report, index) => (
              <ListItem key={index}>
                <Flex align="center" justify="space-between">
                  <Text>{report.name}</Text>
                  <Flex>
                    <IconButton
                      variant="ghost"
                      colorScheme="blue"
                      size="sm"
                      aria-label="View"
                      icon={<ViewIcon />}
                      onClick={() => handleViewFile(report.url)}
                      mr={2}
                    />
                    <IconButton
                      variant="ghost"
                      colorScheme="red"
                      aria-label="Remove"
                      icon={<IoClose />}
                      onClick={() => handleRemoveFile(index)}
                      size="sm"
                    />
                  </Flex>
                </Flex>
              </ListItem>
            ))}
          </List>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" width="100%" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PatientAdditionalFilesModal;
