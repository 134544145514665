import React from "react";
import { Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <Center h="100vh">
      <Flex direction="column" alignItems="center">
        <Heading as="h2" size="xl">
          Unauthorized Access
        </Heading>
        <Text fontSize="2xl" textAlign="center" mt="4">
          Oops! You don't have permission to access this page.
        </Text>
        <Button onClick={() => navigate(-1)} mt="4" colorScheme="teal">
          Go Back
        </Button>
      </Flex>
    </Center>
  );
};

export default Unauthorized;
