import React, { useEffect, useState } from "react";
import { SimpleGrid, Card, CardHeader, CardBody } from "@chakra-ui/react";

const DashboardSecondaryCard = ({ data, onSectionClick }) => {
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    const storedSection = localStorage.getItem("dashboard_label");
    setSelectedCard(storedSection);
  }, []);

  const handleCardClick = (index, label) => {
    if (data[index].value !== "0") {
      if (selectedCard === index) {
        setSelectedCard(null);
      } else {
        setSelectedCard(index);
      }
      onSectionClick(label);
    }
  };

  return (
    <SimpleGrid columns={[1, 3]} spacing="4" mt="20px" ml="8px">
      {data &&
        data.map((item, index) => (
          <Card
            key={index}
            borderRadius="md"
            bgColor={
              selectedCard === index || selectedCard === item.label
                ? "primary.1"
                : item.value === "0"
                ? "#C7C8CC"
                : "white"
            }
            color={
              item.value === "0"
                ? "gray.500"
                : selectedCard === index || selectedCard === item.label
                ? "white"
                : "primary.1"
            }
            textAlign="center"
            cursor="pointer"
            onClick={() => handleCardClick(index, item.label)}
            transition="all 0.4s ease"
          >
            <CardHeader fontSize="xl" fontWeight="bold" mb="2">
              {item.label}
            </CardHeader>
            <CardBody fontSize="3xl" fontWeight="bold">
              {item.value}
            </CardBody>
          </Card>
        ))}
    </SimpleGrid>
  );
};

export default DashboardSecondaryCard;
