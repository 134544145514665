import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  MenuDivider,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const Selector = ({
  options,
  buttonColor,
  hoverColor,
  activeColor,
  onOptionSelect,
  marginLeft = "0",
  defaultOption,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    defaultOption || "Select an option"
  );

  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    if (onOptionSelect) {
      onOptionSelect(option); // Perform the action based on the selected option
    }
  };

  return (
    <Menu placement="bottom">
      <MenuButton
        ml="15px"
        as={Button}
        rightIcon={<ChevronDownIcon />}
        bg={buttonColor || "green.400"}
        color="white"
        borderRadius="full"
        _active={{ bg: activeColor }}
      >
        {selectedOption}
      </MenuButton>
      <MenuList ml={marginLeft}>
        {options.map((option, index) => (
          <React.Fragment key={index}>
            <MenuItem
              onClick={() => handleMenuItemClick(option)}
              bg={selectedOption === option ? hoverColor : "white"}
              color={selectedOption === option ? "white" : "none"}
              //   _hover={{ bg: hoverColor }}
              _active={{ bg: activeColor }}
            >
              {option}
            </MenuItem>
            {/* {index < options.length - 1 && <MenuDivider />} */}
          </React.Fragment>
        ))}
      </MenuList>
    </Menu>
  );
};

export default Selector;
